import { css } from '@emotion/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { colors, darkmodeStyles, fontStyles, spacing } from '../../../styles';
import BookmarkButton from '../../BookmarkButton';
import Button from '../../Button';

interface ToggleableLinkProps {
  symbol: string;
  onClick?: (symbol: string) => void;
}

const ToggleableLink: FC<ToggleableLinkProps> = ({ symbol, onClick, children }) => {
  const styles = {
    link: css`
      ${fontStyles.dog};
      padding: ${spacing.centi} ${spacing.centi};
      display: block;
      flex: 1;
      text-decoration: none;
      color: var(--text-color);
      text-align: left;
    `,
  };

  if (onClick) {
    return (
      <Button css={styles.link} onClick={() => onClick(symbol)}>
        {children}
      </Button>
    );
  }

  return (
    <Link to={`/symbol/${symbol}`} css={styles.link}>
      {children}
    </Link>
  );
};

const Results = ({ investments = [], onResultClick, isAllowBookmark, isError }: any) => {
  return (
    <ul
      css={css`
        list-style-type: none;
        margin: 0;
        padding: ${spacing.centi} 0;
        background-color: ${colors.sugar};
        border: 2px solid ${colors.petrolBlue};
        border-top: none;
        border-radius: 0 0 8px 8px;
        ${darkmodeStyles.regular}
        html[data-theme='dark'] & {
          border: 2px solid ${colors.skyBlue};
          background-color: var(--background-color);
        }
      `}
    >
      {(() => {
        if (isError)
          return (
            <li
              css={css`
                padding: ${spacing.centi};
              `}
            >
              Problem fetching results.
            </li>
          );

        if (investments.length === 0)
          return (
            <li
              css={css`
                padding: ${spacing.centi};
              `}
            >
              No results
            </li>
          );

        return investments.map(
          (
            { symbol, name, cleaned_name, category }: any, // must use cleaned_name from algolia
          ) => (
            <li
              key={symbol}
              css={css`
                display: flex;
              `}
            >
              <ToggleableLink symbol={symbol} onClick={onResultClick}>
                <div
                  css={css`
                    ${fontStyles.lion};
                    color: var(--text-color);
                  `}
                >
                  {symbol}
                </div>
                <div
                  css={css`
                    ${fontStyles.mouse}
                    color: ${colors.petrolBlue};
                    html[data-theme='dark'] & {
                      color: ${colors.skyBlue};
                    }
                  `}
                >
                  {cleaned_name ? cleaned_name : name}
                </div>
              </ToggleableLink>
              {isAllowBookmark && (
                <BookmarkButton symbol={symbol} category={category} name={cleaned_name ? cleaned_name : name} />
              )}
            </li>
          ),
        );
      })()}
    </ul>
  );
};

export default Results;
