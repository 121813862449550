import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { spacing, fontStyles, colors } from '../../styles';
import * as storage from '../../storage';
import * as stores from '../../stores';
import { ColumnSelectionType } from '../InvestmentTable/columns';
import { TimeRange } from '../../types';
import Page from '../Page';
import WatchlistPage from '../WatchlistPage';
import Button from '../Button';
import magnifyingGlassActive from '../NavigationBar/magnifyingGlassActive.svg';
import adjustIcon from '../InvestmentTable/adjust.svg';
import ColumnTakeover from '../InvestmentTable/ColumnTakeover';

const PortfoliosList: React.FC = () => {
  const [portfolios, setPortfolios] = useState<storage.Portfolio[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [isColumnTakeoverShown, setIsColumnTakeoverShown] = useState(false);
  const history = useHistory();

  // Use the same tableId as WatchlistPage for consistent column settings
  const tableId = 'portfolio';

  const columnSelections = stores.TableOptions.useState((s) => s[tableId]?.columnSelections) || [
    { type: ColumnSelectionType.Name },
    { type: ColumnSelectionType.Week52Range },
    { type: ColumnSelectionType.ChangePercent, timeRange: TimeRange.weeks1 },
  ];

  // Function to load portfolios
  const loadPortfolios = async () => {
    setIsLoading(true);
    try {
      const loadedPortfolios = await storage.getPortfolios();
      setPortfolios(loadedPortfolios);
    } catch (error) {
      console.error('Error loading portfolios:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load portfolios on mount
  useEffect(() => {
    loadPortfolios();
  }, []);

  // Refresh portfolios when this component comes into focus
  useEffect(() => {
    const handleFocus = () => {
      loadPortfolios();
    };

    window.addEventListener('focus', handleFocus);

    // Also register a history listener to detect when user navigates back to this page
    const unlisten = history.listen((location) => {
      if (location.pathname === '/portfolios') {
        loadPortfolios();
      }
    });

    return () => {
      window.removeEventListener('focus', handleFocus);
      unlisten();
    };
  }, [history]);

  const toggleExpand = (id: string) => {
    setExpandedIds((prev) => (prev.includes(id) ? prev.filter((pid) => pid !== id) : [...prev, id]));
  };

  const pageStyles = css`
    padding: ${spacing.milli} ${spacing.micro};
    padding-bottom: calc(${spacing.milli} + ${spacing.giga});
    max-width: 100%;
    overflow-x: hidden;

    @media (max-width: 359px) {
      padding: ${spacing.micro} ${spacing.micro};
    }
  `;

  const topStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 ${spacing.centi} 0;
  `;

  const headingStyles = css`
    ${fontStyles.shark};
    margin-bottom: ${spacing.deci};
    color: var(--text-color);

    @media (max-width: 359px) {
      font-size: 1.5rem;
    }
  `;

  const iconContainerStyle = css`
    display: flex;
    align-items: center;
    gap: ${spacing.centi};
    margin-bottom: ${spacing.deci};
  `;

  const iconStyle = css`
    color: ${colors.petrolBlue};
    cursor: pointer;
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;

      @media (max-width: 359px) {
        width: 20px;
        height: 20px;
      }
    }
  `;

  const portfolioItemStyles = css`
    background-color: var(--card-background);
    border-radius: 8px;
    margin-bottom: ${spacing.centi};
    box-shadow: 0 1px 3px var(--shadow-color);
    overflow: hidden;
    border: 1px solid var(--border-color);
  `;

  const portfolioHeaderStyles = () => css`
    cursor: pointer;
    padding: ${spacing.milli} ${spacing.milli}; /* Reduced horizontal padding */
    display: flex;
    align-items: center;
    ${fontStyles.dog};
    color: var(--text-color);
    width: 100%;
    transition: all 0.2s ease;
    min-height: 48px; /* Standard height for single line */
    position: relative;

    &:hover {
      background-color: var(--hover-background);
    }

    @media (max-width: 359px) {
      padding: ${spacing.micro} ${spacing.micro};
      min-height: 44px;
    }
  `;

  const portfolioHeaderLeftStyles = css`
    display: flex;
    align-items: center; /* Better vertical alignment */
    gap: ${spacing.micro};
    flex: 1;
    overflow: hidden;
    min-width: 0;
  `;

  const portfolioTitleStyles = css`
    ${fontStyles.fatCat};
    color: var(--text-color);
    display: inline-block;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 50px);

    @media (max-width: 359px) {
      max-width: calc(100% - 40px);
    }
  `;

  const symbolsInfoStyles = css`
    font-size: 0.85em;
    color: var(--secondary-text);
    font-weight: normal;
    white-space: nowrap;
    line-height: 1.2;
    margin-left: 4px;
    flex-shrink: 0;

    @media (max-width: 359px) {
      font-size: 0.8em;
    }
  `;

  const portfolioContentStyles = css`
    padding: ${spacing.deci} ${spacing.milli};
    border-top: 1px solid var(--divider-color);
    background-color: var(--secondary-background);

    @media (max-width: 359px) {
      padding: ${spacing.micro} ${spacing.micro};
    }
  `;

  const watchlistContainerStyles = css`
    margin-top: ${spacing.milli};
    border-radius: 4px;
    overflow: hidden;

    @media (max-width: 359px) {
      margin-top: ${spacing.micro};
    }
  `;

  const buttonAreaStyles = css`
    padding-top: ${spacing.centi};
    display: flex;
    justify-content: center;
    width: 100%;

    @media (max-width: 359px) {
      padding-top: ${spacing.milli};
    }
  `;

  const emptyStateStyles = css`
    text-align: center;
    padding: ${spacing.giga} ${spacing.deci};
    color: var(--secondary-text);
    ${fontStyles.dog};
    background-color: var(--card-background);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    margin: ${spacing.centi} 0;
  `;

  const loadingStyles = css`
    text-align: center;
    padding: ${spacing.giga} ${spacing.deci};
    color: var(--secondary-text);
    ${fontStyles.dog};
  `;

  return (
    <Page showNavigationBar>
      <div css={pageStyles}>
        <div css={topStyle}>
          <h1 css={headingStyles}>Portfolios</h1>
          <div css={iconContainerStyle}>
            <Link to="/portfolio-search" css={iconStyle}>
              <img src={magnifyingGlassActive} alt="Search" />
            </Link>
          </div>
        </div>

        <Button
          onClick={() => setIsColumnTakeoverShown(true)}
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${spacing.centi} ${spacing.deci};
            margin-bottom: ${spacing.deka};
            background-color: var(--card-background);
            color: var(--text-color);
            border: 1px solid var(--border-color);
            border-radius: 8px;
            ${fontStyles.mouse};
            transition: all 0.2s ease;

            &:hover {
              background-color: var(--hover-background);
            }

            @media (max-width: 359px) {
              padding: ${spacing.micro} ${spacing.centi};
              font-size: 12px;
              margin-bottom: ${spacing.centi};
            }
          `}
        >
          <img
            src={adjustIcon}
            css={css`
              margin-right: ${spacing.micro};
              width: 16px;
              height: 16px;
              filter: var(--icon-filter);

              @media (max-width: 359px) {
                width: 14px;
                height: 14px;
              }
            `}
          />
          Change columns
        </Button>

        <ColumnTakeover
          isShown={isColumnTakeoverShown}
          columnSelections={columnSelections}
          onClose={() => setIsColumnTakeoverShown(false)}
          onChange={(index, value) => {
            stores.TableOptions.update((s) => {
              if (!s[tableId]?.columnSelections) {
                s[tableId].columnSelections = columnSelections;
              }
              s[tableId].columnSelections![index] = value;
            });
          }}
        />
        {isLoading ? (
          <div css={loadingStyles}>Loading portfolios...</div>
        ) : portfolios.length === 0 ? (
          <div css={emptyStateStyles}>No portfolios yet. Add one below!</div>
        ) : (
          portfolios.map((portfolio) => (
            <div key={portfolio.id} css={portfolioItemStyles}>
              <div css={portfolioHeaderStyles()} onClick={() => toggleExpand(portfolio.id)}>
                <div css={portfolioHeaderLeftStyles}>
                  <div
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      justify-content: center;
                      width: 24px;
                      min-width: 24px;
                      padding-top: 2px;
                      margin-right: ${spacing.micro};
                      color: var(--secondary-text);
                    `}
                  >
                    {expandedIds.includes(portfolio.id) ? (
                      <ChevronDownIcon color="currentColor" boxSize={5} />
                    ) : (
                      <ChevronRightIcon color="currentColor" boxSize={5} />
                    )}
                  </div>
                  <div
                    css={css`
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      overflow: hidden;
                      width: 100%;
                      padding-right: ${spacing.milli};
                    `}
                  >
                    <div css={portfolioTitleStyles}>{portfolio.title}</div>
                    <div css={symbolsInfoStyles}>
                      (
                      {portfolio.symbols && portfolio.symbols.length
                        ? portfolio.symbols.length
                        : portfolio.allocations?.length || 0}
                      )
                    </div>
                  </div>
                </div>
              </div>

              {expandedIds.includes(portfolio.id) && (
                <div css={portfolioContentStyles}>
                  {portfolio.symbols && portfolio.symbols.length > 0 && (
                    <div css={watchlistContainerStyles}>
                      <WatchlistPage
                        symbols={portfolio.symbols}
                        showHeader={false}
                        containerOnly={true}
                        title={`${portfolio.title} Symbols`}
                        disableFilters={true}
                        hideColumnControls={true}
                      />
                    </div>
                  )}

                  <div css={buttonAreaStyles}>
                    <Button
                      onClick={() => history.push(`/portfolios/view/${portfolio.id}`)}
                      css={css`
                        width: auto;
                        min-width: 120px;
                        max-width: 150px;
                        ${fontStyles.mouse};
                        padding: ${spacing.milli} ${spacing.centi};
                        border-radius: 4px;
                        font-weight: 500;
                        text-align: center;
                        transition: all 0.2s ease;

                        @media (max-width: 359px) {
                          padding: ${spacing.micro} ${spacing.milli};
                          font-size: 12px;
                          min-width: 100px;
                        }
                      `}
                      variant="secondary"
                    >
                      View Details
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}

        <Link
          to="/portfolios/add"
          css={css`
            display: block;
            margin-top: ${spacing.regular};

            @media (max-width: 359px) {
              margin-top: ${spacing.deci};
            }
          `}
        >
          <Button
            variant="primary"
            block
            css={css`
              padding: ${spacing.deci};
              font-weight: 500;
              border-radius: 8px;
              transition: all 0.2s ease;

              @media (max-width: 359px) {
                padding: ${spacing.centi} ${spacing.deci};
                font-size: 14px;
              }
            `}
          >
            Add new portfolio
          </Button>
        </Link>
      </div>
    </Page>
  );
};

export default PortfoliosList;
