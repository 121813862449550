import React, { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { keyBy, pickBy, mapValues } from 'lodash';
import { format, sub } from 'date-fns';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import * as stores from '../../stores';
import { TimeRange } from '../../types';
import { useInvestmentsCacheState } from '../../utils/persistedState';
import { InvestmentTable, ColumnSelectionType } from '../InvestmentTable';
import { getBasicInvestments, getWiims } from '../../apis/backend';
import Button from '../Button';
import Page from '../Page';
import { colors, spacing, fontStyles } from '../../styles';
import magnifyingGlassActive from '../NavigationBar/magnifyingGlassActive.svg';
import Empty from './Empty';
import addIcon from './add.svg';

interface WatchlistPageProps {
  symbols?: string[]; // Optional prop for portfolio symbols
  showHeader?: boolean; // Whether to show the header with title and icons
  title?: string; // Custom title for the watchlist
  containerOnly?: boolean; // Whether to wrap in a Page component or just return the content
  disableFilters?: boolean; // Whether to disable stock/fund filters
  hideColumnControls?: boolean; // Whether to hide column selection controls
}

const WatchlistPage: FC<WatchlistPageProps> = ({
  symbols,
  showHeader = true,
  title = 'Your Watchlist',
  containerOnly = false,
  disableFilters = false,
  hideColumnControls = false,
}) => {
  const baseStyle = css`
    position: relative;
    color: ${colors.liquorice};
  `;

  const topStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 ${spacing.regular} 0;
  `;

  const headingStyle = css`
    color: ${colors.liquorice};
    margin: 0;
    ${fontStyles.shark};
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `;

  const iconContainerStyle = css`
    display: flex;
    align-items: center;
    gap: ${spacing.deci};
  `;

  const iconStyle = css`
    color: ${colors.petrolBlue};
    cursor: pointer;
    display: flex;
    align-items: center;
  `;

  const tableId = 'portfolio';

  const watchlist = stores.General.useState((s) => s.watchlist);
  const [investmentsCache, setInvestmentsCache] = useInvestmentsCacheState();
  const columnSelections = stores.TableOptions.useState((s) => s[tableId]?.columnSelections) || [
    { type: ColumnSelectionType.Name },
    { type: ColumnSelectionType.Week52Range },
    { type: ColumnSelectionType.ChangePercent, timeRange: TimeRange.weeks1 },
  ];
  const sortingRule = stores.TableOptions.useState((s) => s[tableId]?.sortingRule) || { id: 'name' };
  const filters = stores.TableOptions.useState((s) => s[tableId]?.filters) || { stocks: false, funds: false };

  const dateFrom = format(sub(new Date(), { weeks: 1 }), 'yyyy-MM-dd');

  // Use provided symbols or fall back to the watchlist from General store
  const symbolsList = symbols || Object.keys(pickBy(watchlist, (value) => value!));

  // Create a reference to track if component is mounted
  const isMountedRef = React.useRef(true);

  // Set isMountedRef to false when component unmounts
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const getWatchlist = async () => {
    const data = await getBasicInvestments(symbolsList);

    // Only update state if component is still mounted
    if (isMountedRef.current) {
      // Update cache only if the data has changed
      setInvestmentsCache((prevCache) => {
        const newItems = keyBy(
          data.map(({ symbol, name, category }) => ({
            symbol,
            name,
            category,
          })),
          'symbol',
        );

        // Merge with existing cache
        return { ...prevCache, ...newItems };
      });
    }

    return data;
  };

  const errorToastId = 'getWatchlistError';
  const investmentsResult = useQuery(['investments', { symbols: symbolsList }], getWatchlist, {
    enabled: symbolsList.length > 0,
    onError: () => {
      toast(
        () => (
          <Button
            css={css`
              display: flex;
              justify-content: space-between;
              outline: none;
              color: ${colors.liquorice};
            `}
            onClick={() => {
              toast.dismiss(errorToastId);
              investmentsResult.refetch();
            }}
          >
            <span>Problem loading watchlist data.</span>
            <span
              css={css`
                color: ${colors.petrolBlue};
                margin-left: 4px;
              `}
            >
              Retry
            </span>
          </Button>
        ),
        { toastId: errorToastId, autoClose: false },
      );
    },
    onSuccess: () => {
      toast.dismiss(errorToastId);
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    staleTime: 30000,
    cacheTime: 60000,
  });

  const wiimsResult = useQuery(
    ['investments', 'wiims', { symbols: symbolsList, dateFrom }],
    () => getWiims(symbolsList, { dateFrom }),
    {
      enabled: symbolsList.length > 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: 30000,
      cacheTime: 60000,
    },
  );

  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;
  const appOpenDates = stores.General.useState((s) => s.appOpenDates);
  const hasSeenTakeover = stores.General.useState((s) => s.hasSeenSubscriptionTakeoverThisSession);

  useEffect(() => {
    // Only increment counter if we're showing the main watchlist page
    if (!symbols) {
      stores.General.update((s) => {
        s.sessionWatchlistViewCount++;
      });
    }

    return () => {
      toast.dismiss(errorToastId);
    };
  }, []);

  useEffect(() => {
    if (!isSubscribed && !symbols && appOpenDates && appOpenDates.length % 3 === 0) {
      if (!hasSeenTakeover) {
        stores.General.update((s) => {
          s.isSubscriptionTakeoverShown = true;
          s.hasSeenSubscriptionTakeoverThisSession = true;
        });
      }
    }
  }, [appOpenDates, isSubscribed, hasSeenTakeover, symbols]);

  const watchlistContent = (
    <>
      {showHeader && (
        <div css={topStyle}>
          <h1 css={headingStyle}>{title}</h1>
          <div css={iconContainerStyle}>
            <Link to="/portfolios/add" css={iconStyle}>
              <img src={addIcon} alt="Add" />
            </Link>
            <Link to="/search" css={iconStyle}>
              <img src={magnifyingGlassActive} alt="Search" />
            </Link>
          </div>
        </div>
      )}

      {!symbolsList.length ? (
        <Empty />
      ) : (
        (() => {
          // First, get the cached data but only for symbols in our list
          // This ensures we don't show incorrect symbols when API fails
          const filteredCache =
            symbolsList.length > 0 && investmentsCache
              ? Object.values(investmentsCache).filter((item) => symbolsList.includes(item.symbol))
              : [];

          // If API request succeeded, use that data, otherwise use filtered cache
          const availableInvestments = investmentsResult.data || filteredCache;

          if (!availableInvestments || availableInvestments.length === 0) {
            if (investmentsResult.isLoading) {
              return <div>Loading investments...</div>;
            }
            if (investmentsResult.isError) {
              return <div>Unable to load investment data. Please try again later.</div>;
            }
            return <div />;
          }

          return (
            <div>
              {availableInvestments.length > 0 && (
                <div>
                  <InvestmentTable
                    investments={availableInvestments}
                    wiims={wiimsResult.data}
                    columnSelections={columnSelections}
                    onChangeColumnSelections={
                      hideColumnControls
                        ? undefined
                        : (index, value) => {
                            stores.TableOptions.update((s) => {
                              if (!s[tableId]?.columnSelections) {
                                s[tableId].columnSelections = columnSelections;
                              }
                              s[tableId].columnSelections![index] = value;
                            });
                          }
                    }
                    sortingRule={sortingRule}
                    onChangeSortingRule={(rule) => {
                      stores.TableOptions.update((s) => {
                        s[tableId].sortingRule = rule;
                      });
                    }}
                    isLoading={investmentsResult.isLoading}
                    filters={disableFilters ? undefined : symbolsList.length > 5 ? filters : undefined}
                    onSetFilter={
                      disableFilters
                        ? undefined
                        : symbolsList.length > 5
                          ? (key) => {
                              const updatedFilters = mapValues(filters, () => false);
                              updatedFilters[key] = !filters[key];
                              stores.TableOptions.update((s) => {
                                s[tableId].filters = updatedFilters;
                              });
                            }
                          : undefined
                    }
                  />
                </div>
              )}
            </div>
          );
        })()
      )}

      {!symbols && symbolsList.length > 0 && (
        <Link
          to="/portfolios/add"
          css={css`
            display: block;
            margin-top: ${spacing.regular};
          `}
        >
          <Button variant="secondary" block>
            Add new portfolio
          </Button>
        </Link>
      )}
    </>
  );

  if (containerOnly) {
    return watchlistContent;
  }

  return (
    <Page showNavigationBar css={baseStyle}>
      {watchlistContent}
    </Page>
  );
};

export default WatchlistPage;
