import { FC } from 'react';
import { css } from '@emotion/react';
import { TableInstance } from 'react-table';
import Indicator, { Rating } from '../RatingIndicator';
import { fontStyles } from '../../styles';
import formatDisplay, { FormatterStyle } from '../../utils/formatDisplay';
import PriceRange from '../PriceRange';
import ColoredPercentChange from '../ColoredPercentChange';

import { ColumnId } from './columns';

const percentChangeColumns = [
  ColumnId.ChangePercent1d,
  ColumnId.ChangePercent1w,
  ColumnId.ChangePercent1m,
  ColumnId.ChangePercent3m,
  ColumnId.ChangePercentYtd,
  ColumnId.ChangePercent1y,
  ColumnId.ChangePercent5y,
  ColumnId.RevenueGrowth1y,
];

const percentColumns = [ColumnId.PercentMarketCapTraded1d, ColumnId.CashflowYield, ColumnId.InsidersPercentTraded3m];

interface Props extends TableInstance {
  emptyText: string;
}

const Cell: FC<Props> = ({ column, value, emptyText = '' }) => {
  const styles = {
    indicator: css`
      &:not(:first-of-type) {
        margin-left: 1px;
      }

      @media (max-width: 359px) {
        transform: scale(0.85);
        transform-origin: left center;
      }
    `,
    name: css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      ${fontStyles.flea}

      @media (max-width: 359px) {
        font-size: 0.85rem;
      }
    `,
    gray_percent: css`
      color: inherit;

      @media (max-width: 359px) {
        font-size: 0.85rem;
      }
    `,
    priceRange: css`
      width: 82%;
      display: inline-block;

      @media (max-width: 359px) {
        width: 100%;
      }
    `,
  };

  if (!value) return <span>{emptyText}</span>;

  if (column.id === ColumnId.BottomLine) {
    const isStock = 'profitRating' in value && value.profitRating != null && value.profitRating != 'MISSING';

    const headings = isStock ? ['profits', 'growth', 'price', 'insider'] : ['return', 'risk', 'size', 'fees'];

    const ratings = isStock
      ? [value.profitRating, value.growthRating, value.priceRating, value.insiderRating]
      : [value.returnRating, value.riskRating, value.sizeRating, value.feesRating];

    return (
      <span>
        {ratings.map((rating: Rating, index: number) => (
          <span css={styles.indicator} key={index}>
            <Indicator heading={headings[index]} rating={rating} />
          </span>
        ))}
      </span>
    );
  }

  if (column.id === ColumnId.Week52Range) {
    if (!value.high || !value.low || !value.current) return <span />;
    return (
      <div css={styles.priceRange}>
        <PriceRange highPrice={value.high} lowPrice={value.low} markedPrice={value.current} />
      </div>
    );
  }

  if (percentChangeColumns.includes(column.id)) {
    return <ColoredPercentChange value={value} />;
  }

  if (percentColumns.includes(column.id)) {
    return <span css={styles.gray_percent}>{formatDisplay(FormatterStyle.percentDetailed, value)}</span>;
  }

  if (column.id === ColumnId.Name) {
    return <span css={styles.name}>{value}</span>;
  }

  if (column.id === ColumnId.LatestPrice) {
    return <span>{formatDisplay(FormatterStyle.moneySmall, value)}</span>;
  }

  if (column.id === ColumnId.MarketCap) {
    return <span>{formatDisplay(FormatterStyle.moneyBig, value)}</span>;
  }

  return <span>{value}</span>;
};

export default Cell;
