import { css } from '@emotion/react';
import { useNotificationPreferences } from '../../../hooks/useNotificationPreferences';
import { colors, spacing, fontStyles } from '../../../styles';

const OnboardingNotification: React.FC = () => {
  const { preferences, handleToggle } = useNotificationPreferences();
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: ${spacing.deci};
        margin-top: ${spacing.deci};
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing.centi};
          margin-bottom: ${spacing.hecto};
        `}
      >
        {preferences.map((pref) => (
          <div
            key={pref.id}
            css={css`
              padding: ${spacing.deci};
              background-color: var(--card-background);
              border-radius: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <p
                css={css`
                  ${fontStyles.fatCat};
                `}
              >
                {pref.description}
              </p>
              <label
                css={css`
                  position: relative;
                  display: inline-block;
                  width: 60px;
                  height: 34px;

                  input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }

                  span {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    transition: 0.4s;
                    border-radius: 34px;

                    &:before {
                      position: absolute;
                      content: '';
                      height: 26px;
                      width: 26px;
                      left: 4px;
                      bottom: 4px;
                      background-color: white;
                      transition: 0.4s;
                      border-radius: 50%;
                    }
                  }

                  input:checked + span {
                    background-color: ${colors.petrolBlue};
                  }

                  input:checked + span:before {
                    transform: translateX(26px);
                  }
                `}
              >
                <input type="checkbox" checked={pref.enabled} onChange={() => void handleToggle(pref.id)} />
                <span></span>
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnboardingNotification;
