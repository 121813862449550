import React from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';
import { OnboardingButton } from '../Components/OnboardingButton';
import { colors, fontStyles, spacing } from '../../../styles';
import SafeArea from '../../SafeArea';
import FlowerAnimation from './Flower.json';

export const PurchaseResult: React.FC = () => {
  const history = useHistory();

  const handleFinish = () => {
    localStorage.setItem('hasCompletedOnboarding', 'true');
    history.push('/portfolios/add'); // Navigate to home
  };

  return (
    <SafeArea>
      <div
        css={css`
          width: 100%;
          max-width: 1024px;
          margin: 0 auto;
          padding: ${spacing.deci};
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-top: 35%;
          `}
        >
          <div
            css={css`
              position: relative;
              width: 120px;
              height: 120px;
              display: flex;
              justify-content: center;
              margin-bottom: 48px;
            `}
          >
            <Lottie
              animationData={FlowerAnimation}
              alt="Bloom AI"
              autoplay={true}
              loop={false}
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          </div>

          <div
            css={css`
              text-align: center;
              margin-bottom: 32px;
              padding: 0 24px;
            `}
          >
            <h1
              css={css`
                ${fontStyles.shark};
                color: ${colors.liquorice};
                margin-bottom: ${spacing.deci};
                font-size: 30px;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Bloom AI activated!
            </h1>

            <p
              css={css`
                ${fontStyles.dog};
                color: ${colors.liquorice};
                font-size: 16px;
                margin-top: 8px;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Let&apos;s create a portfolio together!
            </p>
          </div>
        </div>
        <div
          css={css`
            padding: ${spacing.deci};
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            padding-bottom: calc(${spacing.deci} + env(safe-area-inset-bottom, 0px));

            /* Center and limit width for large screens */
            @media (min-width: 1024px) {
              width: 1024px;
              left: 50%;
              transform: translateX(-50%);
            }
          `}
        >
          <OnboardingButton onClick={handleFinish}>Get Started</OnboardingButton>
        </div>
      </div>
    </SafeArea>
  );
};
