import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { AppBar } from '../Components/AppBar';
import { onboardingImages } from '../../../assets/onboardingImages/index';
import { Slide } from '../Types/Types';
import { OnboardingButton } from '../Components/OnboardingButton';
import { colors, fontStyles, spacing } from '../../../styles';
import OnboardingNotification from '../Components/OnboardingNotification';
import OnboardingSocialMedial from '../Components/OnboardingSocialMedial';
import SafeArea from '../../SafeArea';

export const OnboardingView: React.FC = () => {
  const history = useHistory();
  const [index, setIndex] = useState<number>(() => {
    const saved = localStorage.getItem('onboardingIndex');
    return saved ? parseInt(saved) : 0;
  });
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.getAttribute('data-theme') === 'dark');

  useEffect(() => {
    localStorage.setItem('onboardingIndex', index.toString());
  }, [index]);

  useEffect(() => {
    const handleThemeChange = () => {
      setIsDarkMode(document.documentElement.getAttribute('data-theme') === 'dark');
    };

    handleThemeChange();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
          handleThemeChange();
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true });

    return () => observer.disconnect();
  }, []);

  const currentSlides: Slide[] = [
    {
      title: 'Understand why\nstock prices move',
      description: 'We curate news items to show you why prices moved that day',
      image: onboardingImages.slide1,
    },
    {
      title: 'Create expert portfolios for any strategy',
      description: 'Get a research report with the best stock and ETF picks',
      content: null,
      image: isDarkMode ? onboardingImages.slide2b : onboardingImages.slide2,
    },
    {
      title: 'Protect yourself from potential drawdown',
      description: 'Backtest to see your portfolio risk during a recession',
      content: null,
      image: isDarkMode ? onboardingImages.slide3b : onboardingImages.slide3,
    },
    {
      title: 'Stay on top of trends',
      description: 'Get alerts for price changes and big events',
      image: onboardingImages.slide4,
    },
    {
      title: 'Enable notifications',
      description: 'Choose what alerts you want to receive',
      content: <OnboardingNotification />,
    },
    {
      title: 'Where did you hear about us?',
      description: '',
      content: (
        <OnboardingSocialMedial
          onSelect={(source) => {
            console.log('Selected source:', source);
            setSelectedSource(source);
          }}
        />
      ),
    },
  ];

  const handleNext = () => {
    if (index < currentSlides.length - 1) {
      setIndex(index + 1);
    } else {
      // Navigate to payment, but if promo code was selected, pass that info
      if (selectedSource === 'promoCode') {
        // Store in localStorage to indicate promo code was selected
        localStorage.setItem('usePromoCode', 'true');
      }
      history.push('/payment');
    }
  };

  const handleBack = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      history.goBack();
    }
  };

  return (
    <SafeArea backgroundColor={isDarkMode ? colors.liquorice : '#fffefc'}>
      <div
        css={css`
          width: 100%;
          max-width: 1024px;
          height: 100%;
          margin: 0 auto;
          position: relative;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 10;
            background-color: ${isDarkMode ? colors.liquorice : '#fffefc'};
            padding-top: ${Capacitor.isNativePlatform() ? (Capacitor.getPlatform() === 'ios' ? '0' : '24px') : '24px'};

            /* Center and limit width for large screens */
            @media (min-width: 1024px) {
              width: 1024px;
              margin: 0 auto;
              left: 0;
              right: 0;
              transform: none;
            }
          `}
        >
          <AppBar
            onBack={handleBack}
            showBackButton={true}
            showProgress={index >= 0}
            progressIndex={index}
            totalSteps={currentSlides.length}
          />
        </div>

        <div
          css={css`
            padding: ${spacing.deci};
            padding-top: ${Capacitor.isNativePlatform()
              ? Capacitor.getPlatform() === 'ios'
                ? '0'
                : spacing.milli
              : spacing.milli};
            overflow-y: auto;
            height: 100%;
            flex: 1;
          `}
        >
          <div>
            <h1
              css={css`
                ${fontStyles.shark};
                color: ${colors.liquorice};
                margin-bottom: ${spacing.deci};
                font-size: 26px;
                line-height: 2.2rem;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              {currentSlides[index].title}
            </h1>
            <p
              css={css`
                ${fontStyles.dog};
                color: ${colors.liquorice};
                white-space: pre-line;
                margin: 0;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              {currentSlides[index].description}
            </p>
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: start;
              margin-top: 16px;
              padding-bottom: 88px; /* Increased to give more space for the fixed button */
            `}
          >
            {currentSlides[index].content && <div>{currentSlides[index].content}</div>}

            {!currentSlides[index].content && (
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <img
                  src={currentSlides[index].image || ''}
                  alt={currentSlides[index].title}
                  css={css`
                    width: 100%;
                    max-height: 80vh;
                    object-fit: contain;
                  `}
                />
              </div>
            )}
          </div>
        </div>

        <div
          css={css`
            padding: 16px;
            border-top: 0.8px solid ${colors.cloud};
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 10;
            background-color: ${isDarkMode ? colors.liquorice : colors.sugar};
            padding-bottom: ${Capacitor.isNativePlatform() ? `calc(16px + env(safe-area-inset-bottom, 0px))` : '16px'};
            box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);

            /* Center and limit width for large screens */
            @media (min-width: 1024px) {
              width: 1024px;
              left: 50%;
              transform: translateX(-50%);
            }
          `}
        >
          <OnboardingButton onClick={handleNext}>Next</OnboardingButton>
        </div>
      </div>
    </SafeArea>
  );
};
