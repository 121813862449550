import React from 'react';
import { css } from '@emotion/react';
import { colors, fontStyles } from '../../../styles';
import ArrowBack from '../../../assets/Icons/arrowback.svg';
import { ProgressBar } from './ProgressBar';

interface AppBarProps {
  onBack: () => void;
  showBackButton?: boolean;
  showProgress?: boolean;
  progressIndex?: number;
  totalSteps?: number;
  showRightButton?: boolean;
  rightButtonText?: string;
  onRightButtonClick?: () => void;
}

export const AppBar: React.FC<AppBarProps> = ({
  onBack,
  showBackButton = true,
  showProgress = false,
  progressIndex = 0,
  totalSteps = 1,
  showRightButton = false,
  rightButtonText = '',
  onRightButtonClick = undefined,
}) => {
  return (
    <div style={{ paddingRight: 16, paddingLeft: 16 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showBackButton && (
          <button onClick={onBack} style={{ width: 22, height: 22, marginRight: 16 }}>
            <img src={ArrowBack} alt="Arrow Back" style={{ width: 22, height: 22 }} />
          </button>
        )}
        <div style={{ flex: 1 }}>
          {showProgress && <ProgressBar currentStep={progressIndex} totalSteps={totalSteps} />}
        </div>
        {showRightButton && (
          <button onClick={onRightButtonClick} style={{ marginLeft: 16 }}>
            <p
              css={css`
                ${fontStyles.flea};
                color: ${colors.silver};
              `}
            >
              {rightButtonText}
            </p>
          </button>
        )}
      </div>
    </div>
  );
};
