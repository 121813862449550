import { useState } from 'react';
import { useHistory } from 'react-router';
import { css } from '@emotion/react';
import { spacing, fontStyles, colors } from '../../styles';
import Page from '../Page';
import Header from '../Header';
import * as storage from '../../storage';

const AddPortfolio: React.FC = () => {
  const [strategy, setStrategy] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    if (!strategy.trim()) return;

    setIsCreating(true);

    try {
      // LLM will generate a title based on the strategy
      // For now, create a placeholder title that will be replaced by the LLM
      const generatedTitle = 'Portfolio';

      const portfolio: Omit<storage.Portfolio, 'id'> = {
        title: generatedTitle,
        strategy: strategy.trim(),
        symbols: [],
        allocations: [],
      };

      // Save the portfolio to storage
      const portfolioId = await storage.savePortfolio(portfolio);

      // Always go to loading screen since we have a strategy
      history.push('/portfolios/loading', {
        strategy: strategy.trim(),
        portfolioId, // Pass the ID so the loading screen can update this portfolio
      });
    } catch (error) {
      console.error('Error creating portfolio:', error);
      setIsCreating(false);
    }
  };

  const pageStyles = css`
    padding: ${spacing.milli};
    padding-bottom: calc(${spacing.regular} + ${spacing.giga});

    @media (max-width: 359px) {
      padding: ${spacing.micro} ${spacing.micro};
    }
  `;

  const headingStyles = css`
    ${fontStyles.shark};
    margin: 0;
    margin-top: ${spacing.centi};
    margin-bottom: ${spacing.deci};
  `;

  const instructionStyles = css`
    ${fontStyles.cat};
    color: ${colors.silver};
  `;

  const inputStyles = css`
    width: 100%;
    border: 1px solid ${colors.cloud};
    border-radius: 8px;
    padding: ${spacing.deci};
    ${fontStyles.cat};
    margin-top: ${spacing.deci};
    background-color: ${colors.sugar};
    color: ${colors.liquorice};

    html[data-theme='dark'] & {
      background-color: #242424;
      color: ${colors.sugar};
      border-color: ${colors.tungsten};
    }

    &:focus {
      outline: none;
      border-color: ${colors.petrolBlue};
    }

    &::placeholder {
      ${fontStyles.cat};
      color: ${colors.silver};
    }
  `;

  const textareaStyles = css`
    ${inputStyles};
    min-height: 120px;
    resize: vertical;
    margin-top: ${spacing.deka};
  `;

  const buttonStyles = css`
    display: block;
    width: 100%;
    background-color: ${colors.petrolBlue};
    color: ${colors.snow};
    border: none;
    border-radius: 8px;
    padding: ${spacing.deci};
    ${fontStyles.horse};
    margin-top: ${spacing.kilo};
    margin-bottom: ${spacing.deka};
    cursor: pointer;
    opacity: ${!strategy.trim() ? 0.5 : 1};
    font-weight: 500;
    transition: all 0.2s ease;

    &:hover {
      opacity: ${!strategy.trim() ? 0.5 : 0.9};
    }

    @media (max-width: 359px) {
      padding: ${spacing.centi} ${spacing.deci};
      font-size: 14px;
    }
  `;

  return (
    <Page showNavigationBar>
      <Header headingText="" onBack={() => history.push('/portfolios')} />
      <div css={pageStyles}>
        <h1 css={headingStyles}>Add new portfolio</h1>
        <p css={instructionStyles}>
          Describe your investment approach and we&apos;ll suggest stocks that match your criteria.
        </p>

        <textarea
          id="portfolio-strategy"
          css={css`
            ${textareaStyles};
            margin-top: ${spacing.regular};
            min-height: 160px;
          `}
          value={strategy}
          onChange={(e) => setStrategy(e.target.value)}
          placeholder="e.g. technology stocks with high growth potential"
          autoFocus
        />

        <button css={buttonStyles} onClick={handleSubmit} disabled={!strategy.trim() || isCreating}>
          {isCreating ? 'Adding...' : 'Add new portfolio'}
        </button>
      </div>
    </Page>
  );
};

export default AddPortfolio;
