import React from 'react';
import { css } from '@emotion/react';
import { spacing, fontStyles, colors, borderRadius } from '../../styles';
import formatDisplay, { FormatterStyle } from '../../utils/formatDisplay';
import { BasicInvestment, Rating } from '../../apis/backend';
import ColoredPercentChange from '../ColoredPercentChange';
import PriceRange from '../PriceRange';
import RatingIndicator, { Size } from '../RatingIndicator';
import { TimeRange } from '../../types';
import { getTimeRangeFromMetric, isChangePercentMetric } from './PortfolioColumnSelector';

// Extended BasicInvestment with allocation percentage
export interface PortfolioInvestment extends Partial<BasicInvestment> {
  rationale: boolean | undefined;
  allocation_percentage?: number;
  symbol: string;
  name: string;
}

interface InvestmentCardProps {
  investment: PortfolioInvestment;
  selectedMetric: string;
  showRationale?: boolean;
  onClick?: () => void;
}

// Define formatter styles for each metric type
const metricFormatters: Record<string, FormatterStyle> = {
  latestPrice: FormatterStyle.moneySmall,
  changePercent1d: FormatterStyle.percentChange,
  changePercent1w: FormatterStyle.percentChange,
  changePercent1m: FormatterStyle.percentChange,
  changePercent3m: FormatterStyle.percentChange,
  changePercentYtd: FormatterStyle.percentChange,
  changePercent1y: FormatterStyle.percentChange,
  changePercent5y: FormatterStyle.percentChange,
  marketCap: FormatterStyle.moneyBig,
  allocation_percentage: FormatterStyle.percent,
  // New metrics
  priceToEarnings: FormatterStyle.number,
  priceToSales: FormatterStyle.number,
  priceToSalesGrowth: FormatterStyle.number,
  revenueGrowth1y: FormatterStyle.percentDetailed,
  cashflowYield: FormatterStyle.percentDetailed,
  evToGrossProfit: FormatterStyle.number,
  insidersPercentTraded3m: FormatterStyle.percentDetailed,
};

const InvestmentCard: React.FC<InvestmentCardProps> = ({ investment, selectedMetric, showRationale, onClick }) => {
  // Base card styles
  const cardStyles = css`
    background-color: var(--card-background);
    border-radius: ${borderRadius};
    border: 1px solid ${colors.cloud};
    padding: ${spacing.deci};
    cursor: pointer;
    transition: transform 0.2s ease;
    word-break: break-word;
    overflow: hidden;

    @media (max-width: 359px) {
      padding: ${spacing.centi};
    }

    &:hover {
      transform: translateY(-2px);
    }

    html[data-theme='dark'] & {
      border-color: var(--border-color);
    }
  `;

  const symbolStyles = css`
    ${fontStyles.roboCat};
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: var(--link-color);
    overflow: hidden;
  `;

  const nameStyles = css`
    ${fontStyles.cat};
    margin: 0;
    margin-bottom: ${spacing.centi};
    color: var(--secondary-text);
    html[data-theme='dark'] & {
      color: ${colors.fog};
    }
  `;

  const bottomLineStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${spacing.centi};
  `;

  const bottomLineIconsStyles = css`
    display: flex;
    gap: ${spacing.centi};
  `;

  const valueStyles = css`
    ${fontStyles.roboCat};
    color: var(--text-color);
    font-weight: 500;
  `;

  const rationaleStyles = css`
    ${fontStyles.thinFlea};
    margin: ${spacing.centi} 0 0 0;
    color: var(--text-color);
  `;

  // Get the value for display based on selected metric
  const getValue = (): React.ReactNode => {
    // Special case for 52-week range - use PriceRange component for visualization
    if (selectedMetric === 'week52Range') {
      const high = investment.week52High;
      const low = investment.week52Low;
      const current = investment.latestPrice;

      if (high !== undefined && low !== undefined && current !== undefined) {
        // This is a placeholder return to indicate we'll handle the range separately
        return null;
      }
      return 'N/A';
    }

    // For change percent metrics, use ColoredPercentChange component
    if (isChangePercentMetric(selectedMetric)) {
      const value = investment[selectedMetric as keyof PortfolioInvestment] as number;
      // We don't need the timeRange here
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const timeRange = getTimeRangeFromMetric(selectedMetric) || TimeRange.days1;

      // Only use value without timeRange to hide the time period label
      return value !== undefined ? <ColoredPercentChange value={value} /> : 'N/A';
    }

    // For all other metrics, format the value
    const value = investment[selectedMetric as keyof PortfolioInvestment] as number;
    const formatter = metricFormatters[selectedMetric];

    return value !== undefined ? formatDisplay(formatter, value) : 'N/A';
  };

  // Define styles for 52-week range display
  const priceRangeContainerStyles = css`
    margin-top: ${spacing.centi};
    padding-top: ${spacing.milli};
  `;

  return (
    <div css={cardStyles} onClick={onClick}>
      <p css={symbolStyles}>
        <span>{investment.symbol}</span>
        {/* Always show the metric value in the top right corner */}
        <span css={valueStyles}>
          {selectedMetric === 'allocation_percentage' && investment.allocation_percentage !== undefined
            ? `${investment.allocation_percentage}%` // Directly add % since allocation_percentage is already an integer
            : getValue()}
        </span>
      </p>

      {investment.name && investment.name !== investment.symbol && <p css={nameStyles}>{investment.name}</p>}

      {/* Show 52-week range component if selected */}
      {selectedMetric === 'week52Range' &&
        investment.week52High !== undefined &&
        investment.week52Low !== undefined &&
        investment.latestPrice !== undefined && (
          <div css={priceRangeContainerStyles}>
            <PriceRange
              highPrice={investment.week52High}
              lowPrice={investment.week52Low}
              markedPrice={investment.latestPrice}
            />
          </div>
        )}

      {/* Show rationale if available and enabled */}
      {showRationale && investment.rationale && <p css={rationaleStyles}>{investment.rationale}</p>}

      {/* Bottom line indicators */}
      <div css={bottomLineStyles}>
        <div css={bottomLineIconsStyles}>
          {/* Use different indicators based on investment category */}
          {investment.category === 'ETF' ? (
            // For ETFs: return, risk, size, fees
            <>
              <RatingIndicator
                heading="return"
                rating={investment.bottomLine?.returnRating || Rating.Missing}
                size={Size.medium}
              />
              <RatingIndicator
                heading="risk"
                rating={investment.bottomLine?.riskRating || Rating.Missing}
                size={Size.medium}
              />
              <RatingIndicator
                heading="size"
                rating={investment.bottomLine?.sizeRating || Rating.Missing}
                size={Size.medium}
              />
              <RatingIndicator
                heading="fees"
                rating={investment.bottomLine?.feesRating || Rating.Missing}
                size={Size.medium}
              />
            </>
          ) : (
            // For stocks: profitability, growth, price, insider activity
            <>
              <RatingIndicator
                heading="profitability"
                rating={investment.bottomLine?.profitRating || Rating.Missing}
                size={Size.medium}
              />
              <RatingIndicator
                heading="growth"
                rating={investment.bottomLine?.growthRating || Rating.Missing}
                size={Size.medium}
              />
              <RatingIndicator
                heading="price"
                rating={investment.bottomLine?.priceRating || Rating.Missing}
                size={Size.medium}
              />
              <RatingIndicator
                heading="insider activity"
                rating={investment.bottomLine?.insiderRating || Rating.Missing}
                size={Size.medium}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentCard;
