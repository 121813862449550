import { useState, useEffect } from 'react';
import { PushNotificationService } from '../services/pushNotificationService';
import { getPortfolios, Portfolios } from '../storage';

export interface NotificationPreference {
  id: string;
  description: string;
  enabled: boolean;
}

const DEFAULT_PREFERENCES: NotificationPreference[] = [
  {
    id: 'daily_alerts',
    description: 'Daily change +- 5%',
    enabled: false,
  },
  {
    id: 'weekly_alerts',
    description: 'Weekly change +- 10%',
    enabled: false,
  },
  {
    id: 'news_alerts',
    description: 'New news articles',
    enabled: false,
  },
];

export const useNotificationPreferences = () => {
  const [watchlistSymbols, setWatchlistSymbols] = useState<string[]>([]);

  // Subscribe to portfolio changes and load watchlist symbols dynamically
  useEffect(() => {
    // Subscribe to portfolio changes
    const unsubscribe = Portfolios.subscribe(
      (state) => state.portfolios,
      (portfolios) => {
        console.log('[DEBUG] useNotificationPreferences - portfolio subscription triggered with:', portfolios);

        // Print each portfolio with its symbols for debugging
        portfolios.forEach((portfolio) => {
          console.log(`[DEBUG] Portfolio '${portfolio.title}' has symbols:`, portfolio.symbols || []);
        });

        const allSymbols = Array.from(new Set(portfolios.flatMap((portfolio) => portfolio.symbols || [])));
        console.log('[DEBUG] All unique symbols after update:', allSymbols);

        setWatchlistSymbols(allSymbols);
      },
    );

    // Initial load
    const loadSymbols = async () => {
      const portfolios = await getPortfolios();
      console.log('[DEBUG] useNotificationPreferences - initial load with portfolios:', portfolios);

      const allSymbols = Array.from(new Set(portfolios.flatMap((portfolio) => portfolio.symbols || [])));
      console.log('[DEBUG] Initial load - all unique symbols:', allSymbols);

      setWatchlistSymbols(allSymbols);
    };
    loadSymbols();

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const [preferences, setPreferences] = useState<NotificationPreference[]>(() => {
    const savedPreferences = localStorage.getItem('notificationPreferences');
    return savedPreferences ? JSON.parse(savedPreferences) : DEFAULT_PREFERENCES;
  });

  const handleToggle = async (id: string): Promise<void> => {
    console.log(`[DEBUG] handleToggle called for notification type: ${id}`);
    console.log('[DEBUG] Current watchlistSymbols:', watchlistSymbols);

    // Update the state immediately for the UI
    const newPreferences = preferences.map((pref) => (pref.id === id ? { ...pref, enabled: !pref.enabled } : pref));
    setPreferences(newPreferences);

    // Process the configuration in the background
    const enabledTypes = newPreferences.filter((pref) => pref.enabled).map((pref) => pref.id);
    console.log('[DEBUG] New enabled types:', enabledTypes);

    // Save to localStorage immediately
    localStorage.setItem('notificationPreferences', JSON.stringify(newPreferences));
    console.log('[DEBUG] Saved preferences to localStorage');

    // Process the notification configuration in the background
    (async () => {
      if (enabledTypes.length > 0) {
        console.log('[DEBUG] Processing notification config with symbols:', watchlistSymbols);
        try {
          const permissionGranted = await PushNotificationService.initialize({
            notificationTypes: enabledTypes,
            watchlistSymbols, // Use current state
          });
          console.log('[DEBUG] Permission granted:', permissionGranted);

          if (!permissionGranted) {
            console.log('[DEBUG] Permission denied, disabling all notifications');
            const disabledPreferences = preferences.map((pref) => ({
              ...pref,
              enabled: false,
            }));
            setPreferences(disabledPreferences);
            localStorage.setItem('notificationPreferences', JSON.stringify(disabledPreferences));
            return;
          }

          console.log('[DEBUG] Updating notification config with watchlistSymbols:', watchlistSymbols);
          await PushNotificationService.updateConfig({
            notificationTypes: enabledTypes,
            watchlistSymbols, // Use current state
          });
          console.log('[DEBUG] Notification config updated successfully');
        } catch (error) {
          console.error('[DEBUG] Failed to update notifications:', error);
          setPreferences(preferences);
          localStorage.setItem('notificationPreferences', JSON.stringify(preferences));
        }
      } else {
        console.log('[DEBUG] No enabled notification types, skipping update');
      }
    })();
  };

  return {
    preferences,
    handleToggle,
    watchlistSymbols,
  };
};
