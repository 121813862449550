import { App } from '@capacitor/app';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Badge } from '@capawesome/capacitor-badge';
import { registerDevice } from '../apis/backend';

interface NotificationConfig {
  notificationTypes: string[];
  watchlistSymbols: string[];
}

interface NotificationData {
  notification_type?: string;
  symbols?: string;
  [key: string]: any;
}
let isAppInForeground = false; // Default to false

/**
 * Clear app badge based on platform
 */
// Initialize app state listener early
App.addListener('appStateChange', async (state) => {
  isAppInForeground = state.isActive;
  console.log('[PUSH-SERVICE] App state changed:', isAppInForeground);
  if (!isAppInForeground) {
    return;
  }
  try {
    if (Capacitor.isNativePlatform()) {
      await Badge.clear();
      console.log('[PUSH-SERVICE] Badge cleared (native)');
    } else if (Capacitor.getPlatform() === 'web') {
      // Check if Badging API is available in browser
      if (navigator && 'clearAppBadge' in navigator) {
        await navigator.clearAppBadge();
        console.log('[PUSH-SERVICE] Badge cleared (web)');
      } else {
        console.log('[PUSH-SERVICE] Badge API not available in this browser');
      }
    }
  } catch (error) {
    console.error('[PUSH-SERVICE] Error clearing badge:', error);
  }
});

App.getState().then((state) => {
  isAppInForeground = state.isActive;
});

export class PushNotificationService {
  private static currentToken: string | null = null;
  private static isInitialized = false;

  static async initialize(config: NotificationConfig): Promise<boolean> {
    try {
      console.groupCollapsed('🟢 INITIALIZING PUSH NOTIFICATION SERVICE');
      const platform = Capacitor.getPlatform();
      console.log('⚙️ Platform:', platform);
      if (Capacitor.isNativePlatform()) {
        return await this.initializeNative(config);
      } else if (platform === 'web') {
        return await this.initializeWeb(config);
      } else {
        console.warn('[PUSH-SERVICE] Platform not supported:', platform);
        return false;
      }
    } catch (error) {
      console.error('[PUSH-SERVICE] General error during initialization:', error);
      console.groupEnd();
      return false;
    }
  }

  private static async initializeNative(config: NotificationConfig): Promise<boolean> {
    try {
      console.groupCollapsed('📱INITIALIZING NATIVE PUSH SERVICE');
      console.log('[PUSH-SERVICE] Initializing for native platform');
      const permissionStatus = await FirebaseMessaging.requestPermissions();
      if (permissionStatus.receive !== 'granted') {
        console.warn('❌📱 Permissions denied for native platform');
        return false;
      }

      console.log('[PUSH-SERVICE] 🎯 Permission granted, getting FCM token...');
      const { token } = await FirebaseMessaging.getToken();
      console.log('🔔 FCM Token received:', token.slice(0, 10) + '...');
      this.currentToken = token;
      await this.sendTokenToBackend(config);

      FirebaseMessaging.addListener('notificationReceived', (notification) =>
        this.handleNotification('user_notification_receive', notification),
      );

      FirebaseMessaging.addListener('notificationActionPerformed', (notification) =>
        this.handleNotification('user_notification_open', notification),
      );

      this.isInitialized = true;
      return true;
    } catch (error) {
      console.error('[PUSH-SERVICE] Error in native initialization:', error);
      console.groupEnd();
      return false;
    }
  }

  private static async initializeWeb(config: NotificationConfig): Promise<boolean> {
    try {
      console.groupCollapsed('💻 INITIALIZING WEB PUSH SERVICE');
      console.log('[PUSH-SERVICE] Initializing for web platform');
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('[PUSH-SERVICE] Service worker registered');

      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.warn('❌💻 Web permissions denied');
        return false;
      }

      console.log('[PUSH-SERVICE] 🎯 Web permissions granted');
      const { messaging } = await import('../config/firebase.config');
      const { getToken, onMessage } = await import('firebase/messaging');

      const currentToken = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_APP_VAPID_KEY,
        serviceWorkerRegistration: registration,
      });

      if (currentToken) {
        this.currentToken = currentToken;
        console.log('🔔 Web token received:', currentToken.slice(0, 10) + '...');
        await this.sendTokenToBackend(config);
      }

      onMessage(messaging, (payload) => {
        console.log('[PUSH-SERVICE] 📨 Message received:', payload);
      });

      this.isInitialized = true;
      return true;
    } catch (error) {
      console.error('[PUSH-SERVICE] Error in web initialization:', error);
      console.groupEnd();
      return false;
    }
  }

  private static async handleNotification(eventName: string, notification: any): Promise<void> {
    console.groupCollapsed('🟢 HANDLING NOTIFICATION EVENT');
    try {
      const appState = isAppInForeground ? 'foreground' : 'background';
      const notificationData = (notification.notification?.data.custom_data || {}) as NotificationData;

      const eventData = {
        name: eventName,
        params: {
          notification_type: notificationData.notification_type || 'unknown',
          symbols: notificationData.symbols || '',
          platform: Capacitor.getPlatform(),
          app_state: appState,
          timestamp: new Date().toISOString(),
        },
      };
      console.log(`[PUSH-SERVICE] Event: ${eventName}`, JSON.stringify(eventData, null, 2));
      await FirebaseAnalytics.logEvent(eventData);
    } catch (error) {
      console.error(`[PUSH-SERVICE] Error handling notification event "${eventName}":, error`);
      console.groupEnd();
    }
  }

  static async updateConfig(config: NotificationConfig): Promise<boolean> {
    if (!this.isInitialized) {
      const initialized = await this.initialize(config);
      if (!initialized) return false;
    }

    if (!this.currentToken) return false;
    try {
      console.log('[PUSH-SERVICE] Updating config with symbols:', config.watchlistSymbols);
      await this.sendTokenToBackend(config);
      return true;
    } catch (error) {
      console.error('[PUSH-SERVICE] Error updating config:', error);
      return false;
    }
  }

  private static async sendTokenToBackend(config: NotificationConfig): Promise<void> {
    console.groupCollapsed('📡 SENDING TOKEN TO BACKEND');
    if (!this.currentToken) {
      throw new Error('[PUSH-SERVICE] No token available');
    }
    try {
      console.log('[PUSH-SERVICE] Registering device:', {
        token: this.currentToken.slice(0, 10) + '...',
        notificationTypes: config.notificationTypes,
        watchlistSymbols: config.watchlistSymbols,
      });
      await registerDevice(this.currentToken, Capacitor.getPlatform(), {
        notificationTypes: config.notificationTypes,
        watchlistSymbols: config.watchlistSymbols,
      });
      console.log('[PUSH-SERVICE] Device registered successfully');
    } catch (error) {
      console.error('[PUSH-SERVICE] Error registering device:', error);
      throw error;
    }
    console.groupEnd();
  }
}
