import React from 'react';
import { css } from '@emotion/react';
import { colors, fontStyles } from '../../../styles';

interface NotificationToggleProps {
  isEnabled: boolean;
  onToggle: () => void;
  label?: string;
}

export const NotificationToggle: React.FC<NotificationToggleProps> = ({
  isEnabled,
  onToggle,
  label = 'Notify me before trial ends',
}) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--card-background);
        border-radius: 8px;
        padding: 16px;
        cursor: pointer;
      `}
      onClick={onToggle}
    >
      <p
        css={css`
          ${fontStyles.dog};
          color: ${colors.liquorice};
          font-size: 16px;
          html[data-theme='dark'] & {
            color: ${colors.sugar};
          }
        `}
      >
        {label}
      </p>

      <div
        css={css`
          position: relative;
          width: 52px;
          height: 32px;
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            width: 52px;
            height: 32px;
            border-radius: 34px;
            background-color: ${isEnabled ? colors.petrolBlue : '#E0E0E0'};
            transition: 0.3s;
            position: relative;

            &:after {
              position: absolute;
              content: '';
              height: 26px;
              width: 26px;
              left: 3px;
              bottom: 3px;
              background-color: white;
              transition: 0.3s;
              border-radius: 50%;
              transform: ${isEnabled ? 'translateX(20px)' : 'translateX(0)'};
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            }
          `}
        />
      </div>
    </div>
  );
};
