import { css } from '@emotion/react';
import { FC, useEffect } from 'react';
import { colors, fontStyles, spacing } from '../../styles';
import closeIcon from '../../assets/close.svg';

export interface BottomSheetProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  onOpen?: () => void;
  children: React.ReactNode;
}

// Extremely simplified bottom sheet with minimal dependencies
const BottomSheet: FC<BottomSheetProps> = ({ isOpen, title, onClose, onOpen, children }) => {
  // Run side effects only when sheet opens or closes
  useEffect(() => {
    if (isOpen) {
      // Disable body scrolling when open
      document.body.style.overflow = 'hidden';

      // Call onOpen if provided
      if (onOpen) onOpen();
    }

    // Clean up when component unmounts or isOpen changes
    return () => {
      // Re-enable body scrolling
      document.body.style.overflow = '';
    };
  }, [isOpen, onOpen]);

  // Don't render anything if not open
  if (!isOpen) return null;

  return (
    <div className="bottom-sheet-overlay" css={overlayStyles} onClick={onClose}>
      <div className="bottom-sheet-container" css={containerStyles} onClick={(e) => e.stopPropagation()}>
        <div css={headerStyles}>
          {title && <h2 css={titleStyles}>{title}</h2>}
          <button css={closeButtonStyles} onClick={onClose} aria-label="Close">
            <img src={closeIcon} alt="Close" width="16" height="16" />
          </button>
        </div>
        <div css={contentStyles}>{children}</div>
      </div>
    </div>
  );
};

// Simple styles without complex animations
const overlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
`;

const containerStyles = css`
  background-color: var(--background-color);
  border-radius: 16px 16px 0 0;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.1);
  padding-bottom: env(safe-area-inset-bottom, 16px);
  z-index: 1001; /* Ensure bottom sheet content is above overlay */

  html[data-theme='dark'] & {
    background-color: ${colors.liquorice};
    border-top: 1px solid ${colors.tungsten}40;
  }
`;

const headerStyles = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.centi} ${spacing.deci};
  border-bottom: 1px solid var(--border-color);

  html[data-theme='dark'] & {
    border-bottom: 1px solid ${colors.tungsten}40;
  }
`;

const titleStyles = css`
  ${fontStyles.dog};
  color: var(--text-color);
  margin: ${spacing.deci} 0;
  text-align: center;
  width: 100%;
`;

const closeButtonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: ${spacing.centi};
  top: 50%;
  transform: translateY(-50%);
  padding: ${spacing.milli};
`;

const contentStyles = css`
  padding: ${spacing.deci};
`;

export default BottomSheet;
