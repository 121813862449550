import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import * as storage from '../../storage';
import { spacing, fontStyles, colors, borderRadius, fontFamilies } from '../../styles';
import Page from '../Page';
import Header from '../Header';
import NavigationBar from '../NavigationBar';
import { TimeRange } from '../../types';
import { BasicInvestment, getBasicInvestments } from '../../apis/backend';
import * as stores from '../../stores';

// Import SVG icons
import chatIcon from './icons/chat.svg';
import editIcon from './icons/edit.svg';

// Import our new components
import PortfolioColumnSelector from './PortfolioColumnSelector';
import InvestmentCard, { PortfolioInvestment } from './InvestmentCard';

interface RouteParams {
  id: string;
}

interface InvestmentData extends Partial<BasicInvestment> {
  timeRange?: TimeRange;
}

const ViewPortfolio: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const [showRationale, setShowRationale] = useState(false);
  const [isStrategyExpanded, setIsStrategyExpanded] = useState(false);
  const [investmentsData, setInvestmentsData] = useState<Record<string, InvestmentData>>({});

  // Get selected metric from TableOptions store or use default
  const selectedMetric = stores.TableOptions.useState((s) => s.portfolio?.selectedMetric) || 'allocation_percentage';

  // Function to update the selected metric
  const handleMetricChange = (metric: string) => {
    stores.TableOptions.update((s) => {
      if (!s.portfolio) s.portfolio = {};
      s.portfolio.selectedMetric = metric;
    });
  };

  // Get portfolio data from store with loading state
  const { portfolios, isLoading: isPortfoliosLoading } = stores.Portfolios.useState();
  const portfolio = portfolios.find((p) => p.id === id) || null;

  // Get all symbols from the portfolio
  const allSymbols = portfolio
    ? (() => {
        const allocationSymbols = (portfolio.allocations || []).map((a) => a.symbol);
        const watchlistSymbols = portfolio.symbols || [];
        return [...new Set([...allocationSymbols, ...watchlistSymbols])];
      })()
    : [];

  // Fetch investment data using react-query
  // The investments variable is used in onSuccess callback
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: investments, isLoading: isInvestmentsLoading } = useQuery(
    ['portfolioInvestments', id],
    async () => {
      if (portfolio && allSymbols.length > 0) {
        return await getBasicInvestments(allSymbols);
      }
      return [];
    },
    {
      enabled: !!portfolio && allSymbols.length > 0,
      onSuccess: (data) => {
        // Convert array to a map indexed by symbol
        const investmentsMap: Record<string, InvestmentData> = {};

        // Set default timeRange and add to map
        data.forEach((inv) => {
          investmentsMap[inv.symbol] = {
            ...inv,
            timeRange: TimeRange.days1,
          };
        });

        setInvestmentsData(investmentsMap);
      },
    },
  );

  // Function to navigate to chat with portfolio context
  const navigateToChat = (customMessage?: string) => {
    // Get symbols from different sources
    const symbols = portfolio?.symbols || [];
    const allocationSymbols = portfolio?.allocations ? portfolio.allocations.map((alloc) => alloc.symbol) : [];

    // Combine all symbols and remove duplicates
    const allSymbols = [...new Set([...symbols, ...allocationSymbols])];

    // Default message
    let message = '';

    // If custom message is provided, use it
    if (customMessage) {
      message = `${customMessage} for portfolio ${portfolio?.title || 'My portfolio'}: ${allSymbols.slice(0, 5).join(', ')}`;
    } else {
      // If we have no symbols, provide a default message
      if (allSymbols.length === 0) {
        message = `Analyze an investment strategy: ${portfolio?.title || 'My portfolio strategy'}.`;
      } else {
        // Take the top symbols
        const topHoldings = allSymbols.slice(0, 10).join(', ');

        // Create a message that will be visible to the user but still provides context to the AI
        const portfolioName = portfolio?.title;
        message = portfolioName
          ? `My strategy: ${portfolioName}. Analyze my portfolio: ${topHoldings}.`
          : `Analyze my portfolio: ${topHoldings}.`;
      }
    }

    // Navigate to chat with the message and source info
    history.push('/chat', { message, from: 'portfolio' });
  };

  // Function to delete the portfolio (not used in current UI but kept for future use)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelete = async () => {
    if (!portfolio) return;

    const result = await storage.deletePortfolio(id);
    if (result.success) {
      const deletedPortfolio = result.deletedPortfolio;

      // Navigate back to portfolios list
      history.push('/portfolios');

      // Show toast with undo button
      toast.info(
        () => (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: ${fontFamilies.helveticaNowText};
              color: ${colors.liquorice};
              background-color: ${colors.sugar};
            `}
          >
            <span>Portfolio deleted</span>
            <button
              css={css`
                margin-left: ${spacing.deci};
                background: transparent;
                border: 1px solid ${colors.petrolBlue};
                color: ${colors.petrolBlue};
                border-radius: ${borderRadius};
                padding: ${spacing.milli} ${spacing.centi};
                cursor: pointer;
                font-family: ${fontFamilies.helveticaNowText};
                font-weight: 500;
                transition: background-color 0.2s ease;

                &:hover {
                  background-color: rgba(23, 110, 193, 0.1);
                }

                html[data-theme='dark'] & {
                  color: ${colors.skyBlue};
                  border-color: ${colors.skyBlue};
                }
              `}
              onClick={async () => {
                if (deletedPortfolio) {
                  const restored = await storage.restorePortfolio(deletedPortfolio);
                  if (restored) {
                    toast.success(() => (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          font-family: ${fontFamilies.helveticaNowText};
                          color: ${colors.liquorice};
                          background-color: ${colors.sugar};
                        `}
                      >
                        <span>Portfolio restored!</span>
                      </div>
                    ));
                    // Navigate back to the restored portfolio
                    history.push(`/portfolios/view/${deletedPortfolio.id}`);
                  }
                }
              }}
            >
              Undo
            </button>
          </div>
        ),
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        },
      );
    } else {
      toast.error(() => (
        <div
          css={css`
            display: flex;
            align-items: center;
            font-family: ${fontFamilies.helveticaNowText};
            color: ${colors.liquorice};
            background-color: ${colors.sugar};
          `}
        >
          Failed to delete portfolio. Please try again.
        </div>
      ));
    }
  };

  // Style for the page content
  const pageStyles = css`
    padding-bottom: calc(${spacing.regular} + ${spacing.giga});
    color: var(--text-color);
    max-width: 100%;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 359px) {
      padding-left: ${spacing.centi};
      padding-right: ${spacing.centi};
    }
  `;

  const strategyStyles = css`
    ${fontStyles.thinMouse};
    margin: ${spacing.centi} 0;
    line-height: 1.8;
    color: var(--text-color);
    white-space: pre-wrap;
  `;

  const sectionHeadingStyles = css`
    ${fontStyles.lion};
    margin-top: ${spacing.deci};
    color: var(--primary-text);
    html[data-theme='dark'] & {
      color: ${colors.fog};
    }
  `;

  const headerContainer = css`
    margin-bottom: ${spacing.regular};
    position: relative;
  `;

  const mainHeadingStyles = css`
    ${fontStyles.shark};
    margin: 0;
    margin-top: ${spacing.centi};
    margin-bottom: ${spacing.deci};
    color: var(--text-color);
  `;

  // Styles for action buttons row - commented out as not used in current UI
  /* 
  const actionsRowStyles = css`
    display: flex;
    gap: ${spacing.milli};
    margin-bottom: ${spacing.deka};
    margin-top: ${spacing.deci};

    @media (max-width: 359px) {
      gap: ${spacing.micro};
    }
  `;

  const actionButtonRowStyles = css`
    flex: 1;
    border-radius: 8px;
    padding: ${spacing.centi} ${spacing.centi};
    ${fontStyles.cat};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${spacing.milli};

    @media (max-width: 359px) {
      padding: ${spacing.milli} ${spacing.micro};
      font-size: 0.85rem;
      gap: ${spacing.micro};
    }
  `;
  */

  // Removed headerIconsContainerStyles since we moved the icons to the header

  const iconButtonStyles = css`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: ${spacing.milli};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 44px;
    min-height: 44px;

    img {
      width: 24px;
      height: 24px;
      filter: var(--icon-filter);
    }
  `;

  const chatButtonsRowStyles = css`
    display: flex;
    gap: ${spacing.centi};
    margin-top: ${spacing.deci};
    margin-bottom: ${spacing.deci};
  `;

  const chatButtonStyles = css`
    background-color: transparent;
    border: 1px solid ${colors.petrolBlue};
    color: ${colors.petrolBlue};
    border-radius: ${borderRadius};
    padding: ${spacing.milli} ${spacing.centi};
    cursor: pointer;
    font-family: ${fontFamilies.helveticaNowText};
    font-weight: 500;
    transition: background-color 0.2s ease;
    font-size: 0.9rem;

    &:hover {
      background-color: rgba(23, 110, 193, 0.1);
    }

    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
      border-color: ${colors.skyBlue};
    }
  `;

  const rationaleButtonStyles = css`
    background: transparent;
    border: none;
    padding: 0;
    color: ${colors.petrolBlue};
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: ${spacing.centi};
    margin-bottom: ${spacing.deci};
    text-decoration: underline;

    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
    }
  `;

  const headingsContainerStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${spacing.milli};
  `;

  const readMoreLinkStyles = css`
    color: ${colors.petrolBlue};
    cursor: pointer;
    margin-left: ${spacing.milli};
    font-weight: 500;
    text-decoration: underline;

    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
    }
  `;

  const collapseTextStyles = css`
    color: ${colors.petrolBlue};
    cursor: pointer;
    margin-left: ${spacing.milli};
    font-weight: 500;
    text-decoration: underline;

    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
    }
  `;

  const gridContainerStyles = css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: ${spacing.deci};
    margin-top: ${spacing.deci};

    @media (max-width: 359px) {
      grid-template-columns: 1fr;
    }
  `;

  const noSymbolsStyles = css`
    padding: ${spacing.centi};
    text-align: center;
    color: var(--secondary-text);
    ${fontStyles.dog};

    @media (max-width: 359px) {
      padding: ${spacing.milli};
    }
  `;

  const handleRationaleToggle = () => {
    setShowRationale((prev) => !prev);
  };

  // Prepare portfolio investments data by combining API data with allocation data
  const preparePortfolioInvestments = (): PortfolioInvestment[] => {
    // Get all symbols from both sources
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _allocationSymbols = (portfolio?.allocations || []).map((a) => a.symbol);
    const watchlistSymbols = portfolio?.symbols || [];

    // Create a map of all symbols with their allocation data (if available)
    const symbolsMap = new Map<string, PortfolioInvestment>();

    // Add symbols from allocations (with full data)
    (portfolio?.allocations || []).forEach((alloc) => {
      const investmentData = investmentsData[alloc.symbol];

      symbolsMap.set(alloc.symbol, {
        ...investmentData,
        symbol: alloc.symbol,
        name: alloc.name || investmentData?.name || alloc.symbol,
        allocation_percentage: alloc.allocation_percentage || 0,
        rationale: alloc.rationale,
      });
    });

    // Add symbols from watchlist that aren't already in the map
    watchlistSymbols.forEach((symbol) => {
      if (!symbolsMap.has(symbol)) {
        const investmentData = investmentsData[symbol];

        symbolsMap.set(symbol, {
          ...investmentData,
          symbol,
          name: investmentData?.name || symbol,
        });
      }
    });

    // Convert map to array and sort
    return Array.from(symbolsMap.values()).sort((a, b) => {
      // First sort by allocation data presence
      const aHasAllocation = a.allocation_percentage !== undefined;
      const bHasAllocation = b.allocation_percentage !== undefined;

      if (aHasAllocation && !bHasAllocation) return -1;
      if (!aHasAllocation && bHasAllocation) return 1;

      // Then sort by allocation percentage (if both have it)
      if (aHasAllocation && bHasAllocation) {
        return (b.allocation_percentage || 0) - (a.allocation_percentage || 0);
      }

      // Finally sort alphabetically by symbol
      return a.symbol.localeCompare(b.symbol);
    });
  };

  // Show loading state if portfolios or investments are still loading
  if (isPortfoliosLoading || isInvestmentsLoading) {
    return (
      <Page>
        <Header headingText="Portfolio" onBack={() => history.goBack()} />
        <div css={pageStyles}>
          <div
            css={css`
              ${fontStyles.dog};
              color: var(--secondary-text);
              text-align: center;
              margin-top: ${spacing.deka};
              padding: ${spacing.deka} ${spacing.deci};
            `}
          >
            Loading portfolio data...
          </div>
        </div>
        <NavigationBar />
      </Page>
    );
  }

  if (!portfolio) {
    return (
      <Page>
        <Header headingText="Portfolio Not Found" onBack={() => history.goBack()} />
        <div css={pageStyles}>
          <div
            css={css`
              ${fontStyles.dog};
              color: var(--secondary-text);
              text-align: center;
              margin-top: ${spacing.deka};
            `}
          >
            Portfolio not found
          </div>
        </div>
        <NavigationBar />
      </Page>
    );
  }

  // Get the portfolio investments
  const portfolioInvestments = preparePortfolioInvestments();

  return (
    <Page>
      <Header
        headingText=""
        onBack={() => history.push('/portfolios')}
        rightAction={
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <button
              css={iconButtonStyles}
              onClick={() => history.push(`/portfolios/edit/${portfolio.id}`)}
              aria-label="Edit"
            >
              <img src={editIcon} alt="Edit" />
            </button>
            <button css={iconButtonStyles} onClick={() => navigateToChat()} aria-label="Chat">
              <img src={chatIcon} alt="Chat" />
            </button>
          </div>
        }
      />
      <div css={pageStyles}>
        {/* Header with title */}
        <div css={headerContainer}>
          <h1 css={mainHeadingStyles}>{portfolio.title || 'Portfolio'}</h1>
        </div>

        {/* Chat buttons for Catalysts and Risks */}
        <div css={chatButtonsRowStyles}>
          <button css={chatButtonStyles} onClick={() => navigateToChat('Provide catalysts')}>
            Catalysts
          </button>
          <button css={chatButtonStyles} onClick={() => navigateToChat('Provide risks')}>
            Risks
          </button>
        </div>
        <div>
          {portfolio.strategy
            ? (() => {
                // Split text into sentences
                const sentences = portfolio.strategy.split(/(?<=\.|\?|\!) /g);

                // Group sentences based on word count
                const paragraphs = [];
                let currentParagraph = '';
                let wordCount = 0;

                sentences.forEach((sentence) => {
                  const sentenceWordCount = sentence.split(/\s+/).length;

                  // If this is a short sentence (less than 10 words) and the current paragraph
                  // isn't too long yet, add it to the current paragraph
                  if (sentenceWordCount + wordCount < 30) {
                    currentParagraph += sentence + ' ';
                    wordCount += sentenceWordCount;
                  }
                  // Otherwise, start a new paragraph
                  else {
                    if (currentParagraph) {
                      paragraphs.push(currentParagraph.trim());
                    }
                    currentParagraph = sentence + ' ';
                    wordCount = sentenceWordCount;
                  }
                });

                // Add the last paragraph if it exists
                if (currentParagraph) {
                  paragraphs.push(currentParagraph.trim());
                }

                // If there are no paragraphs (empty strategy), return empty string
                if (paragraphs.length === 0) {
                  return '';
                }

                // If strategy is expanded, show all paragraphs with a collapse option
                if (isStrategyExpanded) {
                  return (
                    <>
                      {paragraphs.map((para, index) => {
                        // If this is the last paragraph, include the collapse link on the same line
                        if (index === paragraphs.length - 1) {
                          return (
                            <p key={`para-${index}`} css={strategyStyles}>
                              {para}
                              <span css={collapseTextStyles} onClick={() => setIsStrategyExpanded(false)}>
                                collapse
                              </span>
                            </p>
                          );
                        }
                        // Otherwise, just render the paragraph
                        return (
                          <p key={`para-${index}`} css={strategyStyles}>
                            {para}
                          </p>
                        );
                      })}
                    </>
                  );
                }

                // Otherwise, show only first paragraph with "read more" link if there are more paragraphs
                return (
                  <p css={strategyStyles}>
                    {paragraphs[0]}
                    {paragraphs.length > 1 && (
                      <span css={readMoreLinkStyles} onClick={() => setIsStrategyExpanded(true)}>
                        read more
                      </span>
                    )}
                  </p>
                );
              })()
            : ''}
        </div>

        {/* Holdings section with column selector */}
        <div css={headingsContainerStyles}>
          <h2 css={sectionHeadingStyles}>
            Holdings {portfolioInvestments.length > 0 ? `(${portfolioInvestments.length})` : ''}
          </h2>
        </div>

        {/* Toggle rationale button */}
        <button css={rationaleButtonStyles} onClick={handleRationaleToggle}>
          {showRationale ? 'Hide rationale' : 'View rationale'}
        </button>

        {/* New portfolio column selector */}
        <PortfolioColumnSelector selectedMetric={selectedMetric} onMetricChange={handleMetricChange} />

        {portfolioInvestments.length === 0 ? (
          <div css={noSymbolsStyles}>No symbols in this portfolio yet.</div>
        ) : (
          <div css={gridContainerStyles}>
            {portfolioInvestments.map((investment, index) => (
              <InvestmentCard
                key={`investment-${index}`}
                investment={investment}
                selectedMetric={selectedMetric}
                showRationale={showRationale}
                onClick={() => history.push(`/symbol/${investment.symbol}`)}
              />
            ))}
          </div>
        )}
      </div>
      <NavigationBar />
    </Page>
  );
};

export default ViewPortfolio;
