import { css } from '@emotion/react';
import { FC, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useInvestmentsCacheState } from '../../utils/persistedState';
import Button from '../Button';
import { spacing, fontStyles } from '../../styles';
import { Portfolios, savePortfolio, updatePortfolio, getPortfolios, Portfolio, PortfolioMinimal } from '../../storage';
import { PortfolioSelectionModal } from '../PortfolioSelectionModal';
import bookmarkActiveIcon from './bookmarkFull.svg';
import bookmarkInactiveIcon from './bookmarkEmpty.svg';

interface Props {
  symbol: string;
  category: string;
  name: string;
  showLabel?: boolean;
}

const BookmarkButton: FC<Props> = ({ symbol, category, name, showLabel = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const portfolios = Portfolios.useState((s) => s.portfolios);
  const [investmentsCache, setInvestmentsCache] = useInvestmentsCacheState();

  // Check if symbol is in any portfolio
  const checkBookmarkStatus = useCallback(() => {
    // Add null check for symbols array
    const isInAnyPortfolio = portfolios.some(
      (p) => p.symbols && Array.isArray(p.symbols) && p.symbols.includes(symbol),
    );
    setIsBookmarked(isInAnyPortfolio);
  }, [portfolios, symbol]);

  useEffect(() => {
    checkBookmarkStatus();
  }, [checkBookmarkStatus]);

  const handleClick = () => {
    try {
      // If no portfolios exist, create a default one and add the symbol to it
      if (portfolios.length === 0) {
        createDefaultPortfolio();
        return;
      }

      // If only one portfolio exists, toggle the symbol directly
      if (portfolios.length === 1) {
        toggleInSinglePortfolio(portfolios[0]);
        return;
      }

      // If multiple portfolios, show the selection modal
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error handling bookmark click:', error);
      toast.error('Something went wrong. Please try again.');
    }
  };

  const createDefaultPortfolio = async () => {
    try {
      const defaultPortfolio: Omit<Portfolio, 'id'> = {
        title: 'My Watchlist',
        strategy: 'Default watchlist',
        symbols: [symbol],
        allocations: [],
      };

      const portfolioId = await savePortfolio(defaultPortfolio);
      console.log(`Created default portfolio with ID ${portfolioId} and added ${symbol}`);

      setInvestmentsCache({ ...investmentsCache, [symbol]: { symbol, category, name } });
      showToast(true, 'My Watchlist');
    } catch (error) {
      console.error('Error creating default portfolio:', error);
      toast.error('Failed to add to watchlist');
    }
  };

  const toggleInSinglePortfolio = async (portfolio: PortfolioMinimal) => {
    try {
      // Ensure the portfolio has a symbols array
      const currentSymbols = portfolio.symbols || [];

      // Check if symbol is in portfolio
      const isCurrentlyInPortfolio = currentSymbols.includes(symbol);

      // Get full portfolio data to preserve existing fields
      const allPortfolios = await getPortfolios();
      const fullPortfolio = allPortfolios.find((p) => p.id === portfolio.id) || {
        id: portfolio.id,
        title: portfolio.title,
        strategy: '',
        symbols: [],
        allocations: [],
      };

      // Create updated portfolio preserving existing data
      const updatedPortfolio = {
        ...fullPortfolio,
        symbols: isCurrentlyInPortfolio ? currentSymbols.filter((s) => s !== symbol) : [...currentSymbols, symbol],
      };

      // Update the existing portfolio
      const success = await updatePortfolio(updatedPortfolio);

      if (!success) {
        throw new Error(`Failed to update portfolio ${portfolio.id}`);
      }

      setInvestmentsCache({ ...investmentsCache, [symbol]: { symbol, category, name } });
      console.log(
        `Successfully ${isCurrentlyInPortfolio ? 'removed from' : 'added to'} portfolio ${portfolio.id}: ${portfolio.title}`,
      );

      // Show portfolio-specific toast
      showToast(!isCurrentlyInPortfolio, portfolio.title);
    } catch (error) {
      console.error('Error updating portfolio:', error);
      toast.error('Failed to update watchlist');
    }
  };

  const handleModalSubmit = async (selectedIds: string[]) => {
    console.log('handleModalSubmit called with symbol:', symbol, 'and selectedIds:', selectedIds);

    try {
      // Track whether any updates failed
      let anyFailed = false;
      // Track portfolios that had the symbol removed
      const removedFromPortfolios: string[] = [];

      // Process each portfolio sequentially to avoid race conditions
      for (const portfolio of portfolios) {
        // Make sure the portfolio object is valid
        if (!portfolio || !portfolio.id) {
          console.error('Invalid portfolio object:', portfolio);
          anyFailed = true;
          continue;
        }

        // Ensure the portfolio has a symbols array
        const currentSymbols = Array.isArray(portfolio.symbols) ? portfolio.symbols : [];
        console.log(`Portfolio ${portfolio.id} (${portfolio.title}) has symbols:`, currentSymbols);

        const isSelected = selectedIds.includes(portfolio.id);
        const isCurrentlyInPortfolio = currentSymbols.includes(symbol);

        // Determine if we should update this portfolio
        let shouldUpdatePortfolio = false;

        // Case 1: No portfolios selected - remove from all that have the symbol
        if (selectedIds.length === 0) {
          shouldUpdatePortfolio = isCurrentlyInPortfolio;
        }
        // Case 2: Portfolio is selected but doesn't contain the symbol - add it
        else if (isSelected && !isCurrentlyInPortfolio) {
          shouldUpdatePortfolio = true;
        }
        // Case 3: Portfolio is not selected but contains the symbol - remove it
        else if (!isSelected && isCurrentlyInPortfolio) {
          shouldUpdatePortfolio = true;
        }

        if (shouldUpdatePortfolio) {
          // Create updated portfolio with required fields
          let updatedSymbols;

          if (isSelected) {
            // If portfolio is selected, add the symbol (if not already there)
            updatedSymbols = currentSymbols.includes(symbol) ? currentSymbols : [...currentSymbols, symbol];
          } else {
            // If portfolio is not selected or we're removing from all, remove the symbol
            updatedSymbols = currentSymbols.filter((s) => s !== symbol);
          }

          const updatedPortfolio = {
            ...portfolio, // Preserve all existing portfolio data
            id: portfolio.id,
            title: portfolio.title,
            symbols: updatedSymbols,
          };

          // Keep track if we're removing the symbol
          if (isCurrentlyInPortfolio && !isSelected) {
            removedFromPortfolios.push(portfolio.title);
          }

          // Update the portfolio
          try {
            console.log(`Updating portfolio ${portfolio.id}: ${isSelected ? 'Adding' : 'Removing'} ${symbol}`);
            const success = await updatePortfolio(updatedPortfolio);
            if (!success) {
              anyFailed = true;
              console.error(`Failed to update portfolio ${portfolio.id}`);
            } else {
              console.log(`Successfully updated portfolio ${portfolio.id}`);
            }
          } catch (error) {
            anyFailed = true;
            console.error(`Error updating portfolio ${portfolio.id}:`, error);
          }
        } else {
          console.log(
            `No change needed for portfolio ${portfolio.id}: isSelected=${isSelected}, isCurrentlyInPortfolio=${isCurrentlyInPortfolio}`,
          );
        }
      }

      // Update investments cache
      setInvestmentsCache({ ...investmentsCache, [symbol]: { symbol, category, name } });
      console.log('Updated investmentsCache with symbol:', symbol);

      // Get updated portfolios state
      console.log('Updated portfolios:', Portfolios.getRawState().portfolios);

      // Show toast based on whether operation succeeded and we added to any portfolio
      if (anyFailed) {
        toast.error('Some watchlists could not be updated');
      } else if (selectedIds.length > 0) {
        // Show a single toast for multiple portfolios
        if (selectedIds.length === 1) {
          // If only one portfolio was selected, show a specific message
          const portfolio = portfolios.find((p) => p.id === selectedIds[0]);
          if (portfolio) {
            showToast(true, portfolio.title);
          }
        } else {
          // If multiple portfolios were selected, show a consolidated message
          toast(`${symbol} added to ${selectedIds.length} watchlists`, { toastId: `bookmark-multiple-${Date.now()}` });
        }
      } else if (removedFromPortfolios.length > 0) {
        // Show removed from all portfolios toast
        if (removedFromPortfolios.length === 1) {
          showToast(false, removedFromPortfolios[0]);
        } else {
          toast(`${symbol} was removed from all watchlists`);
        }
      }
    } catch (error) {
      console.error('Error updating portfolios:', error);
      toast.error('Failed to update watchlists');
    }
  };

  const showToast = (wasAdded: boolean, portfolioTitle?: string) => {
    let message;

    if (portfolioTitle) {
      message = wasAdded ? `${symbol} was added to ${portfolioTitle}` : `${symbol} was removed from ${portfolioTitle}`;
    } else {
      message = wasAdded ? 'Added to watchlist' : 'Removed from watchlist';
    }

    console.log('Showing toast:', message);

    // Use a unique toastId for each portfolio message to avoid conflicts
    const toastId = portfolioTitle ? `bookmark-${portfolioTitle}` : 'bookmark';

    if (toast.isActive(toastId)) {
      toast.update(toastId, { render: message });
    } else {
      toast(message, { toastId: toastId });
    }
  };

  const styles = {
    bookmarkButton: css`
      padding: ${spacing.centi};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0px;
      min-width: 44px;
      min-height: 44px;

      &:focus {
        outline: none;
      }

      @media (max-width: 359px) {
        padding: ${spacing.micro};
      }
    `,
    bookmarkIcon: css`
      height: 22px;
      width: 22px;
      vertical-align: middle;
      filter: var(--icon-filter);

      @media (max-width: 359px) {
        height: 20px;
        width: 20px;
      }

      html[data-theme='dark'] & {
        filter: ${`brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(81deg) brightness(108%) contrast(102%)`};
      }
    `,
    label: css`
      ${fontStyles.mouse}

      @media (max-width: 359px) {
        font-size: 0.7rem;
      }
    `,
  };

  return (
    <>
      <Button type="button" onClick={handleClick} css={styles.bookmarkButton}>
        <img src={isBookmarked ? bookmarkActiveIcon : bookmarkInactiveIcon} css={styles.bookmarkIcon} alt="Bookmark" />
        {showLabel && <span css={styles.label}>{isBookmarked ? 'Remove' : 'Add'}</span>}
      </Button>

      {/* Use a portal to render the modal at the root level to avoid z-index issues */}
      <PortfolioSelectionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        title="Add to Portfolio"
        symbol={symbol}
      />
    </>
  );
};

export default BookmarkButton;
