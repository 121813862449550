import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import * as storage from '../../storage';
import { backendUrl } from '../../constants';
import { spacing, fontStyles, colors } from '../../styles';
import Page from '../Page';
import Header from '../Header';
import NavigationBar from '../NavigationBar';

interface EventData {
  type?: string;
  data?: any;
  content?: any;
  agent?: string;
  message?: string;
}

// Using the Allocation interface from storage.ts

interface LocationState {
  strategy: string;
  portfolioId?: string; // Optional ID of an existing portfolio to update
}

const PortfolioLoadingScreen: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [events, setEvents] = useState<EventData[]>([]);
  const [currentStep, setCurrentStep] = useState('Starting...');
  const eventSourceRef = useRef<EventSource | null>(null);
  const eventCountRef = useRef(0);
  const portfolioReceivedRef = useRef(false);
  const MAX_EVENTS = 1000; // Maximum number of events before considering it an error

  useEffect(() => {
    const userQuery = location.state?.strategy;
    if (!userQuery) {
      history.push('/portfolios');
      return;
    }

    const eventSource = new EventSource(
      `${backendUrl}/stream_portfolio?query=${encodeURIComponent(userQuery)}&api_key=agentsagentsagents`,
    );
    eventSourceRef.current = eventSource;

    eventSource.onmessage = (event) => {
      try {
        // Increment event counter
        eventCountRef.current += 1;

        // Check if we're receiving too many events
        if (eventCountRef.current > MAX_EVENTS && !portfolioReceivedRef.current) {
          throw new Error('Too many events received without portfolio completion');
        }

        const data = JSON.parse(event.data);

        switch (data.type) {
          case 'connected':
            setEvents((prev) => [...prev, { type: 'info', message: 'Connected to portfolio generator' }]);
            setCurrentStep('Initializing');
            break;
          case 'agent_updated':
            setEvents((prev) => [...prev, { type: 'info', message: `Agent: ${data.agent}` }]);
            setCurrentStep(data.agent || 'Working...');
            break;
          case 'tool_call':
            if (data.content?.tool?.name) {
              const toolName = data.content.tool.name;
              const displayName = toolName.replace(/_/g, ' ');
              setEvents((prev) => [...prev, { type: 'tool', message: `Using tool: ${displayName}` }]);
              setCurrentStep(`Researching with ${displayName}`);
            }
            break;
          case 'tool_output':
            setEvents((prev) => [...prev, { type: 'data', message: 'Received research data' }]);
            setCurrentStep('Analyzing data');
            break;
          case 'portfolio_allocation':
            // Mark that we've received the portfolio
            portfolioReceivedRef.current = true;

            // Close the event source as we no longer need it
            if (eventSourceRef.current) {
              eventSourceRef.current.close();
              eventSourceRef.current = null;
            }

            const portfolioId = uuid();

            // Process data to ensure consistent field naming
            const processedData = data.data;

            // Ensure the title field is present and valid
            if (!processedData.title || typeof processedData.title !== 'string' || processedData.title.trim() === '') {
              processedData.title = 'Investment Portfolio';
              console.warn('Portfolio title was missing or invalid, using default title');
            }

            console.log('Received portfolio data:', processedData);

            if (processedData.allocations) {
              processedData.allocations = processedData.allocations.map(
                (alloc: { allocation?: number; allocation_percentage?: number; [key: string]: any }) => {
                  // If the API returns 'allocation' instead of 'allocation_percentage', standardize it
                  if (alloc.allocation !== undefined && alloc.allocation_percentage === undefined) {
                    return {
                      ...alloc,
                      allocation_percentage: alloc.allocation,
                    };
                  }
                  return alloc;
                },
              );
            }

            // Extract necessary data from processedData
            const { allocations, title: portfolioTitle, strategy: portfolioStrategy } = processedData;

            // Extract symbols from allocations
            const portfolioSymbols = allocations ? allocations.map((alloc: any) => alloc.symbol) : [];

            // Save or update portfolio based on whether we have an existing portfolioId
            const savePortfolio = async () => {
              try {
                const existingPortfolioId = location.state?.portfolioId;

                if (existingPortfolioId) {
                  // Update existing portfolio
                  const existingPortfolios = await storage.getPortfolios();
                  const portfolioToUpdate = existingPortfolios.find((p) => p.id === existingPortfolioId);

                  if (portfolioToUpdate) {
                    // Check if we received valid portfolio data with allocations
                    if (
                      !allocations ||
                      allocations.length === 0 ||
                      !portfolioSymbols ||
                      portfolioSymbols.length === 0
                    ) {
                      console.error('Invalid portfolio data received for update:', processedData);
                      setEvents((prev) => [...prev, { type: 'error', message: 'Received invalid portfolio data' }]);
                      setCurrentStep('Error occurred');
                      toast.error(
                        'Failed to update portfolio with valid data. Please try again with a different strategy.',
                      );

                      // Redirect back to the existing portfolio view after a short delay
                      setTimeout(() => {
                        history.push(`/portfolios/view/${existingPortfolioId}`);
                      }, 3000);
                      return;
                    }

                    // Create updated portfolio with the allocations and symbols
                    // Note: We explicitly keep the existing title unless a new one is provided
                    const updatedPortfolio = {
                      ...portfolioToUpdate,
                      title: portfolioTitle || portfolioToUpdate.title || 'Investment Portfolio',
                      strategy: portfolioStrategy || portfolioToUpdate.strategy || userQuery,
                      symbols: [...new Set([...portfolioToUpdate.symbols, ...portfolioSymbols])],
                      allocations: allocations || portfolioToUpdate.allocations || [],
                      total_allocation: processedData.total_allocation,
                    };

                    // Log the portfolio being updated
                    console.log('Updating portfolio to:', updatedPortfolio);

                    // Remove the existing portfolio and add the updated one
                    const updatedPortfolios = existingPortfolios
                      .filter((p) => p.id !== existingPortfolioId)
                      .concat(updatedPortfolio);

                    // Update both Capacitor storage and the Portfolios store
                    await storage.set('portfolios', updatedPortfolios);

                    // Explicitly update the Portfolios store to ensure consistency
                    storage.Portfolios.update((s) => {
                      s.portfolios = updatedPortfolios;
                    });

                    setEvents((prev) => [...prev, { type: 'success', message: 'Portfolio updated!' }]);
                    // Replace the current entry in history with the view page
                    setTimeout(() => history.replace(`/portfolios/view/${existingPortfolioId}`), 1500);
                  } else {
                    throw new Error('Portfolio to update not found');
                  }
                } else {
                  // Check if we received valid portfolio data with allocations
                  if (!allocations || allocations.length === 0 || !portfolioSymbols || portfolioSymbols.length === 0) {
                    console.error('Invalid portfolio data received:', processedData);
                    setEvents((prev) => [...prev, { type: 'error', message: 'Received invalid portfolio data' }]);
                    setCurrentStep('Error occurred');
                    toast.error('Failed to generate a valid portfolio. Please try again with a different strategy.');

                    // Redirect back to add portfolio page after a short delay
                    setTimeout(() => {
                      history.push('/portfolios/add');
                    }, 3000);
                    return;
                  }

                  // Create new portfolio
                  // Create the portfolio object
                  const portfolio = {
                    id: portfolioId,
                    title: portfolioTitle || 'Investment Portfolio',
                    strategy: portfolioStrategy || userQuery,
                    symbols: portfolioSymbols,
                    allocations: allocations || [],
                    total_allocation: processedData.total_allocation,
                  };

                  // Log the portfolio being saved
                  console.log('Saving new portfolio:', portfolio);

                  // Update both Capacitor storage and the Portfolios store
                  const currentPortfolios = await storage.getPortfolios();
                  await storage.set('portfolios', [...currentPortfolios, portfolio]);

                  // Explicitly update the Portfolios store to ensure consistency
                  storage.Portfolios.update((s) => {
                    s.portfolios = [...s.portfolios, portfolio];
                  });

                  setEvents((prev) => [...prev, { type: 'success', message: 'Portfolio generated!' }]);
                  // Replace the current entry in history with the view page
                  setTimeout(() => history.replace(`/portfolios/view/${portfolioId}`), 1500);
                }
              } catch (error) {
                console.error('Error saving portfolio:', error);
                toast.error('Failed to save portfolio');
              }
            };

            savePortfolio();
            break;
          case 'error':
          case 'fatal_error':
            setEvents((prev) => [...prev, { type: 'error', message: data.message || 'An error occurred' }]);
            setCurrentStep('Error occurred');
            toast.error(data.message || 'An error occurred while generating portfolio');

            // Close the connection on error
            if (eventSourceRef.current) {
              eventSourceRef.current.close();
              eventSourceRef.current = null;
            }
            break;
          default:
            if (data.message) {
              setEvents((prev) => [...prev, { type: 'info', message: data.message }]);
            }
        }
      } catch (error) {
        console.error('Error processing event:', error instanceof Error ? error.message : 'Unknown error');
        setEvents((prev) => [
          ...prev,
          {
            type: 'error',
            message: error instanceof Error ? error.message : 'Error processing event',
          },
        ]);

        setCurrentStep('Error occurred');
        toast.error('An error occurred while generating portfolio. Please try again.');

        // Close the connection
        if (eventSourceRef.current) {
          eventSourceRef.current.close();
          eventSourceRef.current = null;
        }
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
      setEvents((prev) => [...prev, { type: 'error', message: 'Connection lost or error occurred' }]);
      setCurrentStep('Error occurred');

      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }

      // Check if the error is due to a 400 response
      if (eventSource.readyState === EventSource.CLOSED) {
        toast.error(
          'Failed to generate portfolio. Please try a different strategy or contact support if the issue persists.',
        );
      } else {
        toast.error('Connection to server lost. Please try again.');
      }

      // Redirect back to add portfolio page after a short delay
      setTimeout(() => {
        history.push('/portfolios/add');
      }, 3000);
    };

    // Safety timeout - if no portfolio is received within 3 minutes, close the connection
    const safetyTimeout = setTimeout(
      () => {
        if (!portfolioReceivedRef.current && eventSourceRef.current) {
          eventSourceRef.current.close();
          eventSourceRef.current = null;
          setEvents((prev) => [...prev, { type: 'error', message: 'Portfolio generation timed out' }]);
          setCurrentStep('Timed out');
          toast.error('Portfolio generation timed out. Please try again.');
        }
      },
      3 * 60 * 1000,
    ); // 3 minutes

    return () => {
      clearTimeout(safetyTimeout);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [location, history]);

  const pageStyles = css`
    padding: ${spacing.regular};
    padding-bottom: calc(${spacing.regular} + ${spacing.giga});
  `;

  const spinnerSectionStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    min-height: 120px;
  `;

  const spinnerStyles = css`
    width: 50px;
    height: 50px;
    border: 3px solid var(--border-color);
    border-radius: 50%;
    border-top-color: ${colors.petrolBlue};
    animation: spin 1s linear infinite;
    margin-bottom: ${spacing.deci};

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `;

  const currentStepStyles = css`
    ${fontStyles.dog};
    color: ${colors.petrolBlue};
    margin-top: ${spacing.centi};
    text-align: center;
    min-height: 24px;
  `;

  const eventsContainerStyles = css`
    height: 300px;
    overflow-y: auto;
    padding: ${spacing.centi};
    border: 1px solid var(--border-color);
    border-radius: 8px;
  `;

  const eventStyles = (type: string) => css`
    ${fontStyles.cat};
    margin-bottom: ${spacing.centi};
    padding: ${spacing.centi};
    border-radius: 4px;
    color: ${type === 'error' ? colors.berry : 'var(--text-color)'};
    background-color: ${'var(--card-background)'};
  `;

  // Reference to events container for auto-scrolling
  const eventsContainerRef = useRef<HTMLDivElement>(null);

  // Auto-scroll to bottom when new events are added
  useEffect(() => {
    if (eventsContainerRef.current) {
      eventsContainerRef.current.scrollTop = eventsContainerRef.current.scrollHeight;
    }
  }, [events]);

  return (
    <Page>
      <Header
        headingText="Creating Portfolio"
        onBack={() => {
          if (eventSourceRef.current) {
            eventSourceRef.current.close();
          }
          history.push('/portfolios/add');
        }}
      />
      <div css={pageStyles}>
        <div css={spinnerSectionStyles}>
          <div css={spinnerStyles} />
          <div css={currentStepStyles}>{currentStep}</div>
        </div>

        <div css={eventsContainerStyles} ref={eventsContainerRef}>
          {events.map((event, index) => (
            <div key={index} css={eventStyles(event.type || 'info')}>
              {event.message}
            </div>
          ))}
        </div>
      </div>
      <NavigationBar />
    </Page>
  );
};

export default PortfolioLoadingScreen;
