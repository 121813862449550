import instagram from '../Icons/Instagram.svg';
import facebook from '../Icons/Facebook.svg';
import tiktok from '../Icons/Tiktok.svg';
import youtube from '../Icons/Youtube.svg';
import google from '../Icons/Google.svg';
import tv from '../Icons/Tv.svg';
import appStore from '../Icons/AppStore.svg';
import playStore from '../Icons/PlayStore.svg';
import x from '../Icons/X.svg';
import reddit from '../Icons/Reddit.svg';
import LinkedIn from '../Icons/LinkedIn.svg';
import sunrise from './sunrise.svg';
import graph from './graph.svg';
import drawdown from './drawdown.svg';
import notifications from './notifications.svg';
import flower from './flower.svg';
import flowerFull from './flower-full.svg';
import expertPortfolio from './expertPorfolios.svg';
import expertPortfolioDark from './expertPorfoliosDark.svg';
import drawdownDark from './drawdownDark.svg';
export const onboardingImages = {
  welcome: sunrise,
  slide1: graph,
  slide2: expertPortfolio,
  slide2b: expertPortfolioDark,
  slide3: drawdown,
  slide3b: drawdownDark,
  slide4: notifications,
  slide5: flower,
  slide6: flowerFull,
  instagram: instagram,
  facebook: facebook,
  tiktok: tiktok,
  youtube: youtube,
  google: google,
  tv: tv,
  appStore: appStore,
  playStore: playStore,
  x: x,
  reddit: reddit,
  linkedin: LinkedIn,
  flower: flower,
  flowerFull: flowerFull,
};
