import { Preferences } from '@capacitor/preferences';
import { v4 as uuidv4 } from 'uuid';
import * as storage from './storage';
import { General, SavedGeneral } from './stores';

const moveIsAppVisited = () => {
  if (window.localStorage.getItem('isAppVisited') !== null) return;

  const reduxStateString = window.localStorage.getItem('persist:root');
  if (reduxStateString === null) return;

  const reduxState = JSON.parse(reduxStateString);
  const persistedApp = JSON.parse(reduxState.persistedApp);

  if (persistedApp.isAppVisited) {
    window.localStorage.setItem('isAppVisited', 'true');
  }
};

const moveHasFeedbackBeenClosed = () => {
  if (window.localStorage.getItem('hasFeedbackBeenClosed') !== null) return;

  const reduxStateString = window.localStorage.getItem('persist:root');
  if (reduxStateString === null) return;

  const reduxState = JSON.parse(reduxStateString);
  const persistedApp = JSON.parse(reduxState.persistedApp);

  if (persistedApp.hasFeedbackBeenClosed) {
    window.localStorage.setItem('hasFeedbackBeenClosed', 'true');
  }
};

const moveWatchlist = () => {
  if (window.localStorage.getItem('watchlist') !== null) return;

  const reduxStateString = window.localStorage.getItem('persist:root');
  if (reduxStateString === null) return;

  const reduxState = JSON.parse(reduxStateString);
  const watchlistString = reduxState.watchlist;

  window.localStorage.setItem('watchlist', watchlistString);
};

const resetInvestmentsCache = () => {
  const investmentsCacheString = window.localStorage.getItem('investmentsCache') || 'null';
  if (Array.isArray(JSON.parse(investmentsCacheString))) {
    window.localStorage.removeItem('investmentsCache');
  }
};

const migratePortfoliosToIncludeSymbols = async () => {
  const portfolios = await storage.getPortfolios();

  // Check if any portfolios need updating
  const needsUpdate = portfolios.some((p) => !p.symbols);

  if (!needsUpdate) return; // No portfolios need updating

  // Update all portfolios to include symbols array
  const updatedPortfolios = portfolios.map((portfolio) => {
    if (!portfolio.symbols) {
      // Extract symbols from allocations if they exist
      const symbols = portfolio.allocations ? portfolio.allocations.map((alloc) => alloc.symbol) : [];

      return {
        ...portfolio,
        symbols,
      };
    }
    return portfolio;
  });

  await storage.set('portfolios', updatedPortfolios);
};

const migrateWatchlistToPortfolio = async () => {
  const savedGeneral = await storage.get<SavedGeneral>('GeneralStore');
  const watchlist = savedGeneral?.watchlist || {};
  const watchlistSymbols = Object.keys(watchlist).filter((symbol) => watchlist[symbol]);

  if (!watchlistSymbols.length) return; // No watchlist to migrate

  const portfolios = await storage.getPortfolios();
  if (portfolios.some((p) => p.title === 'My Watchlist')) return; // Already migrated

  const defaultPortfolio: storage.Portfolio = {
    id: uuidv4(),
    title: 'My Watchlist',
    strategy: 'Default watchlist',
    symbols: watchlistSymbols,
    allocations: [],
  };

  await storage.set('portfolios', [...portfolios, defaultPortfolio]);
  General.update((s) => {
    s.watchlist = {}; // Clear the old watchlist
  });
};

export const runAllMigrations = async () => {
  moveIsAppVisited();
  moveHasFeedbackBeenClosed();
  moveWatchlist();
  resetInvestmentsCache();
  await migrateWatchlistToPortfolio();
  await migratePortfoliosToIncludeSymbols();
  await Preferences.migrate();
};
