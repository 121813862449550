import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { spacing, fontStyles, colors } from '../../styles';
import * as storage from '../../storage';
import { getBasicInvestments } from '../../apis/backend';
import { investmentIndex } from '../../apis/search';
import Page from '../Page';
import Header from '../Header';
import NavigationBar from '../NavigationBar';
import Button from '../Button';
import AutoGrowTextarea from './AutoGrowTextarea';

interface RouteParams {
  id: string;
}

const EditPortfolio: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  const [portfolio, setPortfolio] = useState<storage.Portfolio | null>(null);
  const [portfolioTitle, setPortfolioTitle] = useState<string>('');
  const [localAllocations, setLocalAllocations] = useState<storage.Allocation[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>({});
  const [validationStatus, setValidationStatus] = useState<Record<number, { isValid: boolean; isValidating: boolean }>>(
    {},
  );
  const [allocationStatus, setAllocationStatus] = useState<Record<number, { isEmpty: boolean; isValid: boolean }>>({});

  // Load portfolio data
  useEffect(() => {
    const loadPortfolio = async () => {
      setIsLoading(true);
      try {
        const portfolios = await storage.getPortfolios();
        const foundPortfolio = portfolios.find((p) => p.id === id);
        if (foundPortfolio) {
          setPortfolio(foundPortfolio);
          setPortfolioTitle(foundPortfolio.title);

          // Check if portfolio has symbols but empty allocations (watchlist case)
          if (
            foundPortfolio.symbols?.length > 0 &&
            (!foundPortfolio.allocations || foundPortfolio.allocations.length === 0)
          ) {
            // Convert symbols array to allocations format
            const allocationsFromSymbols = foundPortfolio.symbols.map((symbol) => ({
              symbol,
              name: '',
              allocation_percentage: 0,
              rationale: '',
            }));
            setLocalAllocations(allocationsFromSymbols);
          } else {
            // Normal case: use existing allocations
            setLocalAllocations(foundPortfolio.allocations || []);
          }
        }
      } catch (error) {
        console.error('Error loading portfolio:', error);
        setError('Failed to load portfolio data');
      } finally {
        setIsLoading(false);
      }
    };

    loadPortfolio();
  }, [id]);

  // No longer needed as AutoGrowTextarea handles height adjustment internally

  const handleSymbolChange = (index: number, newSymbol: string) => {
    const updated = [...localAllocations];
    updated[index] = {
      ...updated[index],
      symbol: newSymbol.toUpperCase(),
    };
    setLocalAllocations(updated);

    // Only validate if symbol has at least 1 character
    if (newSymbol.trim().length > 0) {
      // Set validating state
      setValidationStatus((prev) => ({
        ...prev,
        [index]: { isValid: false, isValidating: true },
      }));

      // Debounce validation to avoid too many API calls
      const symbolToValidate = newSymbol.toUpperCase();
      const timeoutId = setTimeout(async () => {
        try {
          const isValid = await validateTicker(symbolToValidate);
          // Only update if this is still the current symbol
          if (symbolToValidate === updated[index].symbol) {
            setValidationStatus((prev) => ({
              ...prev,
              [index]: { isValid, isValidating: false },
            }));
          }
        } catch (error) {
          console.error('Error during validation:', error);
          setValidationStatus((prev) => ({
            ...prev,
            [index]: { isValid: false, isValidating: false },
          }));
        }
      }, 600); // Delay validation by 600ms

      return () => clearTimeout(timeoutId);
    } else {
      // Clear validation for empty symbols
      setValidationStatus((prev) => {
        const newStatus = { ...prev };
        delete newStatus[index];
        return newStatus;
      });
    }
  };

  const handleRationaleChange = (index: number, newRationale: string) => {
    const updated = [...localAllocations];
    updated[index] = {
      ...updated[index],
      rationale: newRationale,
    };
    setLocalAllocations(updated);
  };

  const handleAllocationChange = (index: number, newAllocation: string) => {
    // Allow empty string
    if (newAllocation === '') {
      const updated = [...localAllocations];
      updated[index] = {
        ...updated[index],
        allocation_percentage: 0,
      };
      setLocalAllocations(updated);

      // For watchlists, a zero allocation is valid
      setAllocationStatus((prev) => ({
        ...prev,
        [index]: { isEmpty: true, isValid: true },
      }));
      return;
    }

    const value = parseFloat(newAllocation);
    if (isNaN(value)) return;

    // Validate the range but still allow any input
    const isValid = value >= 0 && value <= 100;

    const updated = [...localAllocations];
    updated[index] = {
      ...updated[index],
      allocation_percentage: value,
    };
    setLocalAllocations(updated);

    // Update validation status
    setAllocationStatus((prev) => ({
      ...prev,
      [index]: { isEmpty: false, isValid },
    }));
  };

  const handleRemove = (index: number) => {
    const updated = localAllocations.filter((_, i) => i !== index);
    setLocalAllocations(updated);
  };

  const toggleExpanded = (index: number) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleAddTicker = () => {
    setLocalAllocations([
      ...localAllocations,
      {
        symbol: '',
        name: '',
        allocation_percentage: 0,
        rationale: '',
      },
    ]);
  };

  // Validate a single ticker using the search API
  const validateTicker = async (ticker: string): Promise<boolean> => {
    if (!ticker.trim()) return false;

    try {
      // Search for the exact ticker symbol
      const searchResults = await investmentIndex.search(ticker, {
        hitsPerPage: 10,
      });

      // Check if we have an exact match for the symbol
      return searchResults.hits.some((hit: any) => hit.symbol.toUpperCase() === ticker.toUpperCase());
    } catch (error) {
      console.error('Error validating ticker:', error);
      return false;
    }
  };

  const handleSave = async () => {
    if (!portfolio) return;

    setError('');
    setIsSaving(true);

    // Validate portfolio title
    if (!portfolioTitle.trim()) {
      setError('Please enter a portfolio title');
      setIsSaving(false);
      return;
    }

    try {
      // Extract all symbols
      const symbols = localAllocations.map((allocation) => allocation.symbol.trim()).filter((sym) => sym !== '');
      const uniqueSymbols = [...new Set(symbols)];

      // Allow empty portfolios to be saved (as a watchlist with no symbols)
      // User can add symbols later

      // Check for empty or invalid allocations, but only if they've been edited
      // This allows watchlists with 0 allocations to be saved
      const invalidIndices = Object.entries(allocationStatus)
        .filter(([, status]) => status.isEmpty || !status.isValid)
        .map(([index]) => parseInt(index));

      if (invalidIndices.length > 0) {
        setError('Please enter valid allocation percentages (0-100%) for all tickers');
        setIsSaving(false);
        return;
      }

      // Check for duplicate symbols
      if (uniqueSymbols.length !== symbols.length) {
        setError('You have duplicate symbols in your portfolio');
        setIsSaving(false);
        return;
      }

      // Validate all symbols with the search API first
      const validationResults = await Promise.all(
        uniqueSymbols.map(async (symbol) => ({
          symbol,
          isValid: await validateTicker(symbol),
        })),
      );

      const invalidSymbols = validationResults.filter((result) => !result.isValid).map((result) => result.symbol);

      if (invalidSymbols.length > 0) {
        setError(`Invalid symbols: ${invalidSymbols.join(', ')}`);
        setIsSaving(false);
        return;
      }

      // Validate all symbols with the API (this will fetch additional data)
      const investments = await getBasicInvestments(uniqueSymbols);

      // Update symbol names from API response
      const updatedAllocations = localAllocations
        .filter((allocation) => allocation.symbol.trim() !== '')
        .map((allocation) => {
          const investment = investments.find((inv) => inv.symbol.toUpperCase() === allocation.symbol.toUpperCase());
          return {
            ...allocation,
            symbol: allocation.symbol.toUpperCase(),
            name: investment?.name || allocation.name || '',
          };
        });

      // Update portfolio with new title, allocations and symbols
      const updatedPortfolio: storage.Portfolio = {
        ...portfolio,
        title: portfolioTitle.trim(), // Use the updated title
        allocations: updatedAllocations,
        symbols: updatedAllocations.map((allocation) => allocation.symbol),
      };

      // Save portfolio
      const success = await storage.updatePortfolio(updatedPortfolio);

      if (success) {
        // Redirect back to view page
        history.push(`/portfolios/view/${id}`);
      } else {
        setError('Failed to save portfolio');
      }
    } catch (err) {
      console.error('Error saving portfolio:', err);
      setError('Error saving portfolio');
    } finally {
      setIsSaving(false);
    }
  };

  const pageStyles = css`
    padding-bottom: calc(${spacing.regular} + ${spacing.giga});
    background-color: ${colors.fog};
    html[data-theme='dark'] & {
      background-color: ${colors.liquorice};
    }
  `;

  // Removed unused headingStyles

  const errorStyles = css`
    ${fontStyles.dog};
    color: ${colors.berry};
    margin: ${spacing.deci} 0;
    padding: ${spacing.centi};
    background-color: ${colors.berryAir};
    border-radius: 4px;
  `;

  const loadingStyles = css`
    ${fontStyles.dog};
    text-align: center;
    margin: ${spacing.deka} 0;
    color: var(--secondary-text);
  `;

  const allocationItemStyles = css`
    background-color: ${colors.snow};
    border-radius: 8px;
    padding: ${spacing.deci};
    margin-bottom: ${spacing.deci};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    width: 100%; /* Take full width of container */

    html[data-theme='dark'] & {
      background-color: ${colors.liquorice};
      border: 1px solid ${colors.tungsten}40;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  `;

  const inputGroupStyles = css`
    margin-bottom: ${spacing.centi};
  `;

  const rowStyles = css`
    display: flex;
    flex-direction: row; /* Always horizontal layout */
    gap: ${spacing.deci}; /* Consistent spacing */
    margin-bottom: ${spacing.centi};
    width: 100%;
    align-items: flex-start;
  `;

  const tickerColumnStyles = css`
    flex: 0 0 120px; /* Fixed width, don't grow, don't shrink */
    max-width: 120px; /* Maximum width */

    @media (max-width: 359px) {
      flex: 0 0 100px; /* Even smaller on tiny screens */
      max-width: 100px;
    }
  `;

  const allocationColumnStyles = css`
    flex: 0 0 120px; /* Same fixed width as ticker */
    max-width: 120px; /* Same maximum width */

    @media (max-width: 359px) {
      flex: 0 0 100px; /* Even smaller on tiny screens */
      max-width: 100px;
    }
  `;

  const expandButtonStyles = css`
    background: ${colors.skyAir};
    color: ${colors.petrolBlue};
    cursor: pointer;
    padding: ${spacing.milli} ${spacing.centi};
    border-radius: 4px;
    ${fontStyles.cat};
    border: none;
    white-space: nowrap;
    width: 140px;

    @media (max-width: 359px) {
      padding: ${spacing.micro} ${spacing.milli};
      font-size: 12px;
      width: 100px;
    }

    &:hover {
      background: ${colors.petrolBlue}11;
    }

    html[data-theme='dark'] & {
      background: ${colors.skyBlue}22;
      color: ${colors.skyBlue};
      &:hover {
        background: ${colors.skyBlue}33;
      }
    }
  `;

  const labelStyles = css`
    ${fontStyles.cat};
    color: var(--secondary-text);
    display: block;
    margin-bottom: ${spacing.micro};
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `;

  const inputStyles = css`
    width: 100%;
    padding: ${spacing.centi};
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: ${colors.snow};
    color: var(--text-color);
    ${fontStyles.dog};
    font-size: 16px; /* Prevent iOS zoom on focus */

    @media (min-width: 480px) {
      font-size: 0.95rem; /* Revert to design size on larger screens */
    }

    &:focus {
      outline: none;
      border-color: ${colors.petrolBlue};
    }

    html[data-theme='dark'] & {
      background-color: ${colors.tungsten}30 !important;
      border-color: ${colors.tungsten}90;
      color: ${colors.sugar};

      &::placeholder {
        color: ${colors.silver}90;
      }
    }
  `;

  const validInputStyles = css`
    border-color: ${colors.leaf};

    &:focus {
      border-color: ${colors.leaf};
    }

    html[data-theme='dark'] & {
      border-color: ${colors.leaf}CC;
    }
  `;

  const invalidInputStyles = css`
    border-color: ${colors.berry};

    &:focus {
      border-color: ${colors.berry};
    }

    html[data-theme='dark'] & {
      border-color: ${colors.berry}CC;
    }
  `;

  const emptyInputStyles = css`
    border-color: ${colors.orange}90;

    &:focus {
      border-color: ${colors.orange};
    }

    html[data-theme='dark'] & {
      border-color: ${colors.orange}CC;
    }
  `;

  const validationIconStyles = css`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  `;

  const buttonContainerStyles = css`
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    gap: ${spacing.deci}; /* Slightly larger gap between buttons */
    margin-top: ${spacing.centi};
    padding: ${spacing.milli} 0;
    flex-wrap: nowrap; /* Prevent buttons from wrapping on narrow screens */

    @media (max-width: 359px) {
      gap: ${spacing.milli}; /* Smaller gap on tiny screens */
    }
  `;

  const removeButtonStyles = css`
    color: ${colors.berry};
    background: ${colors.berryAir};
    border: none;
    border-radius: 4px;
    padding: ${spacing.micro} ${spacing.centi};
    cursor: pointer;
    ${fontStyles.cat};
    white-space: nowrap;

    @media (max-width: 359px) {
      padding: ${spacing.micro} ${spacing.milli};
      font-size: 12px;
    }

    &:hover {
      background: ${colors.berry}22;
    }

    html[data-theme='dark'] & {
      background: ${colors.berry}22;
      color: ${colors.berry}EE;

      &:hover {
        background: ${colors.berry}33;
      }
    }
  `;

  const actionButtonsStyles = css`
    display: flex;
    flex-direction: row; /* Always horizontal layout */
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: ${spacing.deci};
    margin-top: ${spacing.deka};
    position: sticky;
    bottom: 0;
    background-color: ${colors.fog};
    padding: ${spacing.deci} 0;
    border-top: 1px solid ${colors.cloud};
    z-index: 10;

    html[data-theme='dark'] & {
      background-color: ${colors.liquorice};
      border-top: 1px solid ${colors.tungsten}40;
    }
  `;

  const rationaleContainerStyles = css`
    border-top: 1px solid var(--border-color);
    padding-top: ${spacing.centi};
    margin-top: ${spacing.centi};

    html[data-theme='dark'] & {
      border-top: 1px solid ${colors.tungsten}40;
    }
  `;

  const addTickerButtonStyles = css`
    width: 100%;
    background-color: ${colors.snow};
    color: ${colors.petrolBlue};
    border: 1px dashed ${colors.petrolBlue};
    border-radius: 8px;
    padding: ${spacing.centi} ${spacing.deci};
    margin-bottom: ${spacing.deci};
    cursor: pointer;
    ${fontStyles.dog};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;

    &:hover {
      background-color: ${colors.petrolBlue}06;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    html[data-theme='dark'] & {
      background-color: ${colors.tungsten}30;
      color: ${colors.skyBlue};
      border-color: ${colors.skyBlue}80;

      &:hover {
        background-color: ${colors.skyBlue}22;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
    }
  `;

  if (isLoading) {
    return (
      <Page
        css={css`
          background-color: ${colors.fog};

          html[data-theme='dark'] & {
            background-color: ${colors.liquorice};
          }
        `}
      >
        <Header
          headingText="Edit Portfolio"
          onBack={() => history.goBack()}
          css={css`
            background-color: ${colors.fog} !important;

            html[data-theme='dark'] & {
              background-color: ${colors.liquorice} !important;
            }
          `}
        />
        <div css={pageStyles}>
          <div css={loadingStyles}>Loading portfolio...</div>
        </div>
        <NavigationBar />
      </Page>
    );
  }

  if (!portfolio) {
    return (
      <Page
        css={css`
          background-color: ${colors.fog};

          html[data-theme='dark'] & {
            background-color: ${colors.liquorice};
          }
        `}
      >
        <Header
          headingText="Portfolio Not Found"
          onBack={() => history.goBack()}
          css={css`
            background-color: ${colors.fog} !important;

            html[data-theme='dark'] & {
              background-color: ${colors.liquorice} !important;
            }
          `}
        />
        <div css={pageStyles}>
          <div css={errorStyles}>The requested portfolio could not be found.</div>
        </div>
        <NavigationBar />
      </Page>
    );
  }

  // Function to handle portfolio deletion
  const handleDeletePortfolio = async () => {
    if (!portfolio) return;

    if (window.confirm('Are you sure you want to delete this portfolio? This action cannot be undone.')) {
      const result = await storage.deletePortfolio(id);
      if (result.success) {
        // Redirect back to portfolios list
        history.push('/portfolios');
      } else {
        setError('Failed to delete portfolio');
      }
    }
  };

  // Styles for the delete button
  const deleteButtonStyles = css`
    background-color: ${colors.berryAir};
    color: ${colors.berry};
    border: 1px solid ${colors.berry};
    border-radius: 8px;
    padding: ${spacing.centi} ${spacing.deci};
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    margin-top: ${spacing.deci};
    transition: all 0.2s ease;
    width: 100%;

    &:hover {
      background-color: ${colors.berry}22;
    }

    html[data-theme='dark'] & {
      background-color: ${colors.berry}22;
      color: ${colors.berry}EE;
      border-color: ${colors.berry}80;

      &:hover {
        background-color: ${colors.berry}33;
      }
    }
  `;

  return (
    <Page
      css={css`
        background-color: ${colors.fog};

        html[data-theme='dark'] & {
          background-color: ${colors.liquorice};
        }
      `}
    >
      <Header
        headingText="Edit Portfolio"
        onBack={() => history.goBack()}
        css={css`
          background-color: ${colors.fog} !important;

          html[data-theme='dark'] & {
            background-color: ${colors.liquorice} !important;
          }
        `}
      />
      <div css={pageStyles}>
        <div css={inputGroupStyles}>
          <label css={labelStyles} htmlFor="portfolio-title">
            Portfolio Title (*)
          </label>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              margin-bottom: ${spacing.deci};
            `}
          >
            <input
              id="portfolio-title"
              css={[
                inputStyles,
                css`
                  width: 100%; /* Full width on small screens */
                `,
              ]}
              type="text"
              value={portfolioTitle}
              onChange={(e) => setPortfolioTitle(e.target.value)}
              placeholder="Enter portfolio name"
              required
            />
          </div>
        </div>

        {error && <div css={errorStyles}>{error}</div>}

        <div>
          {localAllocations.map((allocation, index) => (
            <div key={index} css={allocationItemStyles}>
              {/* Row with ticker and allocation */}
              <div
                css={css`
                  ${rowStyles};
                  position: relative;
                `}
              >
                <div css={tickerColumnStyles}>
                  <label css={labelStyles} htmlFor={`symbol-${index}`}>
                    Symbol (*)
                  </label>
                  <div
                    css={css`
                      position: relative;
                    `}
                  >
                    <input
                      id={`symbol-${index}`}
                      css={[
                        inputStyles,
                        validationStatus[index]?.isValid && validInputStyles,
                        validationStatus[index]?.isValid === false &&
                          !validationStatus[index]?.isValidating &&
                          invalidInputStyles,
                        css`
                          text-transform: uppercase;
                        `,
                      ]}
                      type="text"
                      value={allocation.symbol}
                      onChange={(e) => handleSymbolChange(index, e.target.value)}
                      placeholder="AAPL"
                      maxLength={6}
                    />
                    {validationStatus[index]?.isValidating && (
                      <span css={validationIconStyles}>
                        <span
                          css={css`
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            border: 2px solid ${colors.petrolBlue};
                            border-top-color: transparent;
                            border-radius: 50%;
                            animation: spin 0.8s linear infinite;
                            @keyframes spin {
                              0% {
                                transform: rotate(0deg);
                              }
                              100% {
                                transform: rotate(360deg);
                              }
                            }
                          `}
                        ></span>
                      </span>
                    )}
                    {validationStatus[index]?.isValid && !validationStatus[index]?.isValidating && (
                      <span css={validationIconStyles}>
                        <span
                          css={css`
                            color: ${colors.leaf};
                            font-size: 18px;
                          `}
                        >
                          ✓
                        </span>
                      </span>
                    )}
                    {validationStatus[index]?.isValid === false &&
                      !validationStatus[index]?.isValidating &&
                      allocation.symbol.trim() !== '' && (
                        <span css={validationIconStyles} title="Invalid ticker symbol">
                          <span
                            css={css`
                              color: ${colors.berry};
                              font-size: 18px;
                            `}
                          >
                            ✗
                          </span>
                        </span>
                      )}
                  </div>
                </div>

                <div css={allocationColumnStyles}>
                  <label css={labelStyles} htmlFor={`allocation-${index}`}>
                    Allocation %
                  </label>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      width: 100%;
                    `}
                  >
                    <div
                      css={css`
                        position: relative;
                        width: 100%;
                      `}
                    >
                      <input
                        id={`allocation-${index}`}
                        css={[
                          inputStyles,
                          allocationStatus[index]?.isEmpty && emptyInputStyles,
                          allocationStatus[index]?.isValid && validInputStyles,
                          allocationStatus[index]?.isValid === false &&
                            !allocationStatus[index]?.isEmpty &&
                            invalidInputStyles,
                        ]}
                        type="text" /* Changed from number to allow empty values */
                        value={allocationStatus[index]?.isEmpty ? '' : allocation.allocation_percentage.toString()}
                        onChange={(e) => handleAllocationChange(index, e.target.value)}
                        placeholder="Allocation % (0-100)"
                      />
                      {allocationStatus[index]?.isEmpty && (
                        <span css={validationIconStyles} title="Please enter a percentage">
                          <span
                            css={css`
                              color: ${colors.orange};
                              font-size: 18px;
                            `}
                          >
                            !
                          </span>
                        </span>
                      )}
                      {allocationStatus[index]?.isValid === false && !allocationStatus[index]?.isEmpty && (
                        <span css={validationIconStyles} title="Must be between 0 and 100">
                          <span
                            css={css`
                              color: ${colors.berry};
                              font-size: 18px;
                            `}
                          >
                            !
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div css={buttonContainerStyles}>
                <button css={expandButtonStyles} onClick={() => toggleExpanded(index)} type="button">
                  {expandedItems[index] ? 'Hide Rationale' : 'Show Rationale'}
                </button>

                <button css={removeButtonStyles} onClick={() => handleRemove(index)} type="button">
                  Remove
                </button>
              </div>

              {/* Collapsible rationale section */}
              {expandedItems[index] && (
                <div css={rationaleContainerStyles}>
                  <div css={inputGroupStyles}>
                    <label css={labelStyles} htmlFor={`rationale-${index}`}>
                      Rationale
                    </label>
                    <div
                      css={css`
                        width: 100%;
                      `}
                    >
                      <AutoGrowTextarea
                        id={`rationale-${index}`}
                        value={allocation.rationale || ''}
                        onChange={(value) => handleRationaleChange(index, value)}
                        placeholder="Why you're investing in this ticker"
                        minHeight={80}
                        className={inputStyles}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

          {/* Always show the Add Ticker button */}
          <button css={addTickerButtonStyles} onClick={handleAddTicker} type="button">
            <span
              css={css`
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                margin-right: ${spacing.centi};
                border-radius: 50%;
                background-color: ${colors.petrolBlue}22;
                color: ${colors.petrolBlue};
                font-weight: bold;
                line-height: 1;

                html[data-theme='dark'] & {
                  background-color: ${colors.skyBlue}22;
                  color: ${colors.skyBlue};
                }
              `}
            >
              +
            </span>
            Add Ticker
          </button>
        </div>

        {/* Delete portfolio button */}
        <button css={deleteButtonStyles} onClick={handleDeletePortfolio} type="button">
          Delete Portfolio
        </button>

        <div css={actionButtonsStyles}>
          <Button
            onClick={handleSave}
            disabled={isSaving}
            css={css`
              flex: 2;
              background-color: ${colors.petrolBlue};
              color: ${colors.snow};
              font-weight: 500;
              padding: ${spacing.centi} ${spacing.deci};
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              transition: all 0.2s ease;

              &:hover:not(:disabled) {
                background-color: ${colors.petrolBlue}ee;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
                transform: translateY(-1px);
              }

              &:active:not(:disabled) {
                transform: translateY(0);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
              }

              &:disabled {
                opacity: 0.7;
              }

              html[data-theme='dark'] & {
                background-color: ${colors.skyBlue};
                color: ${colors.sugar};
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

                &:hover:not(:disabled) {
                  background-color: ${colors.skyBlue}ee;
                  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
                }
              }
            `}
          >
            {isSaving ? 'Saving...' : 'Save Changes'}
          </Button>

          <Button
            variant="secondary"
            onClick={() => history.push(`/portfolios/view/${id}`)}
            disabled={isSaving}
            css={css`
              flex: 1;
              background-color: transparent;
              color: ${colors.silver};
              border: 1px solid ${colors.cloud};
              padding: ${spacing.centi} ${spacing.deci};
              border-radius: 8px;
              transition: all 0.2s ease;

              &:hover:not(:disabled) {
                background-color: ${colors.cloud}22;
                color: ${colors.tungsten};
              }

              &:disabled {
                opacity: 0.7;
              }

              html[data-theme='dark'] & {
                color: ${colors.cloud};
                border: 1px solid ${colors.tungsten}80;

                &:hover:not(:disabled) {
                  background-color: ${colors.tungsten}33;
                  color: ${colors.sugar};
                }
              }
            `}
          >
            Cancel
          </Button>
        </div>
      </div>
      <NavigationBar />
    </Page>
  );
};

export default EditPortfolio;
