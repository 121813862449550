import * as Sentry from '@sentry/browser';
import { ChakraProvider } from '@chakra-ui/react';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { Global, ClassNames, css } from '@emotion/react';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'animate.css/animate.css';
import 'react-toastify/dist/ReactToastify.css';
import { posthog } from 'posthog-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FC, useEffect } from 'react';
import { render } from 'react-dom';
import { Redirect, BrowserRouter as Router, Route, useLocation, Switch } from 'react-router-dom';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import initializePosthog from './utils/initializePosthog';
import initializeOfflineToast from './utils/initializeOfflineToast';
import * as backHandling from './utils/backHandling';
import { buildPageSpacing, spacing, getGlobalStyles, fontStyles, colors, theme } from './styles';

import { runAllMigrations } from './migrations';
import * as stores from './stores';
import WatchlistPage from './components/WatchlistPage';
import SearchPage from './components/SearchPage';
import NewsPage from './components/NewsPage';
import CollectionPage from './components/CollectionPage';
import SymbolPage from './components/SymbolPage';
import MorePage from './components/MorePage';
import SubscriptionTakeover from './components/SubscriptionTakeover';
import PlaygroundPage from './components/PlaygroundPage';
import { initializeTheme, setupThemeListener } from './utils/themeManager';
import WatchlistSearchPage from './components/WatchlistSearchPage';
import ChatPage from './components/ChatPage';
import NotificationPreferences from './components/NotificationPreferences';
import { PortfoliosPage, AddPortfolio, ViewPortfolio, LoadingScreen, EditPortfolio } from './components/Portfolios';
// Onboarding
import { Welcome } from './components/OnboardingView/Page/Welcome';
import { OnboardingView } from './components/OnboardingView/Page/OnboardingView';
import { Payment } from './components/OnboardingView/Page/Payment';
import { PurchaseResult } from './components/OnboardingView/Page/Result';
import * as storage from './storage';
import { PushNotificationService } from './services/pushNotificationService';

const queryClient = new QueryClient();

// Only configure Purchases if we are on a native platform
if (Capacitor.isNativePlatform()) {
  Purchases.configure({
    apiKey: Capacitor.getPlatform() === 'ios' ? 'appl_WzwoHcGpOkytDhvIXdkxELufsEY' : 'goog_KvaRdNEIDCrurgzrpZLEgfiKAJp',
  });
}

export const updateNativeUI = async (isDark: boolean) => {
  if (Capacitor.isNativePlatform()) {
    try {
      await StatusBar.setStyle({
        style: isDark ? Style.Dark : Style.Light,
      });

      if (Capacitor.getPlatform() === 'android') {
        await StatusBar.setBackgroundColor({
          color: isDark ? colors.modes.dark.statusBar : colors.modes.light.statusBar,
        });
      }
    } catch (error) {
      console.warn('Error updating native UI:', error);
    }
  }
};

Sentry.init({
  dsn: import.meta.env.PROD ? 'https://ec313205c4cd4f67957ea5afd7ed157a@o190156.ingest.sentry.io/1468344' : undefined,
});

backHandling.init();
backHandling.addBackListener(({ canGoBack }) => {
  if (canGoBack) {
    window.history.back();
  } else {
    App.exitApp();
  }
});

const RouterApp: FC = () => {
  const location = useLocation();
  // Check if onboarding has been completed (null means first time opening the app)
  const hasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding') === 'true';

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  useEffect(() => {
    initializeTheme();

    const cleanup = setupThemeListener();
    return cleanup;
  }, []);

  // Always redirect to onboarding on first launch when hasCompletedOnboarding is false
  const redirectPath = !hasCompletedOnboarding ? '/welcome' : '/portfolios';

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={redirectPath} />} />
      <Route exact path="/welcome" component={Welcome} />
      <Route exact path="/onboarding" component={OnboardingView} />
      <Route exact path="/payment" component={Payment} />
      <Route exact path="/result" component={PurchaseResult} />
      <Route exact path="/portfolio" component={WatchlistPage} />
      <Route exact path="/search" component={SearchPage} />
      <Route exact path="/portfolio-search" component={SearchPage} />
      <Route path="/more" component={MorePage} />
      <Route path="/symbol/:symbol" component={SymbolPage} />
      <Route path="/collection/:collectionId" component={CollectionPage} />
      <Route path="/playground" component={PlaygroundPage} />
      <Route exact path="/watchlist-search" component={WatchlistSearchPage} />
      <Route path="/news" component={NewsPage} />
      <Route path="/chat" component={ChatPage} />
      <Route path="/notification-preferences" component={NotificationPreferences} />
      <Route
        exact
        path="/portfolios"
        render={() => {
          return hasCompletedOnboarding ? <PortfoliosPage /> : <Redirect to="/onboarding" />;
        }}
      />
      <Route exact path="/portfolios/add" component={AddPortfolio} />
      <Route exact path="/portfolios/loading" component={LoadingScreen} />
      <Route exact path="/portfolios/view/:id" component={ViewPortfolio} />
      <Route exact path="/portfolios/edit/:id" component={EditPortfolio} />
    </Switch>
  );
};
const AppWrapper: FC = () => {
  const { isAppReady, isSearchOnboardingPopoverShown, isBookmarkOnboardingPopoverShown } = stores.General.useState();
  useEffect(() => {
    (async () => {
      try {
        await initializePosthog();
      } catch (e) {
        console.warn('Error initializing Posthog:', e);
      }

      initializeOfflineToast();

      await runAllMigrations();
      await Promise.all([
        stores.initializeGeneral(),
        stores.initializeTableOptions(),
        stores.initializePurchase(),
        storage.initializePortfolios(),
      ]);

      // Add notification initialization
      try {
        const portfolios = await storage.getPortfolios();
        const allSymbols = Array.from(new Set(portfolios.flatMap((p) => p.symbols || [])));
        const savedPreferences = localStorage.getItem('notificationPreferences');
        if (savedPreferences) {
          const preferences = JSON.parse(savedPreferences);
          const enabledTypes = preferences.filter((pref) => pref.enabled).map((pref) => pref.id);
          if (enabledTypes.length > 0) {
            // Set a timeout to prevent blocking indefinitely
            const timeoutPromise = new Promise((_, reject) =>
              setTimeout(() => reject(new Error('PushNotificationService initialization timeout')), 5000),
            );

            await Promise.race([
              PushNotificationService.initialize({
                notificationTypes: enabledTypes,
                watchlistSymbols: allSymbols,
              }),
              timeoutPromise,
            ]);
          }
        }
      } catch (e) {
        console.warn('Error initializing push notifications:', e);
      }

      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      updateNativeUI(darkModeMediaQuery.matches);

      const handleDarkModeChange = (e: MediaQueryListEvent) => {
        updateNativeUI(e.matches);
      };

      darkModeMediaQuery.addEventListener('change', handleDarkModeChange);

      // Only execute iOS specific code
      if (Capacitor.getPlatform() === 'ios') {
        try {
          await StatusBar.setOverlaysWebView({ overlay: true });
          await Keyboard.setAccessoryBarVisible({ isVisible: true });
          await Keyboard.setResizeMode({ mode: KeyboardResize.Native });
        } catch (error) {
          console.warn('Error configuring iOS specific features:', error);
        }
      }

      stores.General.update((s) => {
        s.isAppReady = true;
        s.hasSeenSubscriptionTakeoverThisSession = false;
      });

      // Only notify if we are on the native platform
      if (Capacitor.isNativePlatform()) {
        try {
          // Set a timeout to prevent blocking indefinitely
          const timeoutPromise = new Promise((_, reject) =>
            setTimeout(() => reject(new Error('CapacitorUpdater timeout')), 5000),
          );

          await Promise.race([CapacitorUpdater.notifyAppReady(), timeoutPromise]);
        } catch (e) {
          console.warn('Error in CapacitorUpdater:', e);
        }
      }

      return () => {
        darkModeMediaQuery.removeEventListener('change', handleDarkModeChange);
      };
    })();
  }, []);

  // Only add keyboard listeners if we are on the native platform
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', () => {
        stores.General.update((s) => {
          s.isKeyboardVisible = true;
        });
      });

      Keyboard.addListener('keyboardWillHide', () => {
        stores.General.update((s) => {
          s.isKeyboardVisible = false;
        });
      });
    }
  }, []);

  if (!isAppReady) return <div />;

  return (
    <div
      onClick={() => {
        stores.General.update((s) => {
          s.isSearchOnboardingPopoverShown = false;
          s.isBookmarkOnboardingPopoverShown = false;
        });
      }}
    >
      <div
        css={
          (isSearchOnboardingPopoverShown || isBookmarkOnboardingPopoverShown) &&
          css`
            pointer-events: none;
          `
        }
      >
        <Router>
          <RouterApp />
          <SubscriptionTakeover />
        </Router>
      </div>
    </div>
  );
};

initializeTheme();

render(
  <div>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppWrapper />
        <Global styles={getGlobalStyles()} />
        <ClassNames>
          {({ css }) => (
            <ToastContainer
              position="bottom-center"
              hideProgressBar
              draggable={false}
              newestOnTop
              closeButton={false}
              autoClose={3000}
              className={css`
                position: fixed;
                z-index: 300;
                padding: 0 ${buildPageSpacing(spacing.regular, 'RIGHT')} 0 ${buildPageSpacing(spacing.regular, 'LEFT')};
                margin-bottom: calc(8px + ${buildPageSpacing(spacing.giga, 'BOTTOM')});
              `}
              toastClassName={css`
                min-height: initial;
                color: ${colors.liquorice};
                background-color: ${colors.sugar};
                padding: ${spacing.deci};
                ${fontStyles.cat};
              `}
              bodyClassName={css`
                width: 100%;
                padding: 0;
              `}
              transition={cssTransition({
                enter: 'animate__animated animate__fadeInUp animate__faster',
                exit: 'animate__animated animate__fadeOutDown animate__faster',
              })}
            />
          )}
        </ClassNames>
      </QueryClientProvider>
    </ChakraProvider>
  </div>,
  document.getElementById('react-entry'),
);
