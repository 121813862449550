import { css } from '@emotion/react';
import { ComponentPropsWithoutRef } from 'react';
import { useHistory } from 'react-router';
import BookmarkButton from '../BookmarkButton';
import { buildPageSpacing, spacing, fontStyles, colors } from '../../styles';
import Button from '../Button';
import backIcon from './back.svg';

interface Props extends ComponentPropsWithoutRef<'header'> {
  headingText: string;
  symbol?: string;
  category?: string;
  name?: string;
  rightAction?: React.ReactNode;
  onBack?: () => void;
}

const Header = ({ headingText, symbol, category, name, rightAction, onBack, ...props }: Props) => {
  const history = useHistory();

  const styles = {
    header: css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 102;
      background-color: ${colors.sugar};
      padding: ${buildPageSpacing('0px', 'TOP')} ${buildPageSpacing('0px', 'RIGHT')} 0
        ${buildPageSpacing('0px', 'LEFT')};
      display: flex;
      align-items: center;
      html[data-theme='dark'] & {
        background-color: ${colors.liquorice};
      }
    `,
    headerWrapper: css`
      margin-bottom: 28px;
    `,
    backWrapper: css`
      flex: 0 0 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 359px) {
        flex: 0 0 50px;
      }
    `,
    backLink: css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${spacing.milli};
      min-width: 44px;
      min-height: 44px;

      @media (max-width: 359px) {
        padding: ${spacing.micro};
      }
    `,
    backImage: css`
      vertical-align: middle;
      height: 24px;
      width: 24px;

      @media (max-width: 359px) {
        height: 22px;
        width: 22px;
      }
    `,
    heading: css`
      flex: 1;
      text-align: center;
      margin: 0;
      color: ${colors.liquorice};
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
      ${fontStyles.fatCat};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 ${spacing.micro};
    `,
    bookmarkWrapper: css`
      flex: 0 0 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 359px) {
        flex: 0 0 50px;
      }
    `,
  };

  return (
    <div css={styles.headerWrapper}>
      <header css={styles.header} {...props}>
        <div css={styles.backWrapper}>
          <Button onClick={onBack || history.goBack} css={styles.backLink}>
            <img src={backIcon} alt="" css={styles.backImage} />
          </Button>
        </div>

        <h1 css={styles.heading}>{headingText}</h1>

        <div css={styles.bookmarkWrapper}>
          {rightAction ||
            (symbol && category && name && (
              <BookmarkButton symbol={symbol} category={category} name={name} showLabel={false} />
            ))}
        </div>
      </header>
    </div>
  );
};

export default Header;
