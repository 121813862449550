import { css } from '@emotion/react';
import { colors } from '../../../styles';

export const OnboardingButton = ({
  currentSlide,
  onClick,
  children,
  disabled,
}: {
  currentSlide?: number;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <button
      type="button"
      css={css`
        width: 100%;
        height: 60px;
        background-color: ${colors.petrolBlue};
        color: ${colors.sugar};
        border: none;
        border-radius: 4px;
        font-size: 18.4px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: ${currentSlide === 5 ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none'};
        margin-top: ${currentSlide === 5 ? '20px' : '0'};
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
