import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import * as stores from '../../stores';
import { spacing } from '../../styles';
import Search from '../Search';
import Page from '../Page';
import Header from '../Header';
import CollectionsList from './CollectionsList';

const SearchPage: FC = () => {
  const { appOpenDates, searchOnboardingPopoverOpenedAt } = stores.General.useState();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!searchOnboardingPopoverOpenedAt && appOpenDates && appOpenDates.length <= 1) {
      // Set a timeout to hack around positioning issue.
      // Page component, and maybe the safe-area-inset, pushes around the content while loading.
      // Timeout hopefully delays the popover positioning until after the pushing around occurs.
      setTimeout(() => {
        stores.General.update((s) => {
          s.isSearchOnboardingPopoverShown = true;
          s.searchOnboardingPopoverOpenedAt = new Date().toISOString();
        });
      }, 800);
    }
  }, [searchOnboardingPopoverOpenedAt, appOpenDates]);

  return (
    <Page showNavigationBar>
      <Header headingText="" onBack={() => history.goBack()} />
      <div
        css={css`
          padding-top: ${spacing.milli};
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <h1
          css={css`
            font-size: 1.38rem;
            line-height: 1.88rem;
            font-weight: 500;
            margin: 0;
            margin-top: ${spacing.centi};
            margin-bottom: ${spacing.deci};
            color: var(--text-color);

            @media (max-width: 359px) {
              font-size: 1.25rem;
              line-height: 1.5rem;
            }
          `}
        >
          Search
        </h1>

        <Search
          isAllowBookmark
          autoFocus={true}
          css={css`
            margin-bottom: ${spacing.regular};
          `}
        />

        <CollectionsList />
      </div>
    </Page>
  );
};

export default SearchPage;
