import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { Capacitor } from '@capacitor/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { colors, fontStyles } from '../../../styles';
import { onboardingImages } from '../../../assets/onboardingImages';
import Gift from '../../../assets/Icons/Gift.svg';

interface SocialMedia {
  id: string;
  name: string;
  icon: string;
}

interface OnboardingSocialMedialProps {
  onSelect: (id: string) => void;
}

const socialMediaList: SocialMedia[] = [
  { id: 'instagram', name: 'Instagram', icon: onboardingImages.instagram },
  { id: 'facebook', name: 'Facebook', icon: onboardingImages.facebook },
  { id: 'tiktok', name: 'TikTok', icon: onboardingImages.tiktok },
  { id: 'youtube', name: 'Youtube', icon: onboardingImages.youtube },
  { id: 'google', name: 'Google', icon: onboardingImages.google },
  { id: 'tv', name: 'TV', icon: onboardingImages.tv },
  { id: 'appStore', name: 'App Store', icon: onboardingImages.appStore },
  { id: 'playStore', name: 'Play Store', icon: onboardingImages.playStore },
  { id: 'x', name: 'X', icon: onboardingImages.x },
  { id: 'reddit', name: 'Reddit', icon: onboardingImages.reddit },
  { id: 'LinkedIn', name: 'LinkedIn', icon: onboardingImages.linkedin },
  { id: 'promoCode', name: 'Promo Code', icon: Gift },
];

const OnboardingSocialMedial: React.FC<OnboardingSocialMedialProps> = ({ onSelect }) => {
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    // This will run when the component unmounts
    return () => {
      if (selected) {
        // Log the source selection to localStorage instead of Firebase
        try {
          const selectedMedia = socialMediaList.find((media) => media.id === selected);
          localStorage.setItem('selectedSource', selected);
          localStorage.setItem('selectedSourceName', selectedMedia?.name || '');

          // Only try Firebase if in native environment
          if (Capacitor.isNativePlatform()) {
            // Analytics logging is non-critical, so we wrap it in a silent try-catch
            try {
              FirebaseAnalytics.logEvent({
                name: 'social_media_selection',
                params: {
                  social_media_name: selectedMedia?.name || '',
                  social_media_id: selected,
                  platform: Capacitor.getPlatform(),
                  timestamp: new Date().toISOString(),
                },
              }).catch((e) => {
                // Silently catch Firebase errors
                console.log('Firebase analytics unavailable:', e);
              });
            } catch (e) {
              // Silently catch any other errors
              console.log('Firebase analytics error:', e);
            }
          }
        } catch (error) {
          console.log('Error saving selection:', error);
        }
      }
    };
  }, [selected]);

  const handleSelect = async (id: string) => {
    setSelected(id);

    if (onSelect) {
      onSelect(id);
    }
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 24px;
        `}
      >
        {socialMediaList.map((media) => (
          <div
            key={media.id}
            onClick={() => handleSelect(media.id)}
            css={css`
              background-color: ${selected === media.id ? colors.petrolBlue : 'white'};
              color: ${selected === media.id ? 'white' : colors.liquorice};
              padding: 16px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              border: 1px solid ${selected === media.id ? colors.petrolBlue : colors.cloud};
              cursor: pointer;
              transition: all 0.2s ease;

              &:hover {
                transform: translateY(-2px);
                border-color: ${selected === media.id ? colors.petrolBlue : colors.silver};
              }

              html[data-theme='dark'] & {
                background-color: ${selected === media.id ? colors.skyBlue : 'var(--card-background)'};
                color: ${selected === media.id ? 'white' : colors.sugar};
                border-color: ${selected === media.id ? colors.skyBlue : colors.tungsten};

                &:hover {
                  border-color: ${selected === media.id ? colors.skyBlue : colors.silver};
                }
              }
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
              `}
            >
              <img
                src={media.icon}
                alt={media.name}
                css={css`
                  width: 24px;
                  height: 24px;
                  ${media.id === 'promoCode' &&
                  `
                    filter: brightness(0);
                    
                    html[data-theme='dark'] & {
                      filter: brightness(10);
                    }
                  `}
                `}
              />
            </div>
            <div
              css={css`
                ${fontStyles.dog};
                font-weight: 500;
              `}
            >
              {media.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnboardingSocialMedial;
