import React from 'react';
import { css } from '@emotion/react';
import { Capacitor } from '@capacitor/core';
import { useHistory } from 'react-router-dom';
import { OnboardingButton } from '../Components/OnboardingButton';
import { onboardingImages } from '../../../assets/onboardingImages';
import { colors, fontStyles, spacing } from '../../../styles';

export const Welcome: React.FC = () => {
  const history = useHistory();

  const handleStart = () => {
    localStorage.removeItem('onboardingIndex'); // Reinicia progreso
    history.push('/onboarding');
  };

  return (
    <div
      css={css`
        width: 100%;
        max-width: 1024px;
        height: 100vh;
        margin: 0 auto;
        background-color: #fffefc;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        /* For smaller screens, use full height */
        @media (max-height: 700px) {
          height: 100vh;
        }

        ${Capacitor.isNativePlatform() &&
        `
        /* For larger screens, constrain the height */
        @media (min-height: 900px) {
          max-height: 900px;
        }
          `}

        html[data-theme='dark'] & {
          background-color: ${colors.liquorice};
        }
      `}
    >
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        `}
      >
        <div
          css={css`
            position: relative;
            width: 250px;
            height: 188px;
            display: flex;
            justify-content: center;
          `}
        >
          <img
            src={onboardingImages.welcome}
            alt="Sunrise"
            css={css`
              width: 100%;
              height: 100%;
              object-fit: contain;
            `}
          />
        </div>
      </div>

      <div
        css={css`
          width: 100%;
          padding: 0 24px;
          margin-bottom: 24px;
        `}
      >
        <div
          css={css`
            text-align: center;
            margin-bottom: 32px;
          `}
        >
          <h1
            css={css`
              ${fontStyles.shark};
              color: ${colors.liquorice};
              margin-bottom: ${spacing.deci};
              font-size: 30px;
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            Invest like a professional with AI
          </h1>
        </div>
      </div>

      <div
        css={css`
          width: 100%;
          padding: 16px;
          /* Add safe area padding at bottom */
          padding-bottom: calc(16px + env(safe-area-inset-bottom, 0px));
        `}
      >
        <OnboardingButton onClick={handleStart}>Get Started</OnboardingButton>
      </div>
    </div>
  );
};
