import { FC } from 'react';
import { css } from '@emotion/react';
import Card from '../Card';
import { spacing, fontStyles, colors } from '../../styles';

interface Props {
  name: string;
  imageSrc: string;
  isLocked?: boolean;
}

const CollectionCard: FC<Props> = ({ name, imageSrc, isLocked }) => (
  <Card
    css={css`
      height: 100%;
      width: 100%;
      color: ${colors.petrolBlue};
      position: relative;
      padding: 12px 8px 8px 8px;
      ${fontStyles.fatCat};
      ${isLocked &&
      `
        background-color: ${colors.cloud};
      `}

      html[data-theme='dark'] & {
        ${isLocked &&
        `
        background-color: ${colors.tungsten};
      `}
      }
    `}
  >
    {isLocked && (
      <div
        css={css`
          position: absolute;
          right: 0;
          top: 0;
          padding: inherit;
          background: ${colors.petrolBlue};
          color: ${colors.sugar};
          padding: 2px ${spacing.milli};
          border-radius: 4px;
          ${fontStyles.flea}
          text-transform: uppercase;
          letter-spacing: 0.5px;
        `}
      >
        Pro
      </div>
    )}
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        gap: ${spacing.milli};
      `}
    >
      <img
        src={imageSrc}
        css={css`
          width: 85%;
          object-fit: contain;
        `}
      />
      <div
        css={css`
          overflow: hidden;
          word-wrap: break-word;
          min-height: 2.4em;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;

          & > span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 100%;
          }
        `}
      >
        <span>{name}</span>
      </div>
    </div>
  </Card>
);

export default CollectionCard;
