import { css } from '@emotion/react';
import { colors, fontStyles } from '../../../styles';
import GiftIcon from '../../../assets/Icons/Gift.svg';

interface DiscountModalProps {
  onClose: () => void;
  handleClaimOffer: () => void;
  discountMonthlyPrice: number;
  discountPercentage: number;
}

const DiscountModal: React.FC<DiscountModalProps> = ({
  onClose,
  discountMonthlyPrice,
  handleClaimOffer,
  discountPercentage,
}) => {
  return (
    <div
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        html[data-theme='dark'] & {
          background-color: ${colors.liquorice};
        }
        z-index: 1000;
        display: flex;
        flex-direction: column;
        padding: 24px;
      `}
    >
      <div
        css={css`
          padding: 16px 0;
          display: flex;
          justify-content: flex-start;
        `}
      >
        <button
          css={css`
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            color: ${colors.silver};
            padding: 0;
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            }
          `}
          onClick={onClose}
        >
          ✕
        </button>
      </div>

      <div
        css={css`
          text-align: center;
          margin-bottom: 24px;
        `}
      >
        <h1
          css={css`
            ${fontStyles.shark};
            margin-bottom: 8px;
            text-align: center;
            color: ${colors.liquorice};
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            }
          `}
        >
          One Time Offer
        </h1>

        <p
          css={css`
            color: ${colors.silver};
            text-align: center;
            ${fontStyles.cat};
          `}
        >
          You will never see this again
        </p>
      </div>

      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            position: relative;
            border: 1px solid ${colors.fog};
            border-radius: 16px;
            padding: 36px 24px 24px;
            width: 100%;
            min-height: 200px;
            max-width: 350px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
            background-color: ${colors.fog};
            box-shadow: 0px 2px 3px 0px rgba(203, 202, 201, 0.5);
            html[data-theme='dark'] & {
              background-color: ${colors.liquorice};
              border-color: ${colors.tungsten};
            }
          `}
        >
          <div
            css={css`
              position: absolute;
              top: -22px;
              width: 76px;
              height: 42px;
              background-color: ${colors.petrolBlue};
              border-radius: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <img
              src={GiftIcon}
              alt="Gift icon"
              css={css`
                width: 28px;
                height: 28px;
              `}
            />
          </div>

          <p
            css={css`
              font-size: 18px;
              margin-bottom: 16px;
              display: flex;
              align-items: center;
              color: ${colors.liquorice};
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            Here&apos;s a{' '}
            <span
              css={css`
                color: #22c55e;
                font-weight: 600;
                margin: 0 8px;
              `}
            >
              {discountPercentage}% off
            </span>{' '}
            discount
          </p>

          <div
            css={css`
              border: 2px solid ${colors.petrolBlue};
              border-radius: 8px;
              padding: 12px 24px;
              ${fontStyles.roboCat};
              color: ${colors.liquorice};
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            Only ${discountMonthlyPrice} / month
          </div>
        </div>
      </div>

      <div>
        <button
          css={css`
            width: 100%;
            height: 56px;
            background-color: ${colors.petrolBlue};
            color: white;
            border: none;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
          `}
          onClick={handleClaimOffer}
        >
          Claim your {discountPercentage}% discount
        </button>
      </div>
    </div>
  );
};

export default DiscountModal;
