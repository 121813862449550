import { isNil } from 'lodash';
import { Preferences } from '@capacitor/preferences';
import { v4 as uuidv4 } from 'uuid';
import { Store } from 'pullstate';
import { PushNotificationService } from './services/pushNotificationService';

// Updates the notification service with the current list of unique tickers from all portfolios
const updateNotificationTickers = async (portfolios: Portfolio[]) => {
  console.log('[DEBUG] updateNotificationTickers called with portfolios:', portfolios);

  try {
    const allSymbols = Array.from(new Set(portfolios.flatMap((p) => p.symbols || [])));
    console.log('[DEBUG] All unique symbols collected:', allSymbols);

    const savedPreferences = localStorage.getItem('notificationPreferences');
    if (!savedPreferences) {
      console.log('[DEBUG] No notification preferences found in localStorage');
      return;
    }

    const preferences = JSON.parse(savedPreferences);
    const enabledTypes = preferences
      .filter((pref: { enabled: boolean }) => pref.enabled)
      .map((pref: { id: string }) => pref.id);

    console.log('[DEBUG] Enabled notification types:', enabledTypes);

    if (enabledTypes.length > 0) {
      console.log('[DEBUG] Calling PushNotificationService.updateConfig with symbols:', allSymbols);

      // Set a timeout to prevent blocking indefinitely
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('PushNotificationService updateConfig timeout')), 5000),
      );

      await Promise.race([
        PushNotificationService.updateConfig({
          notificationTypes: enabledTypes,
          watchlistSymbols: allSymbols,
        }),
        timeoutPromise,
      ]);
    } else {
      console.log('[DEBUG] No enabled notification types, skipping update');
    }
  } catch (error) {
    console.warn('[DEBUG] Error updating notification tickers:', error);
    // Continue app flow despite notification errors
  }
};

export const set = async (key: string, value: unknown): Promise<void> => {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
};

export const get = async <Type>(key: string): Promise<Type | undefined> => {
  const ret = await Preferences.get({ key });
  if (isNil(ret.value)) return undefined;
  return JSON.parse(ret.value);
};

// Interface for portfolio data
export interface Allocation {
  symbol: string;
  name: string;
  allocation_percentage: number;
  rationale: string;
}

export interface Portfolio {
  id: string;
  title: string;
  strategy: string; // Optional, keep for compatibility
  symbols: string[]; // Add this field for stock symbols
  allocations?: Allocation[]; // Make allocations optional for watchlist-only portfolios
  total_allocation?: number;
}

// Type for minimal portfolio data needed for selection modals
export interface PortfolioMinimal {
  id: string;
  title: string;
  symbols: string[];
}

// Portfolio Store
export const portfoliosStoreId = 'portfolios';

interface PortfoliosState {
  portfolios: Portfolio[];
  isLoading: boolean;
}

export const Portfolios = new Store<PortfoliosState>({
  portfolios: [],
  isLoading: false,
});

// Initialize the portfolio store and setup persistence
export const initializePortfolios = async () => {
  // Setup persistence by subscribing to store changes
  Portfolios.subscribe(
    (s) => s.portfolios,
    (portfolios) => {
      set(portfoliosStoreId, portfolios);
    },
  );

  // Load initial data
  try {
    Portfolios.update((s) => {
      s.isLoading = true;
    });
    const savedPortfolios = await get<Portfolio[]>(portfoliosStoreId);

    Portfolios.update((s) => {
      s.portfolios = savedPortfolios || [];
      s.isLoading = false;
    });
  } catch (error) {
    console.error('Error loading portfolios:', error);
    Portfolios.update((s) => {
      s.isLoading = false;
    });
  }
};

// Get all portfolios from the store
export const getPortfolios = async (): Promise<Portfolio[]> => {
  // If the store hasn't been initialized yet, fetch from storage directly
  if (Portfolios.getRawState().portfolios.length === 0 && !Portfolios.getRawState().isLoading) {
    await initializePortfolios();
  }
  return Portfolios.getRawState().portfolios;
};

// Save a new portfolio to the store
export const savePortfolio = async (portfolio: Omit<Portfolio, 'id'>): Promise<string> => {
  const id = uuidv4();

  const newPortfolio = {
    id,
    ...portfolio,
    symbols: portfolio.symbols || [], // Default to empty array
    allocations: portfolio.allocations || [], // Default to empty array
  };

  Portfolios.update((s) => {
    s.portfolios = [...s.portfolios, newPortfolio];
  });

  // Refresh notification tickers after portfolio change
  await updateNotificationTickers(Portfolios.getRawState().portfolios);

  return id;
};

// Update an existing portfolio in the store
export const updatePortfolio = async (portfolio: Portfolio): Promise<boolean> => {
  try {
    const currentPortfolios = Portfolios.getRawState().portfolios;
    const index = currentPortfolios.findIndex((p) => p.id === portfolio.id);

    // If portfolio doesn't exist, return false
    if (index === -1) return false;

    Portfolios.update((s) => {
      s.portfolios = [
        ...s.portfolios.slice(0, index),
        {
          ...portfolio,
          symbols: portfolio.symbols || [], // Ensure symbols is an array
          allocations: portfolio.allocations || [], // Ensure allocations is an array
        },
        ...s.portfolios.slice(index + 1),
      ];
    });

    // Refresh notification tickers after portfolio change
    await updateNotificationTickers(Portfolios.getRawState().portfolios);

    return true;
  } catch (error) {
    console.error('Error updating portfolio:', error);
    return false;
  }
};

// Delete a portfolio from the store
export const deletePortfolio = async (id: string): Promise<{ success: boolean; deletedPortfolio?: Portfolio }> => {
  try {
    const currentPortfolios = Portfolios.getRawState().portfolios;
    const deletedPortfolio = currentPortfolios.find((portfolio) => portfolio.id === id);

    if (!deletedPortfolio) {
      return { success: false };
    }

    Portfolios.update((s) => {
      s.portfolios = s.portfolios.filter((portfolio) => portfolio.id !== id);
    });

    // Refresh notification tickers after portfolio change
    await updateNotificationTickers(Portfolios.getRawState().portfolios);

    return { success: true, deletedPortfolio };
  } catch (error) {
    console.error('Error deleting portfolio:', error);
    return { success: false };
  }
};

// Restore a deleted portfolio
export const restorePortfolio = async (portfolio: Portfolio): Promise<boolean> => {
  try {
    const currentPortfolios = Portfolios.getRawState().portfolios;

    // Check if a portfolio with this ID already exists
    if (currentPortfolios.some((p) => p.id === portfolio.id)) {
      return false;
    }

    Portfolios.update((s) => {
      s.portfolios = [...s.portfolios, portfolio];
    });

    // Refresh notification tickers after portfolio change
    await updateNotificationTickers(Portfolios.getRawState().portfolios);

    return true;
  } catch (error) {
    console.error('Error restoring portfolio:', error);
    return false;
  }
};

// Get only minimal portfolio data (id, title, symbols) from the store
export const getPortfolioMinimalData = async (): Promise<PortfolioMinimal[]> => {
  const portfolios = await getPortfolios();

  return portfolios.map((portfolio) => ({
    id: portfolio.id,
    title: portfolio.title,
    symbols: portfolio.symbols || [],
  }));
};
