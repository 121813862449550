import React from 'react';
import { css } from '@emotion/react';
import { spacing, fontStyles, colors, borderRadius } from '../../styles';
import { TimeRange } from '../../types';

interface PortfolioColumnSelectorProps {
  selectedMetric: string;
  onMetricChange: (metric: string) => void;
}

// Available metrics from BasicInvestment with human-readable labels
const metrics = [
  { value: 'latestPrice', label: 'Latest Price' },
  { value: 'changePercent1d', label: '1 Day Change %' },
  { value: 'changePercent1w', label: '1 Week Change %' },
  { value: 'changePercent1m', label: '1 Month Change %' },
  { value: 'changePercent3m', label: '3 Month Change %' },
  { value: 'changePercentYtd', label: 'YTD Change %' },
  { value: 'changePercent1y', label: '1 Year Change %' },
  { value: 'changePercent5y', label: '5 Year Change %' },
  { value: 'marketCap', label: 'Market Cap' },
  { value: 'allocation_percentage', label: 'Allocation %' },
  { value: 'priceToEarnings', label: 'Price to Earnings' },
  { value: 'priceToSales', label: 'Price to Sales' },
  { value: 'priceToSalesGrowth', label: 'Price to Sales Growth' },
  { value: 'week52Range', label: '52 Week Range' },
  { value: 'revenueGrowth1y', label: 'Revenue Growth (1y)' },
  { value: 'cashflowYield', label: 'Cashflow Yield' },
  { value: 'evToGrossProfit', label: 'EV to Gross Profit' },
  { value: 'insidersPercentTraded3m', label: 'Insider Trade % (3m)' },
];

// Find time range from change percent metric
export const getTimeRangeFromMetric = (metric: string): TimeRange | undefined => {
  switch (metric) {
    case 'changePercent1d':
      return TimeRange.days1;
    case 'changePercent1w':
      return TimeRange.weeks1;
    case 'changePercent1m':
      return TimeRange.months1;
    case 'changePercent3m':
      return TimeRange.months3;
    case 'changePercentYtd':
      return TimeRange.ytd;
    case 'changePercent1y':
      return TimeRange.years1;
    case 'changePercent5y':
      return TimeRange.years5;
    default:
      return undefined;
  }
};

// Check if a metric is a change percent type
export const isChangePercentMetric = (metric: string): boolean => {
  return metric.startsWith('changePercent');
};

const PortfolioColumnSelector: React.FC<PortfolioColumnSelectorProps> = ({ selectedMetric, onMetricChange }) => {
  const selectContainerStyles = css`
    width: 100%;
    margin-bottom: ${spacing.deci};
  `;

  const selectStyles = css`
    width: 100%;
    padding: ${spacing.centi};
    border-radius: ${borderRadius};
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    color: var(--text-color);
    ${fontStyles.cat};
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23888' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right ${spacing.centi} center;
    padding-right: ${spacing.deka};

    &:focus {
      outline: none;
      border-color: ${colors.petrolBlue};
      html[data-theme='dark'] & {
        border-color: ${colors.skyBlue};
      }
    }
  `;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onMetricChange(e.target.value);
  };

  return (
    <div css={selectContainerStyles}>
      <select css={selectStyles} value={selectedMetric} onChange={handleChange}>
        {metrics.map((metric) => (
          <option key={metric.value} value={metric.value}>
            {metric.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PortfolioColumnSelector;
