import { mapValues } from 'lodash';
import { Column } from 'react-table';
import { BasicInvestment } from '../../apis/backend';
import { TimeRange } from '../../types';

export enum ColumnSelectionType {
  Name = 'name',
  Symbol = 'symbol',
  BottomLine = 'bottomLine',
  Week52Range = 'week52Range',
  ChangePercent = 'changePercent',
  PriceToEarnings = 'priceToEarnings',
  PriceToSales = 'priceToSales',
  PriceToSalesGrowth = 'priceToSalesGrowth',
  RevenueGrowth1y = 'revenueGrowth1y',
  LatestPrice = 'latestPrice',
  PercentMarketCapTraded1d = 'percentMarketCapTraded1d',
  MarketCap = 'marketCap',
  CashflowYield = 'cashflowYield',
  EvToGrossProfit = 'evToGrossProfit',
  InsidersPercentTraded3m = 'insidersPercentTraded3m',
}

export interface ColumnSelection {
  type: ColumnSelectionType;
  timeRange?: TimeRange;
}

export enum ColumnId {
  Name = 'name',
  Symbol = 'symbol',
  ChangePercent1d = 'changePercent1d',
  ChangePercent1w = 'changePercent1w',
  ChangePercent1m = 'changePercent1m',
  ChangePercent3m = 'changePercent3m',
  ChangePercentYtd = 'changePercentYtd',
  ChangePercent1y = 'changePercent1y',
  ChangePercent5y = 'changePercent5y',
  LatestPrice = 'latestPrice',
  Week52Range = 'week52Range',
  BottomLine = 'bottomLine',
  RevenueGrowth1y = 'revenueGrowth1y',
  PercentMarketCapTraded1d = 'percentMarketCapTraded1d',
  PriceToEarnings = 'priceToEarnings',
  PriceToSales = 'priceToSales',
  PriceToSalesGrowth = 'priceToSalesGrowth',
  MarketCap = 'marketCap',
  CashflowYield = 'cashflowYield',
  EvToGrossProfit = 'evToGrossProfit',
  InsidersPercentTraded3m = 'insidersPercentTraded3m',
}

const translateSelectionToId = ({ type, timeRange }: ColumnSelection): ColumnId => {
  if (type !== ColumnSelectionType.ChangePercent) return type as any;
  if (!timeRange) throw new Error('Time range not supplied for change percent.');
  const timeRangeToColumnId = {
    [TimeRange.days1]: ColumnId.ChangePercent1d,
    [TimeRange.weeks1]: ColumnId.ChangePercent1w,
    [TimeRange.months1]: ColumnId.ChangePercent1m,
    [TimeRange.months3]: ColumnId.ChangePercent3m,
    [TimeRange.ytd]: ColumnId.ChangePercentYtd,
    [TimeRange.years1]: ColumnId.ChangePercent1y,
    [TimeRange.years5]: ColumnId.ChangePercent5y,
  };
  return timeRangeToColumnId[timeRange];
};

export const translateIdToSelection = (columnId: ColumnId): ColumnSelection => {
  const columnIdToTimeRange: Record<string, TimeRange> = {
    [ColumnId.ChangePercent1d]: TimeRange.days1,
    [ColumnId.ChangePercent1w]: TimeRange.weeks1,
    [ColumnId.ChangePercent1m]: TimeRange.months1,
    [ColumnId.ChangePercent3m]: TimeRange.months3,
    [ColumnId.ChangePercentYtd]: TimeRange.ytd,
    [ColumnId.ChangePercent1y]: TimeRange.years1,
    [ColumnId.ChangePercent5y]: TimeRange.years5,
  };
  const timeRange = columnIdToTimeRange[columnId];
  return {
    type: timeRange ? ColumnSelectionType.ChangePercent : ((<unknown>columnId) as ColumnSelectionType),
    timeRange,
  };
};

const columnIdToColumns = mapValues(
  {
    [ColumnId.Name]: { Header: 'Name', sortType: 'string' },
    [ColumnId.Symbol]: { Header: 'Symbol', sortType: 'string' },
    [ColumnId.BottomLine]: { Header: 'Bottom line', sortType: 'bottomLine' },
    [ColumnId.MarketCap]: { Header: 'Market cap', sortType: 'basic' },
    [ColumnId.LatestPrice]: { Header: 'Price', sortType: 'basic' },
    [ColumnId.CashflowYield]: { Header: 'Cashflow Yield', sortType: 'basic' },
    [ColumnId.ChangePercent1d]: { Header: '1d Change', sortType: 'basic' },
    [ColumnId.ChangePercent1w]: { Header: '1w Change', sortType: 'basic' },
    [ColumnId.ChangePercent1m]: { Header: '1m Change', sortType: 'basic' },
    [ColumnId.ChangePercent3m]: { Header: '3m Change', sortType: 'basic' },
    [ColumnId.ChangePercentYtd]: { Header: 'YTD Change', sortType: 'basic' },
    [ColumnId.ChangePercent1y]: { Header: '1y change', sortType: 'basic' },
    [ColumnId.ChangePercent5y]: { Header: '5y change', sortType: 'basic' },
    [ColumnId.RevenueGrowth1y]: { Header: '1y rev growth', sortType: 'basic' },
    [ColumnId.PercentMarketCapTraded1d]: { Header: '1d trade', sortType: 'basic' },
    [ColumnId.PriceToEarnings]: { Header: 'P/E', sortType: 'basic' },
    [ColumnId.PriceToSales]: { Header: 'P/S', sortType: 'basic' },
    [ColumnId.PriceToSalesGrowth]: { Header: 'P/S Growth', sortType: 'basic' },
    [ColumnId.EvToGrossProfit]: { Header: 'EV/Gross Profit', sortType: 'basic' },
    [ColumnId.InsidersPercentTraded3m]: { Header: 'Insider trades', sortType: 'basic' },
    [ColumnId.Week52Range]: {
      Header: '52w high-low',
      accessor: (row: Partial<BasicInvestment>) => {
        return { high: row.week52High, low: row.week52Low, current: row.latestPrice };
      },
      id: ColumnId.Week52Range,
      sortInverted: true,
      sortType: 'week52Range',
    },
  },
  (value, key) => ({ accessor: key, ...value }),
) as Record<ColumnId, Column<Partial<BasicInvestment>>>;

export const getColumns = (columnSelection: ColumnSelection) => {
  const id = translateSelectionToId(columnSelection);
  return columnIdToColumns[id];
};
