/* eslint-disable react/jsx-key */
import { pickBy } from 'lodash';
import { FC, useMemo, useEffect, Fragment, useState } from 'react';
import { css } from '@emotion/react';
import { Row, useTable, useSortBy, ColumnInstance, SortingRule } from 'react-table';
import { Link, useHistory } from 'react-router-dom';
import BottomLineExplanationTakeover from '../BottomLineExplanationTakeover';
import Button from '../Button';
import { Rating, CollectionInvestment, BasicInvestment, Wiim } from '../../apis/backend';
import { fontStyles, spacing, colors } from '../../styles';
import BookmarkButton from '../BookmarkButton';
import ColumnTakeover from './ColumnTakeover';
import { ColumnSelection, ColumnSelectionType, getColumns } from './columns';
import adjustIcon from './adjust.svg';
import { filterOptions, FilterKey } from './filterOptions';
import WiimPreview from './WiimPreview';
import Cell from './Cell';
import sortInactive from './sortInactive.svg';
import sortDescending from './sortDescending.svg';
import sortAscending from './sortAscending.svg';

const sortTypes = {
  week52Range: (rowA: Row<any>, rowB: Row<any>) => {
    if (!rowA.original.week52Low || !rowA.original.week52High || !rowA.original.latestPrice) return 1;
    if (!rowB.original.week52Low || !rowB.original.week52High || !rowB.original.latestPrice) return -1;
    const calculateRatio = ({ latestPrice, week52Low, week52High }: BasicInvestment): number =>
      (latestPrice - week52Low) / (week52High - week52Low);
    const ratioA = calculateRatio(rowA.original);
    const ratioB = calculateRatio(rowB.original);
    return ratioA > ratioB ? -1 : 1;
  },

  bottomLine: (rowA: Row<any>, rowB: Row<any>) => {
    const calculateBottomLinePoints = (bottomLine: { [key: string]: Rating }): number => {
      const ratingPoints: { [key in Rating]: number } = {
        [Rating.Exceptional]: 2,
        [Rating.Favorable]: 1,
        [Rating.Average]: 0,
        [Rating.Missing]: 0,
        [Rating.Unfavorable]: -1,
        [Rating.Dangerous]: -2,
      };

      const isStock = 'profitRating' in bottomLine && bottomLine.profitRating != null;

      const relevantRatings = isStock
        ? ['profitRating', 'growthRating', 'priceRating', 'insiderRating']
        : ['returnRating', 'riskRating', 'sizeRating', 'feesRating'];

      return relevantRatings.reduce(
        (previousValue, key) => previousValue + (bottomLine[key] ? ratingPoints[bottomLine[key]] : 0),
        0,
      );
    };

    const rowAPoints = calculateBottomLinePoints(rowA.original.bottomLine ?? {});
    const rowBPoints = calculateBottomLinePoints(rowB.original.bottomLine ?? {});

    return rowAPoints > rowBPoints ? -1 : 1;
  },
};
interface Props {
  isLoading?: boolean;
  investments: Partial<BasicInvestment>[];
  wiims?: { [symbol: string]: Wiim[] };
  columnSelections: ColumnSelection[];
  onChangeColumnSelections?: (index: number, value: ColumnSelection) => void;
  sortingRule: SortingRule<string>;
  onChangeSortingRule?: (rule: SortingRule<string>) => void;
  filters?: Partial<Record<FilterKey, boolean>>;
  onSetFilter?: (key: FilterKey) => void;
  showBookmark?: boolean;
}

const getSortIcon = (column: ColumnInstance) => {
  if (!column.isSorted) return sortInactive;
  return column.isSortedDesc ? sortDescending : sortAscending;
};

export const InvestmentTable: FC<Props> = ({
  investments,
  isLoading,
  wiims,
  columnSelections,
  onChangeColumnSelections,
  sortingRule,
  onChangeSortingRule,
  filters,
  onSetFilter,
  showBookmark = false,
}) => {
  const history = useHistory();
  const [isColumnTakeoverShown, setIsColumnTakeoverShown] = useState(false);
  const [isBottomLineExplanationShown, setIsBottomLineExplanationShown] = useState(false);
  const columns = useMemo(
    () => [
      ...columnSelections.map((columnSelection) => getColumns(columnSelection)),
      ...(showBookmark
        ? [
            {
              id: 'bookmark',
              Header: '',
              Cell: ({ row }: any) => (
                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    width: 25px;
                    height: 56px;
                    position: relative;
                    overflow: hidden;

                    > button {
                      padding: 0;
                      transform: scale(1.5);

                      img {
                        height: 14px;
                        width: 14px;
                      }
                    }
                  `}
                >
                  <BookmarkButton
                    symbol={row.original.symbol}
                    category={row.original.category || ''}
                    name={row.original.name || ''}
                  />
                </div>
              ),
              width: 25,
            },
          ]
        : []),
    ],
    [columnSelections, showBookmark],
  );
  const data = useMemo(
    () =>
      investments.filter((investment) => {
        if (!filters) {
          return investments;
        }
        return (Object.keys(pickBy(filters, (value) => value)) as FilterKey[])
          .map((key) => filterOptions[key].filterFn)
          .every((filterFn) => filterFn(investment));
      }),
    [investments, filters],
  );

  const initialState = {
    sortBy: [sortingRule],
  };

  const tableInstance = useTable({ columns, data, initialState, disableSortRemove: true, sortTypes }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = tableInstance;

  useEffect(() => {
    if (onChangeSortingRule) {
      onChangeSortingRule(state.sortBy[0]);
    }
  }, [state.sortBy, onChangeSortingRule]);

  return (
    <div>
      <BottomLineExplanationTakeover
        isShown={isBottomLineExplanationShown}
        onClose={() => setIsBottomLineExplanationShown(false)}
      />
      <ColumnTakeover
        isShown={isColumnTakeoverShown}
        columnSelections={columnSelections}
        onClose={() => setIsColumnTakeoverShown(false)}
        onChange={(index, value) => {
          if (!columnSelections || !onChangeColumnSelections) return;
          const indexOfDuplicate = columnSelections.findIndex((selection) => {
            if (value.type === ColumnSelectionType.ChangePercent)
              return value.type === selection.type && value.timeRange === selection.timeRange;
            return value.type === selection.type;
          });
          if (indexOfDuplicate >= 0) {
            onChangeColumnSelections(indexOfDuplicate, columnSelections[index]);
          }
          onChangeColumnSelections(index, value);
        }}
      />
      {filters && Object.keys(filters).length > 0 && (
        <div
          css={css`
            margin: 0 -${spacing.centi} -${spacing.milli} 0;
          `}
        >
          <div
            css={css`
              color: ${colors.silver};
              margin-bottom: ${spacing.centi};
              ${fontStyles.flea}
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            Filters
          </div>
          {(Object.keys(filters) as FilterKey[]).map((key) => {
            const { buttonComponent } = filterOptions[key];
            const isActive = !!filters[key];

            return (
              <Button
                key={key}
                variant={isActive ? 'primary' : 'secondary'}
                onClick={() => {
                  // setActiveFilters(disjointFilters ? { [key]: !isActive } : { ...activeFilters, [key]: !isActive });
                  if (onSetFilter) {
                    onSetFilter(key);
                  }
                }}
                css={css`
                  border-radius: 20px;
                  padding: ${spacing.milli} ${spacing.centi};
                  margin: 0 ${spacing.centi} ${spacing.centi} 0;
                  ${fontStyles.fairyfly}
                  & > * {
                    vertical-align: middle;
                  }
                `}
              >
                {buttonComponent}
              </Button>
            );
          })}
        </div>
      )}
      {onChangeColumnSelections && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin: ${spacing.milli} 0 ${spacing.deci} 0;
          `}
        >
          <Button
            onClick={() => setIsColumnTakeoverShown(true)}
            css={css`
              padding: ${spacing.deci} 0;
              ${fontStyles.flea}
            `}
          >
            <img
              src={adjustIcon}
              css={css`
                vertical-align: middle;
                margin-right: ${spacing.milli};
              `}
            />
            Change columns
          </Button>

          {columnSelections.some((selection) => selection.type === 'bottomLine') && (
            <Button
              onClick={() => setIsBottomLineExplanationShown(true)}
              css={css`
                padding: ${spacing.deci} 0;
                ${fontStyles.flea}
              `}
            >
              What&apos;s the Bottom Line?
            </Button>
          )}
        </div>
      )}
      <div
        css={css`
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          /* Hide scrollbar for cleaner look */
          &::-webkit-scrollbar {
            display: none;
          }
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        `}
      >
        <table
          {...getTableProps()}
          css={css`
            width: 100%;
            min-width: 320px; /* Ensure table doesn't shrink below 320px */
            table-layout: fixed;
            border-collapse: collapse;
            border-bottom: 1px solid ${colors.cloud};
            ${fontStyles.roboCat}

            @media (max-width: 359px) {
              font-size: 0.9rem;
            }
          `}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    css={[
                      css`
                        color: ${colors.silver};
                        padding: 0 ${spacing.milli} ${spacing.centi};
                        ${fontStyles.flea};
                        text-align: center;
                        html[data-theme='dark'] & {
                          color: ${colors.sugar};
                        }
                        ${column.id === 'bookmark' &&
                        `
                        width: 25px;
                      `}

                        &:first-of-type {
                          text-align: left;
                        }

                        &:last-of-type {
                          text-align: right;
                        }

                        @media (max-width: 359px) {
                          padding: 0 ${spacing.micro} ${spacing.milli};
                          font-size: 0.7rem;
                          white-space: nowrap;
                        }
                      `,
                      column.isSorted &&
                        css`
                          color: ${colors.petrolBlue};
                          html[data-theme='dark'] & {
                            color: ${colors.skyBlue};
                          }
                        `,
                    ]}
                  >
                    <span
                      css={css`
                        vertical-align: middle;
                      `}
                    >
                      {column.render('Header')}
                    </span>
                    {column.canSort && (
                      <img
                        src={getSortIcon(column)}
                        css={css`
                          vertical-align: middle;
                          margin-left: ${spacing.centi};
                        `}
                      />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const link = `/symbol/${(row.original as CollectionInvestment).symbol}`;
              const wiim = row.original.symbol && wiims?.[row.original.symbol]?.[0];

              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    {...row.getRowProps()}
                    css={css`
                      border-top: 1px solid ${colors.cloud};
                      height: 56px;
                      cursor: pointer;
                    `}
                    onClick={() => history.push(link)}
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        css={css`
                          padding: 0 ${spacing.milli};
                          text-align: center;

                          &:first-of-type {
                            text-align: left;
                          }

                          &:last-of-type {
                            text-align: right;
                          }

                          html[data-theme='dark'] & {
                            color: ${colors.sugar};
                          }

                          @media (max-width: 359px) {
                            padding: 0 ${spacing.micro};
                            font-size: 0.85rem;
                          }
                        `}
                      >
                        {cell.column.id === 'bookmark' ? (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            css={css`
                              display: flex;
                              justify-content: center;
                              padding: 0;
                            `}
                          >
                            {cell.render('Cell')}
                          </div>
                        ) : index === 0 ? (
                          <Link
                            to={link}
                            css={css`
                              color: ${colors.liquorice};
                              html[data-theme='dark'] & {
                                color: ${colors.sugar};
                              }

                              @media (max-width: 359px) {
                                font-size: 0.85rem;
                              }
                            `}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {cell.render(Cell as any)}
                          </Link>
                        ) : (
                          cell.render(Cell as any, { emptyText: isLoading ? '' : '—' })
                        )}
                      </td>
                    ))}
                  </tr>
                  {wiim && (
                    <tr
                      onClick={() => history.push(link)}
                      css={css`
                        cursor: pointer;
                      `}
                    >
                      <td
                        colSpan={columns.length}
                        css={css`
                          padding: 0 ${spacing.milli} ${spacing.deci} ${spacing.milli};

                          @media (max-width: 359px) {
                            padding: 0 ${spacing.micro} ${spacing.centi} ${spacing.micro};
                          }
                        `}
                      >
                        <WiimPreview timestamp={wiim.created} text={wiim.title} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export * from './columns';
export * from './filterOptions';
