import { FC } from 'react';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { colors, fontFamilies, fontStyles, spacing } from '../../styles';
import { useNotificationPreferences } from '../../hooks/useNotificationPreferences';

export interface NotificationPreference {
  id: string;
  description: string;
  enabled: boolean;
}

const NotificationPreferences: FC = () => {
  const { preferences, handleToggle } = useNotificationPreferences();
  return (
    <div
      css={css`
        padding: ${spacing.regular};
        background-color: var(--background-color);
        min-height: 100vh;
      `}
    >
      <div
        css={[
          css`
            transition: all 0.3s ease;
            text-align: center;
            margin-top: ${spacing.giga};
            margin-bottom: ${spacing.hecto};
          `,
        ]}
      >
        <h1
          css={[
            css`
              font-size: 30px;
              font-weight: 500;
              line-height: 30px;
              margin-bottom: ${spacing.regular};
              color: var(--text-color);
              transition: all 0.3s ease;
              font-family: ${fontFamilies.helveticaNowText};
            `,
          ]}
        >
          Stay on top of trends
        </h1>
        <p
          css={css`
            ${fontStyles.dog};
            color: var(--secondary-text);
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            }
          `}
        >
          Enable notifications to get alerts for your watchlist
        </p>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing.centi};
          margin-bottom: ${spacing.hecto};
        `}
      >
        {preferences.map((pref) => (
          <div
            key={pref.id}
            css={css`
              padding: ${spacing.deci};
              background-color: var(--card-background);
              border-radius: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <p
                css={css`
                  ${fontStyles.dog};
                `}
              >
                {pref.description}
              </p>
              <label
                css={css`
                  position: relative;
                  display: inline-block;
                  width: 60px;
                  height: 34px;

                  input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                  }

                  span {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    transition: 0.4s;
                    border-radius: 34px;

                    &:before {
                      position: absolute;
                      content: '';
                      height: 26px;
                      width: 26px;
                      left: 4px;
                      bottom: 4px;
                      background-color: white;
                      transition: 0.4s;
                      border-radius: 50%;
                    }
                  }

                  input:checked + span {
                    background-color: ${colors.petrolBlue};
                  }

                  input:checked + span:before {
                    transform: translateX(26px);
                  }
                `}
              >
                <input type="checkbox" checked={pref.enabled} onChange={() => void handleToggle(pref.id)} />
                <span></span>
              </label>
            </div>
          </div>
        ))}
      </div>

      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: ${spacing.regular};
          background-color: ${colors.petrolBlue};
          margin-bottom: ${spacing.kilo};
          border-radius: ${spacing.milli};
          z-index: 2;
        `}
      >
        <NavLink
          exact
          to="/portfolio"
          css={css`
            display: block;
            text-align: center;
            padding: ${spacing.deci};
            color: ${colors.sugar};
            text-decoration: none;
            ${fontStyles.horse}
          `}
        >
          View my watchlist
        </NavLink>
      </div>
    </div>
  );
};

export default NotificationPreferences;
