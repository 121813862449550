import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import { css } from '@emotion/react';
import { Purchases, PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { useHistory } from 'react-router-dom';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import Lottie from 'lottie-react';
import { AppBar } from '../Components/AppBar';
import { OnboardingButton } from '../Components/OnboardingButton';
import { NotificationToggle } from '../Components/NotificationToggle';
import favorableSvg from '../../../components/RatingIndicator/defaultImages/favorable.svg';
import { colors, fontStyles, spacing } from '../../../styles';
import * as stores from '../../../stores';
import { entitlementIdentifier } from '../../../stores';
import DiscountModal from '../Components/DiscountModal';
import { UserSubscriptionService, SubscriptionType } from '../../../services/userSubscriptionService';
import SafeArea from '../../SafeArea';
import FlowerAnimation from './Flower.json';

export const Payment: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const offerings = stores.Purchase.useState((s) => s.offerings);
  const [selectedPackage, setSelectedPackage] = useState<PurchasesPackage>();
  const [subscriptionType, setSubscriptionType] = useState<'annual' | 'monthly'>('annual');
  const [notifyBeforeTrial, setNotifyBeforeTrial] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [feeDays] = useState(3);
  const history = useHistory();
  const [isDarkMode, setIsDarkMode] = useState(document.documentElement.getAttribute('data-theme') === 'dark');

  useEffect(() => {
    const handleThemeChange = () => {
      setIsDarkMode(document.documentElement.getAttribute('data-theme') === 'dark');
    };

    handleThemeChange();

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
          handleThemeChange();
        }
      });
    });

    observer.observe(document.documentElement, { attributes: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (offerings) {
      // Try to get "new_pricing" offering from offerings.all
      const newPricingOffering = offerings.all?.['new_pricing'];

      if (newPricingOffering) {
        console.log('Using new_pricing offering');
        // Find the package based on subscription type
        const packageType = subscriptionType === 'annual' ? 'ANNUAL' : 'MONTHLY';
        const selectedPkg = newPricingOffering.availablePackages.find((pkg) => pkg.packageType === packageType);

        if (selectedPkg) {
          console.log(`Using ${packageType} package from new_pricing offering`);
          setSelectedPackage(selectedPkg);
        } else {
          // Fallback to directly accessing properties if package not found
          setSelectedPackage(
            subscriptionType === 'annual'
              ? (newPricingOffering.annual ?? undefined)
              : (newPricingOffering.monthly ?? undefined),
          );
        }
      } else {
        console.log('new_pricing offering not found, using default offering');
        // Fallback to current default offering
        if (offerings.current) {
          // Find package by type from current offering
          const packageType = subscriptionType === 'annual' ? 'ANNUAL' : 'MONTHLY';
          const selectedPkg = offerings.current.availablePackages?.find((pkg) => pkg.packageType === packageType);

          if (selectedPkg) {
            console.log(`Using ${packageType} package from current offering`);
            setSelectedPackage(selectedPkg);
          } else {
            // Fallback to directly accessing properties
            setSelectedPackage(
              subscriptionType === 'annual'
                ? (offerings.current.annual ?? undefined)
                : (offerings.current.monthly ?? undefined),
            );
          }
        }
      }
    }
  }, [offerings, subscriptionType]);

  // Go to results page after successful payment
  const handleStartSubscription = () => {
    history.push('/result');
  };

  // Discount offer claimed
  const handleClaimOffer = () => {
    // Apply the discount and close the modal
    setSubscriptionType('annual');
    setShowDiscountModal(false);
    // Call handleStartTrial with discount parameters
    handleStartTrial(true, savingsPercentage);
    toast.success(`${savingsPercentage}% discount applied!`);
  };

  // render start trial
  const handleStartTrial = async (isDiscounted = false, discountPercentage = 0) => {
    if (!selectedPackage) {
      setErrorMessage('Subscription package not available. Please try again later.');
      toast.error('No subscription package available');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      if (Capacitor.isNativePlatform()) {
        // Create offering options with discount if applicable
        const purchaseOptions = { aPackage: selectedPackage };

        // Apply discount if needed
        if (isDiscounted && discountPercentage > 0) {
          // First check if there's a dedicated one_time_discount offering
          const oneTimeDiscountOffering = offerings?.all?.['one_time_discount'];
          const newPricingOffering = offerings?.all?.['new_pricing'];
          const currentOffering = offerings?.current;

          if (oneTimeDiscountOffering) {
            console.log('Using one_time_discount offering');
            // Prioritize the annual package in the one_time_discount offering
            if (oneTimeDiscountOffering.availablePackages && Array.isArray(oneTimeDiscountOffering.availablePackages)) {
              const annualPkg = oneTimeDiscountOffering.availablePackages.find((p: any) => p.packageType === 'ANNUAL');

              if (annualPkg) {
                purchaseOptions.aPackage = annualPkg;
                console.log(
                  `Applying annual package from one_time_discount offering: ${annualPkg.product?.title || annualPkg.identifier}`,
                );
                // Skip other discount options
                console.log('Found dedicated one_time_discount offering with annual package');
              } else if (oneTimeDiscountOffering.annual) {
                purchaseOptions.aPackage = oneTimeDiscountOffering.annual;
                console.log(
                  `Applying annual package from one_time_discount offering: ${oneTimeDiscountOffering.annual.product?.title || 'unnamed'}`,
                );
              }
            } else if (oneTimeDiscountOffering.annual) {
              purchaseOptions.aPackage = oneTimeDiscountOffering.annual;
              console.log(
                `Applying annual package from one_time_discount offering: ${oneTimeDiscountOffering.annual.product?.title || 'unnamed'}`,
              );
            }
          }
          // If no dedicated one_time_discount offering or no annual package found, try other strategies
          else {
            // Find discount package function
            const findDiscountPackage = (offering: any) => {
              if (!offering) return null;

              // First try to find one_time_discount in availablePackages
              if (offering.availablePackages && Array.isArray(offering.availablePackages)) {
                // Look for package with identifier 'one_time_discount'
                const discountPkg = offering.availablePackages.find(
                  (p: any) => p.identifier === 'one_time_discount' || p.identifier.includes('discount'),
                );
                if (discountPkg) return discountPkg;
              }

              // Then try direct property access
              if (offering.one_time_discount) return offering.one_time_discount;

              // Lastly, try packages with a specific discount percentage
              const discountPackageId = `discount_${discountPercentage}`;
              return offering[discountPackageId] || null;
            };

            // First try to find discount in new_pricing offering
            const newPricingDiscount = findDiscountPackage(newPricingOffering);

            // Then try current offering
            const currentDiscount = findDiscountPackage(currentOffering);

            // Use whichever discount we found
            const discountPackage = newPricingDiscount || currentDiscount;

            if (discountPackage) {
              purchaseOptions.aPackage = discountPackage;
              console.log(
                `Applying discount package: ${discountPackage.product?.title || discountPackage.identifier || 'unnamed'}`,
              );
            } else {
              // No specific discount package found, try promotional offers
              // These must be created first in App Store Connect or Google Play
              if (Capacitor.getPlatform() === 'ios') {
                console.log(`Applying iOS promotional offer with ${discountPercentage}% discount`);
                const discountIdentifier = '1y_30';
                (purchaseOptions as any).promotionalOffer = {
                  identifier: discountIdentifier,
                };
              } else if (Capacitor.getPlatform() === 'android') {
                console.log(`Applying Android offer code with ${discountPercentage}% discount`);
                (purchaseOptions as any).offerCode = `DISCOUNT_${discountPercentage}`;
              } else {
                console.log(
                  `Platform ${Capacitor.getPlatform()} does not support direct discounts, using regular package`,
                );
              }
            }
          }
        }

        const { customerInfo } = await Purchases.purchasePackage(purchaseOptions);

        if (customerInfo.entitlements.active[entitlementIdentifier]) {
          stores.Purchase.update((s) => {
            s.purchaserInfo = customerInfo;
          });

          // Register the subscription in our backend
          if (notifyBeforeTrial) {
            const subscriptionService = UserSubscriptionService.getInstance();
            const subscriptionResult = await subscriptionService.registerSubscription(SubscriptionType.free, feeDays);

            if (!subscriptionResult.success) {
              toast.error(`Error registering subscription: ${subscriptionResult.message}`);
            }
          }

          setShowDiscountModal(false);
          toast.success('Trial started successfully!');
          localStorage.setItem('hasCompletedOnboarding', 'true');
          handleStartSubscription();
        } else {
          throw new Error('Entitlement not active');
        }
      } else {
        // For web testing - simulate subscription
        console.log(`Web testing - applying discount: ${isDiscounted ? discountPercentage + '%' : 'no discount'}`);

        setTimeout(() => {
          setShowDiscountModal(false);
          toast.success('Trial started successfully!');
          localStorage.setItem('hasCompletedOnboarding', 'true');
          handleStartSubscription();
        }, 1000);
      }
    } catch (error: any) {
      if (error && error.code === '1') {
        // User cancelled the purchase, show discount modal
        console.log('User cancelled purchase, showing discount offer');
        setTimeout(() => {
          setShowDiscountModal(true);
        }, 500);
      } else {
        setErrorMessage('An error occurred while processing your subscription. Please try again.');

        toast.error(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // render restore purchases
  const handleRestorePurchases = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      if (Capacitor.isNativePlatform()) {
        const { customerInfo } = await Purchases.restorePurchases();

        if (customerInfo.entitlements.active[entitlementIdentifier]) {
          stores.Purchase.update((s) => {
            s.purchaserInfo = customerInfo;
          });
          toast.success('Purchase restored successfully!');

          // Set onboarding complete flag
          localStorage.setItem('hasCompletedOnboarding', 'true');

          // Move to next slide
          handleStartSubscription();
        } else {
          setErrorMessage('No active subscription found to restore.');
          toast.info('No active subscription found.');
        }
      } else {
        // For web testing
        setTimeout(() => {
          toast.info('Restore purchases is only available on mobile devices.');
        }, 500);
      }
    } catch (error) {
      console.error('Restore purchases error:', error);
      setErrorMessage('Failed to restore purchases. Please try again.');
      toast.error('Failed to restore purchases.');
    } finally {
      setIsLoading(false);
    }
  };

  // Enable notifications before start pyment
  const handleEnableNotifications = async () => {
    // Verificar y solicitar permisos si es necesario
    const { receive } = await FirebaseMessaging.checkPermissions();

    if (receive !== 'granted') {
      const permissionResult = await FirebaseMessaging.requestPermissions();
      if (permissionResult.receive !== 'granted') {
        toast.error('Failed to enable notifications. Please try again.');
        return;
      }
      setNotifyBeforeTrial(true);
    } else {
      setNotifyBeforeTrial(true);
    }
  };

  // Find the relevant offering - first try new_pricing, then fall back to current
  const newPricingOffering = offerings?.all?.['new_pricing'];
  const targetOffering = newPricingOffering || offerings?.current;

  // Function to find a package by type in an offering
  const findPackageByType = (offering: any, type: string) => {
    if (!offering) return null;

    // Try to find in availablePackages first
    if (offering.availablePackages && Array.isArray(offering.availablePackages)) {
      const pkg = offering.availablePackages.find((p: any) => p.packageType === type);
      if (pkg) return pkg;
    }

    // Fallback to direct properties
    if (type === 'ANNUAL' && offering.annual) return offering.annual;
    if (type === 'MONTHLY' && offering.monthly) return offering.monthly;
    return null;
  };

  // Get annual and monthly packages
  const annualPackage = findPackageByType(targetOffering, 'ANNUAL');
  const monthlyPackage = findPackageByType(targetOffering, 'MONTHLY');

  // Get pricing details
  const annualPrice = annualPackage?.product?.price || 60;
  const monthlyPrice = monthlyPackage?.product?.price || 10;

  // Calculate monthly equivalent for annual plan
  const monthlyEquivalent = Math.round((annualPrice / 12) * 100) / 100;

  // Check for a dedicated one_time_discount offering first
  const oneTimeDiscountOffering = offerings?.all?.['one_time_discount'];

  // Find discount package - try to get one_time_discount package
  const findDiscountPackage = (offering: any) => {
    if (!offering) return null;

    // Try to find in availablePackages first
    if (offering.availablePackages && Array.isArray(offering.availablePackages)) {
      const pkg = offering.availablePackages.find((p: any) => p.identifier === 'one_time_discount');
      if (pkg) return pkg;
    }

    // Fallback to direct property
    return offering.one_time_discount || null;
  };

  // First check if we can get annual package from one_time_discount offering
  let discountPackage = null;

  if (oneTimeDiscountOffering) {
    console.log('Found one_time_discount offering for pricing calculations');
    if (oneTimeDiscountOffering.availablePackages && Array.isArray(oneTimeDiscountOffering.availablePackages)) {
      const annualPkg = oneTimeDiscountOffering.availablePackages.find((p: any) => p.packageType === 'ANNUAL');
      if (annualPkg) {
        discountPackage = annualPkg;
      } else if (oneTimeDiscountOffering.annual) {
        discountPackage = oneTimeDiscountOffering.annual;
      }
    } else if (oneTimeDiscountOffering.annual) {
      discountPackage = oneTimeDiscountOffering.annual;
    }
  }

  // Fallback to finding discount package in the target offering if not found
  if (!discountPackage) {
    discountPackage = findDiscountPackage(targetOffering);
  }

  // Calculate discount price
  const discountMonthlyPrice = discountPackage?.product?.price
    ? Math.round((discountPackage.product.price / 12) * 100) / 100
    : 2.5; // Special discount price monthly equivalent

  // Calculate savings percentage
  const savingsPercentage = Math.round(((annualPrice - discountMonthlyPrice * 12) / annualPrice) * 100);

  const benefits = [
    'Understand why stock prices move',
    'Create expert portfolios for any strategy',
    'Backtest and simulate portfolio return',
    'Get alerts for price changes and big events',
  ];

  return (
    <SafeArea>
      <div
        css={css`
          width: 100%;
          max-width: 1024px;
          height: 100%;
          margin: 0 auto;
          position: relative;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          padding-bottom: ${spacing.kilo}; /* Add padding to prevent content from being hidden behind bottom section */
        `}
      >
        {/* AppBar */}
        <div
          css={css`
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 10;
            background-color: ${isDarkMode ? colors.liquorice : '#fffefc'};
            padding-top: 0;
            /* Consistent platform-specific padding */
            padding-top: ${Capacitor.isNativePlatform() ? (Capacitor.getPlatform() === 'ios' ? '0' : '24px') : '24px'};

            /* Center and limit width for large screens */
            @media (min-width: 1024px) {
              width: 1024px;
              margin: 0 auto;
              left: 0;
              right: 0;
              transform: none;
            }
          `}
        >
          <AppBar
            onBack={() => history.goBack()}
            showBackButton={true}
            showRightButton={true}
            rightButtonText="Restore"
            onRightButtonClick={() => handleRestorePurchases()}
          />
        </div>

        {/* Top section: Title, image, and benefits (scrollable) */}
        <div
          css={css`
            padding: ${spacing.deci};
            padding-top: ${spacing.deci};
            padding-bottom: ${Capacitor.isNativePlatform()
              ? Capacitor.getPlatform() === 'ios'
                ? spacing.deci
                : spacing.milli
              : spacing.deci};
            flex: 1;
            overflow-y: auto;
          `}
        >
          {/* Title */}
          <h1
            css={css`
              ${fontStyles.shark};
              color: ${colors.liquorice};
              margin-bottom: ${spacing.deci};
              font-size: 30px;
              text-align: center;
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            Try Bloom AI for free
          </h1>

          {/* Image and text group */}
          <div
            css={css`
              margin-bottom: 16px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-bottom: 8px;
              `}
            >
              <Lottie
                animationData={FlowerAnimation}
                alt="Subscription"
                autoplay={true}
                loop={false}
                css={css`
                  width: 22%;
                  height: auto;
                  max-width: 120px;

                  /* Increase size for medium and large screens */
                  @media (min-width: 768px) {
                    width: 30%;
                    max-width: 180px;
                  }

                  /* Further increase for larger screens */
                  @media (min-width: 1024px) {
                    width: 35%;
                    max-width: 220px;
                  }
                `}
              />
            </div>

            <p
              css={css`
                ${fontStyles.shark};
                color: ${colors.liquorice};
                text-align: center;
                margin-bottom: ${spacing.deci};
                font-size: 20px;
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Join 500+ members
            </p>
          </div>

          {/* Benefits list */}
          <div
            css={css`
              width: 100%;
              max-width: 340px;
              margin: 0 auto 16px auto;
              padding: 0 8px;
            `}
          >
            {benefits.map((feature, index) => (
              <div
                key={index}
                css={css`
                  display: flex;
                  align-items: center;
                  margin-bottom: ${spacing.deci};
                `}
              >
                <div
                  css={css`
                    width: 20px;
                    height: 20px;
                    margin-right: ${spacing.milli};
                    flex-shrink: 0;
                  `}
                >
                  <img
                    src={favorableSvg}
                    alt="Checkmark"
                    css={css`
                      width: 100%;
                      height: 100%;
                    `}
                  />
                </div>
                <p
                  css={css`
                    ${fontStyles.cat};
                    color: ${colors.liquorice};
                    margin: 0;
                    flex: 1;
                    line-height: 1.2;
                    text-align: left;
                    html[data-theme='dark'] & {
                      color: ${colors.sugar};
                    }
                  `}
                >
                  {feature}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom section: Plans, toggle, and button (fixed position at the bottom) */}
        <div
          css={css`
            padding: ${spacing.deci};
            background-color: ${isDarkMode ? colors.liquorice : '#fffefc'};
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            padding-bottom: ${Capacitor.isNativePlatform()
              ? `calc(${spacing.deci} + env(safe-area-inset-bottom, 0px))`
              : spacing.deci};
            box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);

            /* Center and limit width for large screens */
            @media (min-width: 1024px) {
              width: 1024px;
              left: 50%;
              transform: translateX(-50%);
            }
          `}
        >
          {/* Notification toggle */}
          {subscriptionType === 'annual' && (
            <div
              css={css`
                margin-bottom: 16px;
              `}
            >
              <NotificationToggle
                isEnabled={notifyBeforeTrial}
                onToggle={() => (notifyBeforeTrial ? setNotifyBeforeTrial(false) : handleEnableNotifications())}
              />
            </div>
          )}
          {/* Payment plans */}
          <div
            css={css`
              display: flex;
              gap: 16px;
              margin-bottom: 16px;
            `}
          >
            {/* Monthly plan */}
            <div
              css={css`
                display: flex;
                flex: 1;
                border-radius: 8px;
                border: 2px solid var(--cloud, ${subscriptionType === 'monthly' ? colors.petrolBlue : colors.cloud});
                background: ${isDarkMode ? colors.liquorice : colors.sugar};
                box-shadow: 0px 2px 3px 0px rgba(203, 202, 201, 0.5);
                padding: 16px;
                cursor: pointer;
                transition: all 0.2s ease;
                position: relative;
                justify-content: space-between;
                align-items: center;
              `}
              onClick={() => setSubscriptionType('monthly')}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                `}
              >
                <h3
                  css={css`
                    ${fontStyles.cat};
                    color: ${isDarkMode ? colors.sugar : colors.liquorice};
                    margin: 0 0 4px 0;
                  `}
                >
                  Monthly
                </h3>
                <p
                  css={css`
                    margin: 0;
                    ${fontStyles.dog};
                    color: ${isDarkMode ? colors.sugar : colors.liquorice};
                  `}
                >
                  ${monthlyPrice}/mo
                </p>
              </div>

              <div
                css={css`
                  width: 24px;
                  height: 24px;
                  border: 2px solid ${subscriptionType === 'monthly' ? colors.petrolBlue : colors.cloud};
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: ${subscriptionType === 'monthly' ? colors.petrolBlue : 'white'};
                `}
              >
                {subscriptionType === 'monthly' && (
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="white" />
                  </svg>
                )}
              </div>
            </div>
            {/* Annual plan */}
            <div
              css={css`
                display: flex;
                flex: 1;
                border-radius: 8px;
                border: 2px solid var(--cloud, ${subscriptionType === 'annual' ? colors.petrolBlue : colors.cloud});
                background: ${isDarkMode ? colors.liquorice : colors.sugar};
                box-shadow: 0px 2px 3px 0px rgba(203, 202, 201, 0.5);
                padding: 16px;
                cursor: pointer;
                transition: all 0.2s ease;
                position: relative;
                justify-content: space-between;
                align-items: center;
              `}
              onClick={() => setSubscriptionType('annual')}
            >
              <div
                css={css`
                  position: absolute;
                  top: -12px;
                  left: 0;
                  right: 0;
                  display: flex;
                  justify-content: center;
                `}
              >
                <div
                  css={css`
                    background-color: ${colors.petrolBlue};
                    color: white;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 4px 12px;
                    border-radius: 20px;
                    text-transform: uppercase;
                  `}
                >
                  {feeDays} days free
                </div>
              </div>

              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                `}
              >
                <h3
                  css={css`
                    margin: 0 0 4px 0;
                    ${fontStyles.cat};
                    color: ${isDarkMode ? colors.sugar : colors.liquorice};
                  `}
                >
                  Yearly
                </h3>
                <p
                  css={css`
                    margin: 0;
                    ${fontStyles.dog};
                    color: ${isDarkMode ? colors.sugar : colors.liquorice};
                  `}
                >
                  ${monthlyEquivalent}/mo
                </p>
              </div>

              <div
                css={css`
                  width: 24px;
                  height: 24px;
                  border: 2px solid ${subscriptionType === 'annual' ? colors.petrolBlue : colors.cloud};
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: ${subscriptionType === 'annual' ? colors.petrolBlue : 'white'};
                `}
              >
                {subscriptionType === 'annual' && (
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="white" />
                  </svg>
                )}
              </div>
            </div>
          </div>

          {/* Button and price text */}
          <div>
            <OnboardingButton
              onClick={() => {
                // For web testing, go directly to results
                if (!Capacitor.isNativePlatform()) {
                  toast.success('Trial started successfully!');
                  localStorage.setItem('hasCompletedOnboarding', 'true');
                  history.push('/result');
                } else {
                  // For native devices, start the normal process
                  handleStartTrial(false, 0);
                }
              }}
              disabled={isLoading}
            >
              {isLoading
                ? 'Loading...'
                : subscriptionType === 'annual'
                  ? 'Start my 3-day free trial'
                  : 'Start investing smarter'}
            </OnboardingButton>

            <p
              css={css`
                ${fontStyles.dog};
                color: ${colors.silver};
                text-align: center;
                font-size: 14px;
                margin-top: 8px;
                html[data-theme='dark'] & {
                  color: ${colors.silver};
                }
              `}
            >
              {subscriptionType === 'annual'
                ? `${feeDays} days free, then $${annualPrice} per year ($${monthlyEquivalent}/mo)`
                : `Just $${monthlyPrice} per month`}
            </p>
          </div>
        </div>

        {/* Discount Modal */}
        {showDiscountModal && (
          <DiscountModal
            onClose={() => setShowDiscountModal(false)}
            handleClaimOffer={handleClaimOffer}
            discountMonthlyPrice={discountMonthlyPrice}
            discountPercentage={savingsPercentage}
          />
        )}
      </div>
    </SafeArea>
  );
};
