import { FC, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { css } from '@emotion/react';
import { Link, useHistory } from 'react-router-dom';
import { formatDistanceToNowStrict, sub } from 'date-fns';
import { colors, spacing, fontStyles } from '../../styles';
import Page from '../Page';
import * as stores from '../../stores';
import * as storage from '../../storage';
import { getWiims, getBasicInvestments, BasicInvestment, getAllInsights, Insight, Wiim } from '../../apis/backend';
import lock from '../CollectionCard/lock.svg';
import ColoredPercentChange from '../ColoredPercentChange';
import { TimeRange } from '../../types';

// Define the 3 types of content for the News page
enum ContentType {
  WIIMs = 'My Portfolio',
  EarningsCalls = 'Earnings Calls',
  TopNews = 'Top News',
}

// List of popular symbols for "Top News"
const POPULAR_SYMBOLS = [
  'AAPL',
  'GOOGL',
  'AMZN',
  'MSFT',
  'TSLA',
  'META',
  'NFLX',
  'NVDA',
  'VOO',
  'QQQ',
  'BRK.B',
  'SPOT',
  'IBIT',
  'V',
];

// Function to determine if a WIIM item is an Earnings Call
const isEarningsCall = (wiim: Wiim): boolean => {
  const lowerTitle = wiim.title.toLowerCase();
  return (
    lowerTitle.includes('earnings call') ||
    lowerTitle.includes('quarterly results') ||
    lowerTitle.includes('financial results') ||
    lowerTitle.includes('earnings report') ||
    lowerTitle.includes('earnings release')
  );
};

interface NewsItem {
  title: string;
  symbol: string;
  created: string;
  articleDate?: string;
  changePercent?: number;
  isEarningsRelease?: boolean;
  fileUrl?: string;
  summary?: string;
  insightId?: number;
}

// Reusable components for news items
interface NewsItemHeaderProps {
  displayDate: string;
  symbol: string;
  isLocked: boolean;
  isEarningsItem: boolean;
  getChangePercent: (date: string, symbol: string) => number | undefined;
  getTimeRange: (date: string) => TimeRange;
}

const NewsItemHeader: FC<NewsItemHeaderProps> = ({
  displayDate,
  symbol,
  isLocked,
  isEarningsItem,
  getChangePercent,
  getTimeRange,
}) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      gap: ${spacing.centi};
      margin-bottom: ${spacing.milli};
    `}
  >
    <span
      css={css`
        color: ${colors.sugar};
        padding: ${spacing.milli} 0px;
        border-radius: 4px;
        ${fontStyles.flea}
        display: flex;
        align-items: center;
        gap: ${spacing.milli};
      `}
    >
      <ColoredPercentChange
        value={getChangePercent(displayDate, symbol)}
        timeRange={getTimeRange(displayDate)}
        prefix={symbol}
      />
      {(isLocked || (isEarningsItem && !isLocked)) && (
        <div
          css={css`
            background: ${colors.petrolBlue};
            color: ${colors.sugar};
            padding: 2px ${spacing.milli};
            border-radius: 4px;
            ${fontStyles.flea}
            text-transform: uppercase;
            letter-spacing: 0.5px;
            ${isLocked ? 'width: 3.5rem;' : ''}
          `}
        >
          {isLocked && (
            <img
              src={lock}
              alt="Locked"
              css={css`
                width: 12px;
                height: 12px;
                filter: brightness(0) invert(1);
                vertical-align: middle;
                top: -1px;
                margin-right: 1px;
                position: relative;
              `}
            />
          )}
          {isLocked ? 'Pro' : 'Earnings'}
        </div>
      )}
    </span>
    <span
      css={css`
        color: ${colors.silver};
        ${fontStyles.flea}
      `}
    >
      {formatDistanceToNowStrict(new Date(displayDate))} ago
    </span>
  </div>
);

const PlaceholderContent: FC = () => (
  <div
    css={css`
      margin: 0;
      ${fontStyles.cat}
      color: ${colors.liquorice};
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `}
  >
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
      `}
    >
      {/* Create multiple blocks to simulate text lines */}
      {[...Array(3)].map((_, i) => (
        <div
          key={i}
          css={css`
            height: 1em;
            width: ${i === 2 ? '60%' : '100%'};
            background-color: ${colors.cloud};
            border-radius: 4px;
          `}
        />
      ))}
    </div>
  </div>
);

interface NewsContentProps {
  item: NewsItem;
}

const NewsContent: FC<NewsContentProps> = ({ item }) => (
  <>
    <p
      css={css`
        margin: 0;
        ${fontStyles.thinFlea}
        color: ${colors.liquorice};
        html[data-theme='dark'] & {
          color: ${colors.sugar};
        }
      `}
    >
      {item.title}
    </p>
    {item.summary && (
      <p
        css={css`
          margin: ${spacing.milli} 0 0 0;
          ${fontStyles.fairyfly}
          color: ${colors.tungsten};
          html[data-theme='dark'] & {
            color: ${colors.fog};
          }
        `}
      >
        {item.summary}
      </p>
    )}
  </>
);

interface NewsListItemProps {
  item: NewsItem;
  isSubscribed: boolean;
  getChangePercent: (date: string, symbol: string) => number | undefined;
  getTimeRange: (date: string) => TimeRange;
  isNewsItemLocked: (date: string) => boolean;
  handleItemClick: (item: NewsItem) => void;
  handleLockedItemClick: () => void;
  contentType: ContentType;
}

const NewsListItem: FC<NewsListItemProps> = ({
  item,
  isSubscribed,
  getChangePercent,
  getTimeRange,
  isNewsItemLocked,
  handleItemClick,
  handleLockedItemClick,
  contentType,
}) => {
  const displayDate = item.articleDate || item.created;
  const isLocked = isNewsItemLocked(displayDate);
  const isEarningsItem = item.isEarningsRelease || contentType === ContentType.EarningsCalls;
  const shouldLockContent = isLocked && !isSubscribed;

  const handleClick = () => {
    if (shouldLockContent) {
      handleLockedItemClick();
    } else {
      handleItemClick(item);
    }
  };

  return (
    <li
      css={css`
        padding: ${spacing.deci} 0;
        border-bottom: 1px solid ${colors.cloud};
        &:last-child {
          border-bottom: none;
        }
        html[data-theme='dark'] & {
          border-color: ${colors.tungsten};
        }
      `}
    >
      <div
        onClick={handleClick}
        css={css`
          text-decoration: none;
          display: block;
          cursor: pointer;
          &:hover {
            background-color: ${colors.fog};
            html[data-theme='dark'] & {
              background-color: ${colors.tungsten};
            }
          }
        `}
      >
        <NewsItemHeader
          displayDate={displayDate}
          symbol={item.symbol}
          isLocked={shouldLockContent}
          isEarningsItem={isEarningsItem && !shouldLockContent}
          getChangePercent={getChangePercent}
          getTimeRange={getTimeRange}
        />

        {shouldLockContent ? <PlaceholderContent /> : <NewsContent item={item} />}
      </div>
    </li>
  );
};

const NewsPage: FC = () => {
  const history = useHistory();
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [contentType, setContentType] = useState<ContentType>(ContentType.WIIMs);
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const [investmentData, setInvestmentData] = useState<Record<string, BasicInvestment>>({});
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  // Get all portfolios from the store
  const portfolios = storage.Portfolios.useState((s) => s.portfolios);

  // Pagination state
  const [page, setPage] = useState<number>(0); // API uses 0-indexed pages
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const PAGE_SIZE = 20;

  // Reference to the observer element for infinite scroll
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  // Compute symbols based on filters
  const getSymbols = useMemo(() => {
    if (contentType === ContentType.TopNews) {
      return POPULAR_SYMBOLS;
    } else {
      // Union of all unique symbols across all portfolios
      const allSymbols = Array.from(new Set(portfolios.flatMap((p) => p.symbols || [])));
      return allSymbols;
    }
  }, [contentType, portfolios]);

  // Load more data function
  const loadMoreData = useCallback(() => {
    if (!hasNextPage || isLoadingMore) return;

    setIsLoadingMore(true);
    setPage((prevPage) => prevPage + 1);
  }, [hasNextPage, isLoadingMore]);

  // Setup Intersection Observer for infinite scrolling
  useEffect(() => {
    // Disconnect previous observer if it exists
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Create new observer for infinite scroll
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && hasNextPage && !isLoadingMore && !isLoading) {
          // Load more data when the observer element is visible
          loadMoreData();
        }
      },
      { threshold: 0.5 },
    );

    // Observe the loading element if it exists
    if (loadMoreRef.current) {
      observerRef.current.observe(loadMoreRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [hasNextPage, isLoadingMore, isLoading, loadMoreData]);

  const isNewsItemLocked = (date: string): boolean => {
    const twoDaysAgo = sub(new Date(), { days: 2 });
    return new Date(date) < twoDaysAgo;
  };

  const handleLockedItemClick = () => {
    stores.General.update((s) => {
      s.isSubscriptionTakeoverShown = true;
    });
  };

  // Reset pagination when content type changes
  useEffect(() => {
    setPage(0);
    setNewsItems([]);
    setHasNextPage(true);
    setIsLoading(true);
  }, [contentType]);

  // Main data fetching effect
  useEffect(() => {
    let isMounted = true;
    const isFirstPage = page === 0;
    const currentLoadingState = isFirstPage ? setIsLoading : setIsLoadingMore;

    const fetchData = async () => {
      try {
        currentLoadingState(true);

        if (contentType === ContentType.EarningsCalls) {
          // For Earnings filter, get all insights with pagination
          const insightsResponse = await getAllInsights(
            page + 1, // getAllInsights is 1-indexed
            getSymbols.length > 0 ? getSymbols.join(',') : undefined,
          );

          if (!isMounted) return;

          const formattedInsights: NewsItem[] = insightsResponse.results.map((insight: Insight) => ({
            title: insight.title,
            symbol: insight.investment,
            created: insight.created,
            articleDate: insight.articleDate,
            isEarningsRelease: true,
            fileUrl: insight.fileUrl,
            summary: insight.summary,
            insightId: insight.id,
          }));

          // Update pagination state for insights
          setHasNextPage(!!insightsResponse.next);

          const insightSymbols = Array.from(new Set(formattedInsights.map((item) => item.symbol)));
          const investmentResponse = await getBasicInvestments(insightSymbols);

          if (isMounted) {
            setNewsItems((prev) => (isFirstPage ? formattedInsights : [...prev, ...formattedInsights]));
            setInvestmentData((prev) => ({
              ...prev,
              ...investmentResponse.reduce(
                (acc, item) => ({
                  ...acc,
                  [item.symbol]: item,
                }),
                {},
              ),
            }));
          }
        } else {
          // For WIIMs and Top News, use getWiims
          // Skip fetching if no symbols and not using Top News
          if (getSymbols.length === 0 && contentType !== ContentType.TopNews) {
            setNewsItems([]);
            currentLoadingState(false);
            setHasNextPage(false);
            return;
          }

          // Use the paginated API
          const newsResponse = await getWiims(getSymbols, {
            page: page,
            pageSize: PAGE_SIZE,
          });

          if (!isMounted) return;

          // Extract news data from the API response
          const newsData = newsResponse.results;
          const newsSymbols = Array.from(new Set(Object.keys(newsData)));
          const investmentResponse = await getBasicInvestments(newsSymbols);

          const formattedNews: NewsItem[] = [];
          Object.entries(newsData).forEach(([symbol, items]) => {
            items.forEach((item) => {
              // For Earnings Calls filter type, only include items that match the earnings criteria
              if ((contentType as ContentType) === ContentType.EarningsCalls && !isEarningsCall(item)) {
                return;
              }

              formattedNews.push({
                ...item,
                symbol,
              });
            });
          });

          // Sort by date
          formattedNews.sort((a, b) => {
            const dateA = new Date(a.created);
            const dateB = new Date(b.created);
            return dateB.getTime() - dateA.getTime();
          });

          // Update hasNextPage based on the pagination info from the API
          setHasNextPage(newsResponse.pagination.hasMore);

          if (isMounted) {
            // Append new items if not the first page, otherwise replace
            setNewsItems((prev) => (isFirstPage ? formattedNews : [...prev, ...formattedNews]));
            setInvestmentData((prev) => ({
              ...prev,
              ...investmentResponse.reduce(
                (acc, item) => ({
                  ...acc,
                  [item.symbol]: item,
                }),
                {},
              ),
            }));
          }
        }
      } catch (error) {
        console.error('Failed to load news:', error);
        if (isMounted) {
          setHasNextPage(false);
          // Skip updating the store as we don't know its exact structure
        }
      } finally {
        if (isMounted) {
          currentLoadingState(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [contentType, getSymbols, page]);

  // Helper function to get the appropriate change percent based on article date
  const getChangePercent = (created: string, symbol: string): number | undefined => {
    const investment = investmentData[symbol];
    if (!investment) return undefined;

    const articleDate = new Date(created);
    const now = new Date();
    const daysDiff = Math.floor((now.getTime() - articleDate.getTime()) / (1000 * 60 * 60 * 24));

    if (daysDiff <= 1) return investment.changePercent1d;
    if (daysDiff <= 7) return investment.changePercent1w;
    if (daysDiff <= 30) return investment.changePercent1m;
    if (daysDiff <= 90) return investment.changePercent3m;
    return investment.changePercent1y;
  };

  const getTimeRange = (created: string): TimeRange => {
    const daysDiff = Math.floor((new Date().getTime() - new Date(created).getTime()) / (1000 * 60 * 60 * 24));
    if (daysDiff <= 1) return TimeRange.days1;
    if (daysDiff <= 7) return TimeRange.weeks1;
    if (daysDiff <= 30) return TimeRange.months1;
    if (daysDiff <= 90) return TimeRange.months3;
    return TimeRange.years1;
  };

  const handleItemClick = (item: NewsItem): void => {
    if (item.isEarningsRelease) {
      const dateToCheck = item.articleDate || item.created;
      if (isNewsItemLocked(dateToCheck) && !isSubscribed) {
        handleLockedItemClick();
      } else {
        // Navigate to the symbol page's insights section
        history.push(`/symbol/${item.symbol}/insights`);
      }
    } else {
      // For regular news items
      if (isNewsItemLocked(item.created) && !isSubscribed) {
        handleLockedItemClick();
      } else {
        // Navigate to the symbol page
        history.push(`/symbol/${item.symbol}`);
      }
    }
  };

  return (
    <Page showNavigationBar>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing.centi};
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${spacing.milli};
          `}
        >
          <h1
            css={css`
              color: ${colors.liquorice};
              margin: 0 0 ${spacing.centi} 0;
              ${fontStyles.shark}
              html[data-theme='dark'] & {
                color: ${colors.sugar};
              }
            `}
          >
            News
          </h1>
          {isSubscribed && (
            <div
              css={css`
                background: ${colors.petrolBlue};
                color: ${colors.sugar};
                padding: 2px ${spacing.milli};
                border-radius: 4px;
                ${fontStyles.flea}
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin-bottom: ${spacing.centi};
                html[data-theme='dark'] & {
                  background: ${colors.skyBlue};
                }
              `}
            >
              Pro
            </div>
          )}
        </div>

        {/* Content Type Selector */}
        <div
          css={css`
            display: flex;
            gap: ${spacing.centi};
            flex-wrap: wrap;
          `}
        >
          {Object.values(ContentType).map((type: ContentType) => (
            <button
              key={type}
              onClick={() => setContentType(type)}
              css={css`
                padding: ${spacing.milli} ${spacing.centi};
                border-radius: 999px;
                border: 1px solid ${colors.petrolBlue};
                background: ${contentType === type ? colors.petrolBlue : 'transparent'};
                color: ${contentType === type ? colors.sugar : colors.petrolBlue};
                cursor: pointer;
                ${fontStyles.fairyfly}
                transition: all 0.2s ease;
                &:hover {
                  background: ${contentType === type ? colors.petrolBlue : 'rgba(58, 143, 146, 0.1)'};
                }
                html[data-theme='dark'] & {
                  border-color: ${contentType === type ? colors.skyBlue : colors.skyBlue};
                  background: ${contentType === type ? colors.skyBlue : 'transparent'};
                  color: ${contentType === type ? colors.sugar : colors.skyBlue};
                  &:hover {
                    background: ${contentType === type ? colors.skyBlue : 'rgba(91, 193, 241, 0.1)'};
                  }
                }
              `}
            >
              {type}
            </button>
          ))}
        </div>

        {!isSubscribed && (
          <p
            css={css`
              ${fontStyles.flea}
              line-height: 1.19rem;
              color: ${colors.silver};
              text-align: left;
              padding-top: ${spacing.centi};
            `}
          >
            News older than 48 hours and earnings summaries require a subscription to view.{' '}
            <Link
              to="#"
              onClick={() => {
                stores.General.update((s) => {
                  s.isSubscriptionTakeoverShown = true;
                });
              }}
              css={css`
                color: ${colors.petrolBlue};
                text-decoration: none;
                html[data-theme='dark'] & {
                  color: ${colors.skyBlue};
                }
              `}
            >
              Click here to subscribe
            </Link>
            .
          </p>
        )}

        {isLoading ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: ${spacing.centi};
            `}
          >
            Loading...
          </div>
        ) : getSymbols.length === 0 && (contentType as ContentType) !== ContentType.TopNews ? (
          <p
            css={css`
              ${fontStyles.cat}
              color: ${colors.tungsten};
              text-align: center;
              margin-top: ${spacing.centi};
            `}
          >
            {contentType !== ContentType.TopNews
              ? 'No stocks found in your portfolios'
              : 'No stocks found for the selected filters'}
          </p>
        ) : newsItems.length === 0 ? (
          <p
            css={css`
              ${fontStyles.cat}
              color: ${colors.tungsten};
              text-align: center;
              margin-top: ${spacing.centi};
            `}
          >
            No news available for the selected filters
          </p>
        ) : (
          <>
            <ul
              css={css`
                list-style: none;
                padding: 0;
                margin: 0;
              `}
            >
              {newsItems.map((item, index) => (
                <NewsListItem
                  key={`${item.symbol}-${index}`}
                  item={item}
                  isSubscribed={!!isSubscribed}
                  getChangePercent={getChangePercent}
                  getTimeRange={getTimeRange}
                  isNewsItemLocked={isNewsItemLocked}
                  handleItemClick={handleItemClick}
                  handleLockedItemClick={handleLockedItemClick}
                  contentType={contentType}
                />
              ))}
            </ul>

            {/* Observer element for infinite scroll */}
            <div
              ref={loadMoreRef}
              css={css`
                height: 20px;
                width: 100%;
                margin: ${spacing.centi} 0;
              `}
            />

            {/* Loading more indicator */}
            {isLoadingMore && (
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin: ${spacing.centi} 0;
                  padding-bottom: ${spacing.centi};
                `}
              >
                Loading more...
              </div>
            )}

            {/* End of list message */}
            {!hasNextPage && newsItems.length > 0 && !isLoadingMore && (
              <p
                css={css`
                  ${fontStyles.fairyfly}
                  color: ${colors.tungsten};
                  text-align: center;
                  margin: ${spacing.centi} 0;
                  padding-bottom: ${spacing.centi};
                `}
              >
                No more news to load
              </p>
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default NewsPage;
