import { css } from '@emotion/react';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
  const percentage = ((currentStep + 1) / (totalSteps + 1)) * 100;

  return (
    <div
      css={css`
        flex: 1;
        height: 8px;
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 8px;
          background-color: #e9eaeb;
          border-radius: 4px;
          position: relative;
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: ${percentage}%;
            background-color: #118383;
            border-radius: 4px;
            transition: width 0.3s ease;
          `}
        ></div>
      </div>
    </div>
  );
};
