import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { Capacitor } from '@capacitor/core';
import { colors } from '../../styles';

type SafeAreaProps = PropsWithChildren<{
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
  backgroundColor?: string;
}>;

const SafeArea: React.FC<SafeAreaProps> = ({
  children,
  top = true,
  bottom = true,
  left = true,
  right = true,
  backgroundColor,
}) => {
  const isNative = Capacitor.isNativePlatform();
  const platform = Capacitor.getPlatform();

  return (
    <div
      css={css`
        /* Base container styles */
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: ${backgroundColor || 'var(--background-color, #fffefc)'};
        position: relative;
        overflow: hidden;

        /* Common safe area insets for all platforms */
        padding-top: ${top ? (isNative ? 'env(safe-area-inset-top, 0px)' : '0px') : '0'};
        padding-bottom: ${bottom ? (isNative ? 'env(safe-area-inset-bottom, 0px)' : '0px') : '0'};
        padding-left: ${left ? (isNative ? 'env(safe-area-inset-left, 0px)' : '0px') : '0'};
        padding-right: ${right ? (isNative ? 'env(safe-area-inset-right, 0px)' : '0px') : '0'};

        /* Platform specific adjustments */
        ${platform === 'ios'
          ? `
          /* iOS specific adjustments */
          `
          : platform === 'android'
            ? `
          /* Android specific adjustments */
          padding-top: ${top ? '8px' : '0'};
          `
            : `
          /* Web specific adjustments */
          padding-top: ${top ? '0px' : '0'};
          `}

        /* Dark mode support */
        html[data-theme='dark'] & {
          background-color: ${backgroundColor || colors.liquorice};
        }
      `}
    >
      {children}
    </div>
  );
};

export default SafeArea;
