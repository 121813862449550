import { css } from '@emotion/react';
import { FC } from 'react';
import { fontStyles, borderRadius } from '../../styles';
import marker from './marker.svg';

interface Props {
  highPrice: number;
  lowPrice: number;
  markedPrice: number;
}

const formatPrice = (price: number): string => {
  if (price < 1) return `$${price.toFixed(1)}`;
  if (price < 1000) return `$${price.toFixed(0)}`;
  if (price < 100000) return `$${(price / 1000).toFixed(1)}K`;
  return `$${(price / 1000).toFixed(0)}\u200AK`;
};

const calculatePricePercent = (highPrice: number, lowPrice: number, markedPrice: number) => {
  if (markedPrice > highPrice) return 100;
  if (markedPrice < lowPrice) return 0;
  return ((markedPrice - lowPrice) / (highPrice - lowPrice)) * 100;
};

const PriceRange: FC<Props> = ({ highPrice, lowPrice, markedPrice }) => {
  const pricePercent = calculatePricePercent(highPrice, lowPrice, markedPrice);
  const styles = {
    base: css`
      width: 100%;
      color: var(--text-color);
      ${fontStyles.roboMite};
    `,
    prices: css`
      display: flex;
      justify-content: space-between;
      margin-top: 4px;
    `,
    line: css`
      flex: 1;
      background-color: var(--border-color);
      border-radius: ${borderRadius};
      height: 4px;
      position: relative;
    `,
    marker: css`
      width: 12px;
      position: absolute;
      top: -4px;
      left: calc(${pricePercent}% - 6px);
      filter: var(--icon-filter);
    `,
  };

  return (
    <div css={styles.base}>
      <div css={styles.line}>
        <img src={marker} css={styles.marker} />
      </div>
      <div css={styles.prices}>
        <span>{formatPrice(lowPrice)}</span>
        <span>{formatPrice(highPrice)}</span>
      </div>
    </div>
  );
};

export default PriceRange;
