import { useEffect, useRef } from 'react';
import { css, SerializedStyles } from '@emotion/react';

interface AutoGrowTextareaProps {
  id?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  minHeight?: number;
  className?: string | SerializedStyles;
  style?: React.CSSProperties;
}

const AutoGrowTextarea: React.FC<AutoGrowTextareaProps> = ({
  id,
  value,
  onChange,
  placeholder = '',
  minHeight = 80,
  className,
  style = {},
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Adjust height on mount and when value changes
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, minHeight)}px`;
    }
  }, [value, minHeight]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
    // Height will be adjusted by the useEffect above
  };

  const textareaStyles = css`
    width: 100%;
    min-height: ${minHeight}px;
    height: auto;
    overflow-y: hidden;
  `;

  return (
    <textarea
      ref={textareaRef}
      id={id}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      css={[textareaStyles, className]}
      style={style}
    />
  );
};

export default AutoGrowTextarea;
