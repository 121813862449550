import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { userSubscription } from '../apis/backend';

export enum SubscriptionType {
  premium = 'premium',
  free = 'free',
}

interface SubscriptionResponse {
  success: boolean;
  message: string;
  subscriptionId?: string;
}

export class UserSubscriptionService {
  private static instance: UserSubscriptionService;

  public static getInstance(): UserSubscriptionService {
    if (!UserSubscriptionService.instance) {
      UserSubscriptionService.instance = new UserSubscriptionService();
    }
    return UserSubscriptionService.instance;
  }

  /**
   * Registers the user's subscription
   * @param type Subscription type (PREMIUM or FREE)
   * @param feeDays Free trial days
   * @returns Promise with the server response
   */
  public async registerSubscription(type: SubscriptionType, feeDays: number): Promise<SubscriptionResponse> {
    try {
      // Get the FCM token
      const { token } = await this.getFCMToken();

      if (!token) {
        throw new Error('No se pudo obtener el token FCM');
      }

      // Call the backend to register the subscription
      const response = await userSubscription({
        subscription_type: type,
        user: token,
        expiration_days: feeDays,
      });

      return {
        success: true,
        message: 'Subscription registered successfully',
        subscriptionId: response.subscriptionId,
      };
    } catch (error) {
      console.error('Error registering subscription:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Unknown error registering subscription',
      };
    }
  }

  /**
   * Gets the FCM token from the device
   * @returns Promise with the FCM token
   */
  private async getFCMToken(): Promise<{ token: string }> {
    try {
      // Get the FCM token
      const { token } = await FirebaseMessaging.getToken();

      if (!token) {
        throw new Error('Could not get FCM token');
      }

      return { token };
    } catch (error) {
      console.error('Error getting FCM token:', error);
      throw error;
    }
  }
}
