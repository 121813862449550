import { useQuery } from 'react-query';
import { css } from '@emotion/react';
import { FC, useRef, useState } from 'react';
import { investmentIndex } from '../../apis/search';
import { boxShadow, colors, fontStyles, spacing } from '../../styles';
import magnifyingGlassUrl from './magnifyingGlass.svg';
import crossIcon from './cross.svg';
import Results from './Results';

interface Props {
  onResultClick?: (symbol: string) => void;
  autoFocus?: boolean;
  isAllowBookmark?: boolean;
  excludedSymbols?: string[];
}

const Search: FC<Props> = ({ onResultClick, autoFocus, isAllowBookmark, excludedSymbols = [], ...props }) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const facetFilters = excludedSymbols.map((symbol) => `objectID:-${symbol}`);

  const resultsQuery = useQuery(
    ['search', query],
    async () => {
      if (!query) return [];
      const results = (
        await investmentIndex.search(query, {
          facetFilters,
          hitsPerPage: 5,
        })
      ).hits;
      return results.filter((result: any) => !!result.name);
    },
    { enabled: !!query },
  );

  const styles = {
    search: css`
      position: relative;
    `,
    searchWrapper: css`
      position: relative;
      display: flex;
      align-items: center;
    `,
    iconContainer: css`
      position: absolute;
      left: 0;
      top: 0;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      pointer-events: none;
    `,
    clearButton: css`
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: none;
      cursor: pointer;
      z-index: 1;
    `,
    results: css``,
  };
  const isResultsShown = query && !resultsQuery.isLoading;

  const handleClickCross = () => {
    setQuery('');
    inputEl.current?.focus();
  };

  return (
    <div css={styles.search} {...props}>
      <div css={styles.searchWrapper}>
        <div css={styles.iconContainer}>
          <img src={magnifyingGlassUrl} alt="" />
        </div>
        <input
          autoFocus={autoFocus}
          ref={inputEl}
          type="text"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search stocks"
          css={[
            css`
              ${fontStyles.dog};
              width: 100%;
              height: 50px;
              padding: ${spacing.centi} 50px;
              border: 2px solid ${colors.petrolBlue};
              border-radius: 8px;
              outline: none;
              background-color: ${colors.sugar};
              -webkit-appearance: none;
              html[data-theme='dark'] & {
                border: 2px solid ${colors.skyBlue};
                background-color: var(--background-color);
                color: var(--text-color);
                &::placeholder {
                  color: ${colors.silver};
                }
              }
              &::placeholder {
                color: ${colors.silver};
              }
            `,
            isResultsShown &&
              css`
                border-radius: 8px 8px 0 0;
              `,
          ]}
        />
        {query.length > 0 && (
          <button type="button" onClick={handleClickCross} css={styles.clearButton} aria-label="cancel">
            <img src={crossIcon} alt="" width="16" height="16" />
          </button>
        )}
      </div>

      {isResultsShown && (
        <div
          css={css`
            position: absolute;
            z-index: 1;
            width: 100%;
            border-radius: 8px;
            ${boxShadow};
          `}
        >
          <Results
            investments={resultsQuery.data}
            onResultClick={onResultClick}
            isAllowBookmark={isAllowBookmark}
            isError={resultsQuery.isError}
          />
        </div>
      )}
    </div>
  );
};

export default Search;
