import { css } from '@emotion/react';
import { FC, useState, useEffect } from 'react';
import BottomSheet from '../BottomSheet';
import Button from '../Button';
import { fontStyles, spacing, colors } from '../../styles';
import { Portfolios } from '../../storage';

interface PortfolioSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (selectedPortfolioIds: string[]) => void;
  title: string;
  symbol?: string;
}

export const PortfolioSelectionModal: FC<PortfolioSelectionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  symbol,
}) => {
  const storePortfolios = Portfolios.useState((s) => s.portfolios);
  const storeIsLoading = Portfolios.useState((s) => s.isLoading);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Only run this effect when the modal opens or when the symbol changes
  // But NOT when storePortfolios changes to avoid infinite loops
  useEffect(() => {
    if (!isOpen) {
      setSelectedIds([]);
      return;
    }

    setIsLoading(true);
    try {
      // Pre-select portfolios that already contain this symbol
      if (symbol) {
        console.log('Processing portfolios for symbol:', symbol);
        const preSelectedIds = storePortfolios
          .filter((p) => p.symbols && Array.isArray(p.symbols) && p.symbols.includes(symbol))
          .map((p) => p.id);
        console.log('Pre-selected portfolio IDs:', preSelectedIds);
        setSelectedIds(preSelectedIds);
      }
    } catch (error) {
      console.error('Error processing portfolios:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isOpen, symbol]);

  const handleToggle = (id: string) => {
    // Create a new array to ensure React detects the state change
    const newSelectedIds = selectedIds.includes(id) ? selectedIds.filter((pid) => pid !== id) : [...selectedIds, id];

    // Update state with new array
    setSelectedIds(newSelectedIds);

    // Debugging to check state changes
    console.log('Toggle portfolio', id, 'New selected IDs:', newSelectedIds);
  };

  const handleSubmit = () => {
    console.log('Selected portfolio IDs in PortfolioSelectionModal:', selectedIds);
    onSubmit(selectedIds);
    onClose();
  };

  const containerStyles = css`
    padding: ${spacing.deci} ${spacing.deci} calc(${spacing.giga} + env(safe-area-inset-bottom, 0px)) ${spacing.deci};
  `;

  const listStyles = css`
    max-height: 50vh;
    overflow-y: auto;
    margin-bottom: ${spacing.deci};
  `;

  const checkboxContainerStyles = css`
    display: flex;
    align-items: center;
    margin-bottom: ${spacing.centi};
    padding: ${spacing.centi};
    border-radius: 8px;
    background-color: var(--card-background);
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:active {
      background-color: ${colors.cloud}22;
    }

    html[data-theme='dark'] & {
      &:active {
        background-color: ${colors.tungsten}40;
      }
    }
  `;

  const checkboxStyles = css`
    margin-right: ${spacing.centi};
    height: 20px;
    width: 20px;
    accent-color: ${colors.petrolBlue};
    cursor: pointer;
    position: relative;
    z-index: 10; /* Increased z-index to ensure checkbox is on top */
    pointer-events: auto; /* Ensure pointer events work */

    html[data-theme='dark'] & {
      accent-color: ${colors.skyBlue};
    }
  `;

  const labelStyles = css`
    ${fontStyles.dog};
    color: var(--text-color);
  `;

  const buttonStyles = css`
    margin-top: ${spacing.deci};
    width: 100%;
    background-color: ${colors.petrolBlue};
    color: ${colors.snow};
    padding: ${spacing.centi};
    border-radius: 8px;
    font-weight: 500;

    &:disabled {
      opacity: 0.6;
      background-color: ${colors.silver};
    }

    html[data-theme='dark'] & {
      background-color: ${colors.skyBlue};
      color: ${colors.liquorice};

      &:disabled {
        background-color: ${colors.silver}70;
        color: ${colors.tungsten};
      }
    }
  `;

  const loadingStyles = css`
    ${fontStyles.cat};
    color: var(--secondary-text);
    text-align: center;
    padding: ${spacing.deci};
  `;

  const emptyStyles = css`
    ${fontStyles.cat};
    color: var(--secondary-text);
    text-align: center;
    padding: ${spacing.deci};
  `;

  const createWatchlistButtonStyles = css`
    display: block;
    width: 100%;
    background-color: transparent;
    color: ${colors.petrolBlue};
    border: 1px solid ${colors.petrolBlue};
    border-radius: 8px;
    padding: ${spacing.centi};
    ${fontStyles.dog};
    margin-top: ${spacing.centi};
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: ${colors.petrolBlue}11;
    }

    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
      border-color: ${colors.skyBlue};

      &:hover {
        background-color: ${colors.skyBlue}11;
      }
    }
  `;

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose} title={title}>
      <div css={containerStyles}>
        {isLoading || storeIsLoading ? (
          <div css={loadingStyles}>Loading portfolios...</div>
        ) : (
          <>
            {storePortfolios.length === 0 ? (
              <div css={emptyStyles}>
                <p>No watchlists found.</p>
                <a href="/portfolios/add" css={createWatchlistButtonStyles}>
                  Create a Watchlist
                </a>
              </div>
            ) : (
              <>
                <div css={listStyles}>
                  {storePortfolios.map((portfolio) => (
                    <div
                      key={portfolio.id}
                      css={checkboxContainerStyles}
                      role="button"
                      aria-pressed={selectedIds.includes(portfolio.id)}
                      tabIndex={0}
                      onClick={(e) => {
                        // Only toggle if not clicking on the checkbox itself
                        if (e.target !== document.getElementById(`portfolio-${portfolio.id}`)) {
                          handleToggle(portfolio.id);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleToggle(portfolio.id);
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`portfolio-${portfolio.id}`}
                        checked={selectedIds.includes(portfolio.id)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleToggle(portfolio.id);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        css={checkboxStyles}
                      />
                      <span css={labelStyles}>{portfolio.title}</span>
                    </div>
                  ))}
                </div>
                <Button onClick={handleSubmit} css={buttonStyles} block>
                  {selectedIds.length > 0 ? 'Save' : 'Remove from all'}
                </Button>
              </>
            )}
          </>
        )}
      </div>
    </BottomSheet>
  );
};

export default PortfolioSelectionModal;
